import React from 'react';
import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { getTranslations } from '../translations';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'ArialRegular',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: '#000',
  },
  container: {
    width: '90%',
    margin: '0 auto',
    height: 830,
    paddingBottom: 80,
  },
  content: {
    fontSize: 8,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: '30%',
    columnGap: 20,
  },
  title: {
    fontSize: 18,
    fontFamily: 'ArialRegular',
    color: '#01B9F2',
    marginTop: 30,
    marginBottom: 20,
  },
  block: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 8,
  },
  blockText: {
    flexWrap: 'wrap',
  },

  blockHeader: {
    color: '#01B9F2',
    fontSize: 9,
    marginBottom: 2,
  },
  footer: {
    fontSize: 6,
    color: 'grey',
    width: 250,
    position: 'absolute',
    bottom: 0,
  },
});

const SecondPageNO = () => {
  const { translations } = getTranslations('grenke_second_page_no');

  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View>
          <Text style={{ fontSize: 6, marginBottom: 5, marginTop: 20 }}>
            SIDE 1 – <Text style={{ fontFamily: 'ArialBold' }}>18NO02</Text>
          </Text>
          <Text style={{ fontSize: 6 }}>{translations.info}</Text>
        </View>

        <View>
          <Text style={styles.title}>{translations.title}</Text>
        </View>

        <View style={styles.content}>
          <View style={styles.block}>
            <View>
              <Text style={styles.blockHeader}>{translations.block_head_1}</Text>
              <Text style={styles.blockText}>{translations.block_text_1}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <View>
              <Text style={styles.blockHeader}>{translations.block_head_2}</Text>
              <Text style={styles.blockText}>{translations.block_text_2_1}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <View>
              <Text style={styles.blockText}>{translations.block_text_2_2}</Text>
            </View>
          </View>

          <View style={{ ...styles.block }}>
            <View>
              <Text style={styles.blockText}>{translations.block_text_2_3}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <View>
              <Text style={styles.blockHeader}>{translations.block_head_3}</Text>
              <Text style={styles.blockText}>{translations.block_text_3}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <View>
              <Text style={styles.blockHeader}>{translations.block_head_4}</Text>
              <Text style={styles.blockText}>{translations.block_text_4_1}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <View>
              <Text style={styles.blockText}>{translations.block_text_4_2}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <View>
              <Text style={styles.blockHeader}>{translations.block_head_5}</Text>
              <Text style={styles.blockText}>{translations.block_text_5}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <View>
              <Text style={styles.blockHeader}>{translations.block_head_6}</Text>
              <Text style={styles.blockText}>{translations.block_text_6_1}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <View>
              <Text style={styles.blockText}>{translations.block_text_6_2}</Text>
            </View>
          </View>
          <View style={styles.block}>
            <View>
              <Text style={styles.blockText}>{translations.block_text_6_3}</Text>
            </View>
          </View>
          <View style={styles.block}>
            <View>
              <Text style={styles.blockText}>{translations.block_text_6_4}</Text>
            </View>
          </View>

          <View style={{ ...styles.block, flexDirection: 'column' }}>
            <View style={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
              <Text style={styles.blockText}>c)</Text>
              <Text style={styles.blockText}>{translations.block_text_6_5_1}</Text>
            </View>

            <View style={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
              <Text style={styles.blockText}>d)</Text>
              <Text style={styles.blockText}>{translations.block_text_6_5_2}</Text>
            </View>

            <View style={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
              <Text style={styles.blockText}>e)</Text>
              <Text style={styles.blockText}>{translations.block_text_6_5_3}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <View>
              <Text style={styles.blockText}>{translations.block_text_6_6}</Text>
            </View>
          </View>
          <View style={styles.block}>
            <View>
              <Text style={styles.blockText}>{translations.block_text_6_7}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <View>
              <Text style={styles.blockHeader}>{translations.block_head_7}</Text>
              <Text style={styles.blockText}>{translations.block_text_7}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <View>
              <Text style={styles.blockHeader}>{translations.block_head_8}</Text>
              <Text style={styles.blockText}>{translations.block_text_8_1}</Text>
            </View>
          </View>
          {/*  */}
        </View>

        <View style={styles.footer}>
          <Text>
            <Text style={{ color: '#000' }}>{translations.bank}</Text> {'\n'}
            {translations.footer}
          </Text>
        </View>
      </View>
    </Page>
  );
};

export default SecondPageNO;
