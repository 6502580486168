import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import { securityPdfStyles } from '../../../../helpers/pdfCommonStyles';

import logo from '../../../../assets/images/Logo.png';
import Footer from './footer';

const styles = StyleSheet.create({
  ...securityPdfStyles,
  page: {
    ...securityPdfStyles.page,
    backgroundColor: 'rgba(126,179,227,1)',
  },

  container: {
    ...securityPdfStyles.container,
    marginTop: 250,
  },
  logo: {
    ...securityPdfStyles.logo,
    marginLeft: 0,
  },
  title: {
    ...securityPdfStyles.title,
    color: '#fff',
    fontSize: 31,
    marginBottom: 40,
  },
  subTitle: {
    ...securityPdfStyles.subTitle,
    color: '#fff',
  },
  text: {
    ...securityPdfStyles.text,
    color: '#fff',
  },
});

// eslint-disable-next-line arrow-body-style
const FirstPage = () => {
  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.content}>
          <View style={styles.logo}>
            <Image src={logo} />
          </View>
          <Text style={{ ...styles.title }}>Security and quality{'\n'} at Pingaway</Text>

          <Text style={styles.subTitle}>INTRODUCTION</Text>
          <Text style={styles.text}>
            In this document, you can read how we at Pingaway work with security. We assure high quality in everything
            from firewalling, data storage, support, and more. If you have any questions, please contact us
            <Text style={{ ...styles.text, fontFamily: 'Como-Bold', margin: 0 }}> here.</Text>
          </Text>
        </View>
      </View>
      <Footer />
    </Page>
  );
};

FirstPage.propTypes = {};

FirstPage.defaultProps = {};

export default FirstPage;
