import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';
import {
  Summary,
  Trafic,
  Roaming,
  // Grenke,
  SLA,
  Security,
  PersonalDataServiceAgreement,
  HardwarePot,
  FinalInvoice,
  FinalInvoiceAndHardwarePot,
} from '../../components/simple/Summary';
import { authorization } from '../../helpers/authentication';
import { LinkButton, Logo, StyledButton } from '../../components/simple';
import { get } from '../../services/API';
import styles from './styles.module.scss';
import { mainPage } from '../../router/routes';
import pdfViewer from '../../helpers/pdfViewer';
import { downloadSummary, downloadGrenke, signPDF, typesSign, checkSignStatus } from '../SummaryPage/SummaryPage';
import useMultiLang from '../../localization/useMultiLang';

const MyAgreementPage = () => {
  const { t, getCurrentLanguage } = useMultiLang();
  const [agreement, setAgreement] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState('');

  const [isSending, setSending] = useState({
    sv: false,
    en: false,
    agreement_with_number: false,
    agreement_without_number: false,
  });

  const fetchData = async () => {
    setIsLoading(true);
    const { data } = await get(`/quotes/quotes/${localStorage.getItem('quoteId')}`, authorization());
    setIsLoading(false);
    setAgreement(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleLoad = (type, value) => {
    setSending((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const handleError = (val) => {
    if (val.data?.scrive_error) {
      setError(val.data?.scrive_error);
    }
    fetchData();
  };

  const startSign = (lang) => {
    handleLoad(lang, true);

    signPDF({
      data: agreement,
      lang,
      type: lang === 'sv' ? typesSign.start_signing_sv : typesSign.start_signing_en,
      name: agreement?.company.name,
      isTrafic: agreement?.has_trafic,
      isRoaming: agreement?.has_roaming,
      isSLA: agreement?.has_sla,
      isSecurity: agreement?.has_security,
      isPersonalDataServiceAgreement: agreement?.has_data_processing,
      isHardwarePot: agreement?.has_hardware_budget,
      isFinalInvoice: agreement?.has_final_invoice,
      isFinalInvoiceAndHardwarePot: agreement?.has_final_invoice_and_hardware_budget,
    }).then((res) => {
      handleLoad(lang, false);
      handleError(res);
    });
  };

  const startSignAgreement = (type) => {
    handleLoad(
      type === typesSign.start_signing_agreement_with_inquiry_number
        ? 'agreement_with_number'
        : 'agreement_without_number',
      true,
    );
    signPDF({
      data: agreement,
      lang: 'sv',
      type,
      name: 'Grenke',
    }).then((res) => {
      handleLoad(
        type === typesSign.start_signing_agreement_with_inquiry_number
          ? 'agreement_with_number'
          : 'agreement_without_number',
        false,
      );
      handleError(res);
    });
  };

  const disabledStartSigningButton = useMemo(
    () => ({
      disabledStartSigningSV: checkSignStatus(agreement?.esign_documents, typesSign.start_signing_sv),
      disabledStartSigningEn: checkSignStatus(agreement?.esign_documents, typesSign.start_signing_en),
      disabledStartSigningWithNumber: checkSignStatus(
        agreement?.esign_documents,
        typesSign.start_signing_agreement_with_inquiry_number,
      ),
      disabledStartSigningWithoutNumber: checkSignStatus(
        agreement?.esign_documents,
        typesSign.start_signing_agreement_without_inquiry_number,
      ),
    }),
    [agreement],
  );

  return (
    <div className={styles.mainPageContainer}>
      <Logo />
      <div className={styles.content}>
        <div className={styles.actions}>
          <LinkButton text={t('go_home')} color="yellow" path={mainPage} />

          {disabledStartSigningButton?.disabledStartSigningSV ? null : (
            <div className={styles.buttonSign}>
              <StyledButton
                color="blue"
                text={isSending?.sv ? `${t('sanding')}...` : t('start_signing_quote')}
                onClick={() => startSign('sv')}
                disabled={isLoading}
              />
            </div>
          )}

          {disabledStartSigningButton?.disabledStartSigningEn || getCurrentLanguage() !== 'sv' ? null : (
            <div className={styles.buttonSign}>
              <StyledButton
                color="blue"
                text={isSending?.en ? `${t('sanding')}...` : t('start_signing_quote_eng')}
                onClick={() => startSign('en')}
                disabled={isLoading}
              />
            </div>
          )}

          {!agreement?.inquiry_number || disabledStartSigningButton?.disabledStartSigningWithNumber ? null : (
            <div className={styles.buttonSign}>
              <StyledButton
                color="yellow"
                text={
                  isSending?.agreement_with_number
                    ? `${t('sanding')}...`
                    : t('start_signing_financial_agreement_with_request_number')
                }
                onClick={() => startSignAgreement(typesSign.start_signing_agreement_with_inquiry_number)}
                disabled={isLoading}
              />
            </div>
          )}

          {!!agreement?.inquiry_number || disabledStartSigningButton?.disabledStartSigningWithoutNumber ? null : (
            <div className={styles.buttonSign}>
              <StyledButton
                color="yellow"
                text={
                  isSending?.agreement_without_number
                    ? `${t('sanding')}...`
                    : t('start_signing_financial_agreement_without_request_number')
                }
                onClick={() => startSignAgreement(typesSign.start_signing_agreement_without_inquiry_number)}
                disabled={isLoading}
              />
            </div>
          )}

          <div className={styles.button}>
            {downloadSummary({
              isTrafic: agreement?.has_trafic,
              isRoaming: agreement?.has_roaming,
              isSLA: agreement?.has_sla,
              isSecurity: agreement?.has_security,
              isPersonalDataServiceAgreement: agreement?.has_data_processing,
              isHardwarePot: agreement?.has_hardware_budget,
              isFinalInvoice: agreement?.has_final_invoice,
              isFinalInvoiceAndHardwarePot: agreement?.has_final_invoice_and_hardware_budget,
              data: agreement,
              lang: 'sv',
              isLoading,
            })}
          </div>

          {getCurrentLanguage() === 'sv' ? (
            <div className={styles.button}>
              {downloadSummary({
                data: agreement,
                lang: 'en',
                isLoading,
              })}
            </div>
          ) : null}

          <div className={styles.button}>{downloadGrenke(agreement, () => null, isLoading)}</div>
        </div>

        <div className={styles.agreement}>
          {pdfViewer(Summary, agreement)}
          {agreement?.has_trafic && pdfViewer(Trafic)}
          {agreement?.has_roaming && pdfViewer(Roaming)}

          {agreement?.has_sla && pdfViewer(SLA)}
          {agreement?.has_security && pdfViewer(Security)}
          {agreement?.has_data_processing && pdfViewer(PersonalDataServiceAgreement, agreement)}
          {agreement?.has_hardware_budget && pdfViewer(HardwarePot, agreement)}
          {agreement?.has_final_invoice && pdfViewer(FinalInvoice, agreement)}
          {agreement?.has_final_invoice_and_hardware_budget && pdfViewer(FinalInvoiceAndHardwarePot, agreement)}

          {/* {agreement?.has_grenke && pdfViewer(Grenke, agreement)} */}
        </div>
      </div>

      <Snackbar
        open={error}
        // autoHideDuration={4000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setError('')} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default MyAgreementPage;
