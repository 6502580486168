import { get } from '../services/API';

export const authorization = () => ({
  headers: {
    Authorization: `Token ${localStorage.getItem('token')}`,
  },
});

export const logout = async () => {
  await get('/auth/logout');
  localStorage.clear();
};

export const isLogin = () => !!localStorage.getItem('token');
