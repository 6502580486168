import React, { useEffect, useMemo, useState } from 'react';
import { PDFDownloadLink, pdf } from '@react-pdf/renderer';
import { useNavigate } from 'react-router-dom';
import { Alert, Snackbar } from '@mui/material';
import pdfViewer from '../../helpers/pdfViewer';
import {
  Summary,
  Grenke,
  Roaming,
  Trafic,
  SummaryDocument,
  SLA,
  Security,
  PersonalDataServiceAgreement,
  HardwarePot,
  FinalInvoice,
  FinalInvoiceAndHardwarePot,
} from '../../components/simple/Summary';
import useToggle from '../../helpers/useToogle';
import styles from './styles.module.scss';
import { LinkButton, Logo, StyledButton, StyledCheckbox } from '../../components/simple';
import { mainPage, myAgreement, newAgreementStep3 } from '../../router/routes';
import { get, post, put } from '../../services/API';
import { authorization } from '../../helpers/authentication';
import GrenkeDocument from '../../components/simple/Summary/GrenkeDocument';
import useMultiLang from '../../localization/useMultiLang';
import { translate } from '../../localization/LanguageContext';

export const downloadSummary = ({
  isTrafic = false,
  isRoaming = false,
  isSLA = false,
  isSecurity = false,
  isPersonalDataServiceAgreement = false,
  isHardwarePot = false,
  isFinalInvoice = false,
  isFinalInvoiceAndHardwarePot = false,
  data,
  onClick = () => null,
  lang = 'sv',
  isLoading,
}) => {
  if (isLoading) {
    return (
      <button type="button" className={styles.download} onClick={() => null}>
        {translate('download_quote')} {lang === 'sv' ? '' : 'ENG'}
      </button>
    );
  }

  return (
    <PDFDownloadLink
      document={
        <SummaryDocument
          isTrafic={isTrafic}
          isRoaming={isRoaming}
          isSLA={isSLA}
          isSecurity={isSecurity}
          isPersonalDataServiceAgreement={isPersonalDataServiceAgreement}
          isHardwarePot={isHardwarePot}
          isFinalInvoice={isFinalInvoice}
          isFinalInvoiceAndHardwarePot={isFinalInvoiceAndHardwarePot}
          data={data}
          lang={lang}
        />
      }
      fileName={data?.company.name}
    >
      <button type="button" className={styles.download} onClick={onClick}>
        {translate('download_quote')} {lang === 'sv' ? '' : 'ENG'}
      </button>
    </PDFDownloadLink>
  );
};

export const downloadGrenke = (data, onClick, isLoading) => {
  if (isLoading) {
    return (
      <button type="button" className={styles.download} onClick={() => null}>
        {translate('download_finance_agreement')}
      </button>
    );
  }
  return (
    <PDFDownloadLink document={<GrenkeDocument data={data} />} fileName="Grenke">
      <button type="button" className={styles.download} onClick={onClick}>
        {translate('download_finance_agreement')}
      </button>
    </PDFDownloadLink>
  );
};

export const typesSign = {
  start_signing_sv: 'summary/swe/',
  start_signing_en: 'summary/eng/',
  start_signing_agreement_with_inquiry_number: 'agreement-number/swe/',
  start_signing_agreement_without_inquiry_number: 'agreement/swe/',
};

export const checkSignStatus = (arr, type) => {
  const format = arr?.map((item) => `${item.type}/${item.language}/`) ?? [];
  return format.includes(type);
};

export const signPDF = async ({
  data,
  lang,
  type,
  name,
  isTrafic = false,
  isRoaming = false,
  isSLA = false,
  isSecurity = false,
  isPersonalDataServiceAgreement = false,
  isHardwarePot = false,
  isFinalInvoice = false,
  isFinalInvoiceAndHardwarePot = false,
}) => {
  let doc = '';
  if (
    type === typesSign.start_signing_agreement_with_inquiry_number ||
    type === typesSign.start_signing_agreement_without_inquiry_number
  ) {
    doc = <GrenkeDocument data={data} />;
  } else {
    const withAttachments = typesSign.start_signing_sv === type;
    doc = (
      <SummaryDocument
        isTrafic={isTrafic && withAttachments}
        isRoaming={isRoaming && withAttachments}
        isSLA={isSLA && withAttachments}
        isSecurity={isSecurity && withAttachments}
        isPersonalDataServiceAgreement={isPersonalDataServiceAgreement && withAttachments}
        isHardwarePot={isHardwarePot && withAttachments}
        isFinalInvoice={isFinalInvoice && withAttachments}
        isFinalInvoiceAndHardwarePot={isFinalInvoiceAndHardwarePot && withAttachments}
        data={data}
        lang={lang}
      />
    );
  }

  const blob = await pdf(doc).toBlob();
  const file = new File([blob], name, { lastModified: new Date().getTime() });
  const form = new FormData();
  form.append('file', file);
  return post(`/esign/upload-file/${type}${localStorage.getItem('quoteId')}/`, form, authorization());
};

const SummaryPage = () => {
  const { t, getCurrentLanguage } = useMultiLang();
  const [agreement, setAgreement] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [isTrafic, setIsTrafik] = useState(false);
  const [isRoaming, setIsRoaming] = useState(false);
  const [isSLA, setIsSLA] = useState(false);
  const [isGrenke, setIsGrenke] = useState(false);
  const [isSecurity, setIsSecurity] = useState(false);
  const [isPersonalDataServiceAgreement, setIsPersonalDataServiceAgreement] = useState(false);
  const [isHardwarePot, setIsHardwarePot] = useState(false);
  const [isFinalInvoice, setIsFinalInvoice] = useState(false);
  const [isFinalInvoiceAndHardwarePot, setIsFinalInvoiceAndHardwarePot] = useState(false);
  const [error, setError] = useState('');

  const [isSending, setSending] = useState({
    sv: false,
    en: false,
    agreement_with_number: false,
    agreement_without_number: false,
  });

  const [isDownloaded, setIsDownloaded] = useState(false);
  const [isDublicated, setIsDublicated] = useState(false);
  const quote = localStorage.getItem('quoteId');
  const navigate = useNavigate();

  const setCheckBoxes = async (isDownl) => {
    await put(
      `quotes/quotes/${localStorage.getItem('quoteId')}`,
      {
        manager_id: localStorage.getItem('userId'),
        company_id: localStorage.getItem('companyId'),
        is_downloaded: isDownl,
        has_trafic: isTrafic,
        has_roaming: isRoaming,
        has_sla: isSLA,
        has_security: isSecurity,
        has_data_processing: isPersonalDataServiceAgreement,
        has_final_invoice_and_hardware_budget: isFinalInvoiceAndHardwarePot,
        has_final_invoice: isFinalInvoice,
        has_hardware_budget: isHardwarePot,
      },
      authorization(),
    );
  };

  const setDownloadSummary = async () => {
    await setCheckBoxes(true);
    navigate(myAgreement);
    setIsDownloaded(true);
  };

  const setDownloadGrenke = async () => {
    await put(
      `quotes/quotes/${localStorage.getItem('quoteId')}`,
      {
        manager_id: localStorage.getItem('userId'),
        company_id: localStorage.getItem('companyId'),
        has_grenke: true,
      },
      authorization(),
    );
  };

  const dublicateAgreement = async () => {
    const data = await post(`quotes/quotes/${localStorage.getItem('quoteId')}/duplicate`, null, authorization());
    if (data.status === 200) {
      setIsDublicated(true);
    }
  };

  const fetchData = async (err) => {
    setIsLoading(true);
    const { data } = await get(`/quotes/quotes/${localStorage.getItem('quoteId')}`, authorization());
    setIsLoading(false);
    setAgreement(data);
    setIsTrafik(Boolean(data?.has_trafic));
    setIsRoaming(Boolean(data?.has_roaming));
    setIsSLA(Boolean(data?.has_sla));
    setIsSecurity(Boolean(data?.has_security));
    setIsPersonalDataServiceAgreement(Boolean(data?.has_data_processing));
    setIsHardwarePot(Boolean(data?.has_hardware_budget));
    setIsFinalInvoice(Boolean(data?.has_final_invoice));
    setIsFinalInvoiceAndHardwarePot(Boolean(data?.has_final_invoice_and_hardware_budget));
    if (err) {
      return;
    }
    if (data.is_downloaded || Boolean(data?.esign_documents?.length)) {
      navigate(myAgreement);
    }
    setIsDownloaded(data.is_downloaded || Boolean(data?.esign_documents?.length));
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!quote) navigate(mainPage);
  }, [quote]);

  const handleError = (val) => {
    if (val.data?.scrive_error) {
      setError(val.data?.scrive_error);
      fetchData(true);
      return;
    }
    fetchData();
  };

  const handleLoad = (type, value) => {
    setSending((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const startSign = (lang) => {
    handleLoad(lang, true);
    setCheckBoxes(false);
    signPDF({
      data: agreement,
      lang,
      type: lang === 'sv' ? typesSign.start_signing_sv : typesSign.start_signing_en,
      name: agreement?.company.name,
      isTrafic,
      isRoaming,
      isSLA,
      isSecurity,
      isPersonalDataServiceAgreement,
      isHardwarePot,
      isFinalInvoice,
      isFinalInvoiceAndHardwarePot,
    }).then((res) => {
      handleLoad(lang, false);
      handleError(res);
    });
  };

  const startSignAgreement = (type) => {
    handleLoad(
      type === typesSign.start_signing_agreement_with_inquiry_number
        ? 'agreement_with_number'
        : 'agreement_without_number',
      true,
    );
    setCheckBoxes(false);

    signPDF({
      data: agreement,
      lang: 'sv',
      type,
      name: 'Grenke',
    }).then((res) => {
      handleLoad(
        type === typesSign.start_signing_agreement_with_inquiry_number
          ? 'agreement_with_number'
          : 'agreement_without_number',
        false,
      );
      handleError(res);
    });
  };

  const disabledStartSigningButton = useMemo(
    () => ({
      disabledStartSigningSV: checkSignStatus(agreement?.esign_documents, typesSign.start_signing_sv),
      disabledStartSigningEn: checkSignStatus(agreement?.esign_documents, typesSign.start_signing_en),
      disabledStartSigningWithNumber: checkSignStatus(
        agreement?.esign_documents,
        typesSign.start_signing_agreement_with_inquiry_number,
      ),
      disabledStartSigningWithoutNumber: checkSignStatus(
        agreement?.esign_documents,
        typesSign.start_signing_agreement_without_inquiry_number,
      ),
    }),
    [agreement],
  );

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <Logo />
      </div>
      <div className={styles.content}>
        <div className={styles.contentWrapper}>
          <div className={styles.panel}>
            <div className={styles.buttons}>
              {!isDownloaded && (
                <div className={styles.forwardButton}>
                  <div className={styles.button}>
                    <LinkButton
                      color="lightblue"
                      text={t('back')}
                      path={newAgreementStep3}
                      linkClassName={isDownloaded && styles.disabled}
                      className={styles.button}
                    />
                  </div>
                </div>
              )}

              <div className={styles.activeButtons}>
                <div className={styles.button}>
                  <StyledButton
                    color={disabledStartSigningButton.disabledStartSigningSV ? 'grey' : 'blue'}
                    text={isSending?.sv ? `${t('sanding')}...` : t('start_signing_quote')}
                    onClick={() => startSign('sv')}
                    disabled={isSending?.sv || disabledStartSigningButton.disabledStartSigningSV || isLoading}
                  />
                </div>

                {getCurrentLanguage() === 'sv' ? (
                  <div className={styles.button}>
                    <StyledButton
                      color={disabledStartSigningButton.disabledStartSigningEn ? 'grey' : 'blue'}
                      text={isSending?.en ? `${t('sanding')}...` : t('start_signing_quote_eng')}
                      onClick={() => startSign('en')}
                      disabled={isSending?.en || disabledStartSigningButton.disabledStartSigningEn || isLoading}
                    />
                  </div>
                ) : null}

                <div className={styles.button}>
                  <StyledButton
                    color={
                      !agreement?.inquiry_number || disabledStartSigningButton.disabledStartSigningWithNumber
                        ? 'grey'
                        : 'yellow'
                    }
                    text={
                      isSending?.agreement_with_number
                        ? `${t('sanding')}...`
                        : t('start_signing_financial_agreement_with_request_number')
                    }
                    onClick={() => startSignAgreement(typesSign.start_signing_agreement_with_inquiry_number)}
                    disabled={
                      isSending?.agreement_with_number ||
                      !agreement?.inquiry_number ||
                      disabledStartSigningButton.disabledStartSigningWithNumber ||
                      isLoading
                    }
                  />
                </div>

                <div className={styles.button}>
                  <StyledButton
                    color={
                      !!agreement?.inquiry_number || disabledStartSigningButton.disabledStartSigningWithoutNumber
                        ? 'grey'
                        : 'yellow'
                    }
                    text={
                      isSending?.agreement_without_number
                        ? `${t('sanding')}...`
                        : t('start_signing_financial_agreement_without_request_number')
                    }
                    onClick={() => startSignAgreement(typesSign.start_signing_agreement_without_inquiry_number)}
                    disabled={
                      isSending?.agreement_without_number ||
                      !!agreement?.inquiry_number ||
                      disabledStartSigningButton.disabledStartSigningWithoutNumber ||
                      isLoading
                    }
                  />
                </div>
              </div>

              <div className={styles.activeButtons}>
                <div className={styles.button}>
                  <LinkButton text={t('go_to_the_home_page')} color="yellow" path={mainPage} />
                </div>

                <div className={styles.button}>
                  <StyledButton
                    color={isDublicated ? 'grey' : 'blue'}
                    text={t('clone_agreement')}
                    onClick={dublicateAgreement}
                    disabled={isDublicated}
                  />
                </div>

                <div className={styles.button}>
                  {!isDownloaded &&
                    downloadSummary({
                      isTrafic,
                      isRoaming,
                      isSLA,
                      isSecurity,
                      isPersonalDataServiceAgreement,
                      isHardwarePot,
                      isFinalInvoice,
                      isFinalInvoiceAndHardwarePot,
                      data: agreement,
                      lang: 'sv',
                      onClick: setDownloadSummary,
                      isLoading,
                    })}
                </div>
                <div className={styles.button}>
                  {!isDownloaded &&
                    getCurrentLanguage() === 'sv' &&
                    downloadSummary({
                      isTrafic,
                      isRoaming,
                      isSLA,
                      isSecurity,
                      isPersonalDataServiceAgreement,
                      isHardwarePot,
                      isFinalInvoice,
                      isFinalInvoiceAndHardwarePot,
                      data: agreement,
                      lang: 'en',
                      onClick: setDownloadSummary,
                      isLoading,
                    })}
                </div>

                <div className={styles.button}>{downloadGrenke(agreement, setDownloadGrenke, isLoading)}</div>
                {/* {isGrenke && <div className={styles.button}>{downloadGrenke(agreement, setDownloadGrenke)}</div>} */}
              </div>
            </div>
            {!isDownloaded && (
              <div className={styles.checkboxes}>
                {getCurrentLanguage() === 'sv' ? (
                  <>
                    <StyledCheckbox
                      label={t('traffic_price_list')}
                      checked={isTrafic}
                      onChange={() => setIsTrafik((prev) => !prev)}
                    />
                    <StyledCheckbox
                      label={t('roaming_price_list')}
                      checked={isRoaming}
                      onChange={() => setIsRoaming((prev) => !prev)}
                    />
                  </>
                ) : (
                  <StyledCheckbox
                    label={`${t('traffic_price_list')} & ${t('roaming_price_list')}`}
                    checked={isRoaming}
                    onChange={() => setIsRoaming((prev) => !prev)}
                  />
                )}

                <StyledCheckbox label={t('sla')} checked={isSLA} onChange={() => setIsSLA((prev) => !prev)} />
                <StyledCheckbox
                  label={t('security_document')}
                  checked={isSecurity}
                  onChange={() => setIsSecurity((prev) => !prev)}
                />
                <StyledCheckbox
                  label={t('personal_assistant_agreement')}
                  checked={isPersonalDataServiceAgreement}
                  onChange={() => setIsPersonalDataServiceAgreement((prev) => !prev)}
                />
                <StyledCheckbox
                  label={t('hardware_pot')}
                  checked={isHardwarePot}
                  onChange={() => setIsHardwarePot((prev) => !prev)}
                />
                <StyledCheckbox
                  label={t('final_invoice')}
                  checked={isFinalInvoice}
                  onChange={() => setIsFinalInvoice((prev) => !prev)}
                />
                <StyledCheckbox
                  label={t('final_invoice_hardware_pot')}
                  checked={isFinalInvoiceAndHardwarePot}
                  onChange={() => setIsFinalInvoiceAndHardwarePot((prev) => !prev)}
                />

                {/* <StyledCheckbox label="Lägg till grenke avtal" checked={isGrenke} onChange={setIsGrenke} /> */}
              </div>
            )}
          </div>
          <div className={styles.agreements}>
            {pdfViewer(Summary, agreement)}
            {isTrafic && pdfViewer(Trafic)}
            {isRoaming && pdfViewer(Roaming)}
            {isSLA && pdfViewer(SLA)}
            {isSecurity && pdfViewer(Security)}
            {isPersonalDataServiceAgreement && pdfViewer(PersonalDataServiceAgreement, agreement)}
            {isHardwarePot && pdfViewer(HardwarePot, agreement)}
            {isFinalInvoice && pdfViewer(FinalInvoice, agreement)}
            {isFinalInvoiceAndHardwarePot && pdfViewer(FinalInvoiceAndHardwarePot, agreement)}

            {/* {isGrenke && pdfViewer(Grenke, agreement)} */}
            {/* {pdfViewer(Grenke, agreement)} */}
            {/* {pdfViewer(Security, agreement)} */}
          </div>
        </div>
      </div>

      <Snackbar
        open={error}
        // autoHideDuration={4000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setError('')} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SummaryPage;
