const buttonColor = (color) => {
  const colors = {
    lightgreen: '#32ada9',
    lightblue: '#76a9db',
    darkblue: '#10225c',
    blue: '#2072f5',
    purple: '#997fb9',
    yellow: '#f5ad20',
  };
  return colors[color];
};

export default buttonColor;
