import React from 'react';
import Router from './router/Router';
import { LanguageProvider } from './localization/LanguageContext';

import './styles/global.css';
import './styles/_main.scss';

const App = () => (
  <LanguageProvider>
    <div className="App">
      <Router />
    </div>
  </LanguageProvider>
);

export default App;
