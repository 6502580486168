import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import picture from '../../../../assets/images/MainBackground.png';
import logo from '../../../../assets/images/Logo.png';
import { summaryPdfStyles } from '../../../../helpers/pdfCommonStyles';
import { getTranslations } from '../translations';

const styles = StyleSheet.create({
  page: {
    ...summaryPdfStyles.page,
    position: 'relative',
  },
  container: {
    position: 'relative',
  },
  picture: {
    height: 840,
  },
  logo: {
    height: 70,
    width: 240,
    marginLeft: 'auto',
    marginTop: 15,
    color: 'grey',
    fontSize: 16,
    textAlign: 'center',
    marginRight: 430,
  },
  content: {
    position: 'absolute',
    bottom: 80,
    left: 40,
  },
  title: {
    fontFamily: 'Como-Bold',
    fontSize: 30,
    borderBottom: 1,
    width: 520,
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 40px',
    borderBottom: 1,
    width: 520,
    paddingBottom: 10,
  },
  infoTitle: {
    fontFamily: 'Como-Bold',
    fontSize: 14,
    margin: '10px 0',
    height: 30,
  },
  infoText: {
    fontSize: 12,
    margin: '3px 0',
  },
  infoBlock: {
    width: 300,
  },
  infoCaption: {
    fontSize: 10,
    marginLeft: 15,
    marginTop: 10,
    fontFamily: 'Como-Bold',
    width: '85%',
  },
  footer: {
    ...summaryPdfStyles.footer,
    // width: '100%',
    // position: 'absolute',
    // textAlign: 'center',
    // fontSize: 14,
    // bottom: 40,
  },
  footerName: {
    ...summaryPdfStyles.footerName,
    marginBottom: 5,
  },
});

const FirstPage = ({ data, lang }) => {
  const { translations, t } = getTranslations('firstPage', lang);

  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <Image src={picture} style={styles.picture} />
        <View style={styles.content}>
          <Image src={logo} style={styles.logo} />
          <Text style={styles.title}>{translations.title}</Text>

          <View style={styles.info}>
            <View style={styles.infoBlock}>
              <Text style={styles.infoTitle}>
                {translations.customer} {data?.company.name}
              </Text>
              <Text style={styles.infoText}>{data?.company.postal_address}</Text>
              <Text style={styles.infoText}>{`${data?.company.zip_code} ${data?.company.state}`}</Text>
              <Text style={styles.infoText}>{data?.company?.signers?.[0]?.phone_number ?? ''}</Text>
              <Text style={styles.infoText}>{data?.company?.signers?.[0]?.email ?? ''}</Text>
            </View>
            <View style={styles.infoBlock}>
              <Text style={styles.infoTitle}>
                {translations.contact} {data?.manager.username}
              </Text>
              <Text style={styles.infoText}>{data?.manager.username}</Text>
              <Text style={styles.infoText}>{data?.manager.email}</Text>
              <Text style={styles.infoText}>{data?.manager.phone_number}</Text>
            </View>
          </View>
          <Text style={styles.infoCaption}>{translations.info.replace('{{date}}', data?.valid_till)}</Text>
        </View>
        <View style={{ ...styles.footer }}>
          <View style={styles.footerName}>
            <Text style={{ fontFamily: 'Como-Bold' }}>{t.common.offer} </Text>
            <Text style={{ fontFamily: 'Como-SemiBold' }}>{data?.company.name}</Text>
          </View>
          <Text style={{ fontFamily: 'Como-Medium' }}>{data?.created_at}</Text>
        </View>
      </View>
    </Page>
  );
};

FirstPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.objectOf(PropTypes.shape)]),
  lang: PropTypes.string,
};

FirstPage.defaultProps = {
  data: null,
  lang: 'sv',
};

export default FirstPage;
