import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { ErrorMessage, StyledButton, Input } from '../../../components/simple';
import styles from './styles.module.scss';
import { patch } from '../../../services/API';
import { loginPage } from '../../../router/routes';
import useMultiLang from '../../../localization/useMultiLang';

const ForgotPasswordForm = () => {
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const { t } = useMultiLang();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async (values) => {
      const data = await patch('/auth/forgot-password', values);
      if (data.status === 200) {
        setMessage(t('forgot_password_success'));
        setTimeout(() => navigate(loginPage), 2000);
      }
    },
  });

  return (
    <div className={styles.forgotPasswordForm}>
      <h3 className={styles.formHeader}>{t('forgot_password_head')}</h3>
      <p className={styles.formInfo}>{t('forgot_password_body')}</p>
      <form onSubmit={formik.handleSubmit} className={styles.form}>
        <Input name="email" type="text" onChange={formik.handleChange} value={formik.values.email} label={t('email')} />
        {error && <ErrorMessage text={t('forgot_password_err')} />}
        {message && <p className={styles.success}>{message}</p>}
        <StyledButton type="submit" color="yellow" text={t('send')} className={styles.button} />
      </form>
    </div>
  );
};

export default ForgotPasswordForm;
