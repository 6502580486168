import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import { slaPdfStyles } from '../../../../helpers/pdfCommonStyles';

import logo from '../../../../assets/images/Logo.png';
import { getTranslations } from '../translations';

const styles = StyleSheet.create({
  ...slaPdfStyles,
  block: {
    display: 'flex',
    flexDirection: 'row',
    borderWidth: 1,
    borderRadius: 20,
    borderColor: '#10225c',
    padding: '20px 40px',
    marginBottom: 10,
  },
});

// eslint-disable-next-line arrow-body-style
const FourPage = () => {
  const { translations, t } = getTranslations( 'SLA_four_page');

  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.logo}>
          <Image src={logo} />
        </View>

        <View style={styles.content}>
          <Text style={styles.text}>{translations.block_text_1}</Text>

          <View style={styles.block}>
            <View style={{ flex: 1, display: 'flex' }}>
              <Text style={{ ...styles.text, fontFamily: 'Como-Bold', margin: 0 }}>{translations.text_1}</Text>
            </View>

            <View style={{ flex: 1, display: 'flex' }}>
              <Text style={{ ...styles.text, fontFamily: 'Como-Bold', margin: 0 }}>{translations.text_2}</Text>
            </View>
          </View>

          <Text style={styles.text}>{translations.block_text_2}</Text>

          <Text style={styles.subTitle}>{translations.sub_title_1}</Text>
          <Text style={styles.text}>{translations.block_text_3}</Text>

          <Text style={{ ...styles.subTitle, marginBottom: 5 }}>{translations.sub_title_2}</Text>

          <Text style={styles.textBlock}>
            <Text style={{ ...styles.text, fontFamily: 'Como-Bold' }}>{translations.text_bold_1} </Text>
            {translations.item_text_1}
          </Text>

          <Text style={styles.textBlock}>
            <Text style={{ ...styles.text, fontFamily: 'Como-Bold' }}>{translations.text_bold_2} </Text>
            {translations.item_text_2}
          </Text>

          <Text style={styles.textBlock}>
            <Text style={{ ...styles.text, fontFamily: 'Como-Bold' }}>{translations.text_bold_3} </Text>
            {translations.item_text_3}
          </Text>

          <Text style={styles.textBlock}>
            <Text style={{ ...styles.text, fontFamily: 'Como-Bold' }}>{translations.text_bold_4} </Text>
            {translations.item_text_4}
          </Text>

          <Text style={styles.textBlock}>
            <Text style={{ ...styles.text, fontFamily: 'Como-Bold' }}>{translations.text_bold_5} </Text>
            {translations.item_text_5}
          </Text>
        </View>
      </View>
      <View style={styles.footer}>
        <Text style={styles.footerName}>{translations.link}</Text>
      </View>
    </Page>
  );
};

FourPage.propTypes = {};

FourPage.defaultProps = {};

export default FourPage;
