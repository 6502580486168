import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import logo from '../../../../assets/images/Logo.png';
import smartline from '../../../../assets/images/smartline.png';
import tImg from '../../../../assets/images/T.png';
import { summaryPdfStyles } from '../../../../helpers/pdfCommonStyles';

import { getTranslations } from '../translations';

const styles = StyleSheet.create({
  ...summaryPdfStyles,
  title: {
    fontFamily: 'Como-Bold',
    fontSize: 32,
    marginTop: 30,
    letterSpacing: 2,
    width: 400,
    lineHeight: 1,
    marginBottom: 15,
  },
  info: {
    fontSize: 16,
    fontFamily: 'Como-SemiBold',
  },
  image: {
    height: 200,
    backgroundColor: 'lightblue',
    marginBottom: 100,
  },
  blockTitle: {
    fontFamily: 'Como-Bold',
    fontSize: 12,
    width: 180,
    marginBottom: 2,
  },
  items: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
  },
  block: {
    width: '50%',
    fontSize: 12,
    textAlign: 'right',
    marginTop: -27,
  },
  blockText: {
    width: 155,
    fontSize: 10,
    marginLeft: 25,
    lineHeight: 1.1,
  },
  smartline: {
    marginLeft: 170,
    height: 841,
    width: 126,
    position: 'absolute',
    left: 2,
    top: -95,
  },
  letter: {
    width: 30,
    height: 40,
    position: 'absolute',
    right: -130,
    top: -15,
  },
});

const FivePage = ({ data, lang }) => {
  const { translations, t, language } = getTranslations('fivePage', lang);

  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.logo}>
          <Image src={logo} />
          <View style={styles.agreementNumberBlock}>
            <Text style={styles.agreementNumber}>{data?.agreement_id}</Text>
            <Text style={styles.agreementNumber}>{data?.created_at}</Text>
          </View>
        </View>
        <View style={styles.content}>
          <View style={styles.items}>
            <View style={styles.leftSide}>
              <View style={{ ...styles.block, height: 312, marginTop: -8 }}>
                <Text style={styles.blockTitle}>{translations.step_T_frontdesk_title}</Text>
                <Text style={styles.blockText}>{translations.step_T_frontdesk_description}</Text>
                {/* <Image src={tImg} style={styles.letter} /> */}
              </View>

              <View style={{ ...styles.block, height: 140 }}>
                <Text style={styles.blockTitle}>{translations.step_1_frontdesk_title}</Text>
                <Text style={styles.blockText}>{translations.step_1_frontdesk_description}</Text>
              </View>

              <View style={{ ...styles.block, height: 160 }}>
                <Text style={styles.blockTitle}>{translations.step_2_frontdesk_title}</Text>
                <Text style={styles.blockText}>{translations.step_2_frontdesk_description}</Text>
              </View>

              <View style={{ ...styles.block, height: 150 }}>
                <Text style={styles.blockTitle}>{translations.step_3_frontdesk_title}</Text>
                <Text style={styles.blockText}>{translations.step_3_frontdesk_description}</Text>
              </View>

              <View style={{ ...styles.block }}>
                <Text style={styles.blockTitle}>{translations.step_4_frontdesk_title}</Text>
                <Text style={styles.blockText}>{translations.step_4_frontdesk_description}</Text>
              </View>
              {language === 'no' ? null : (
                <Text
                  style={{
                    position: 'absolute',
                    bottom: 145,
                    right: -80,
                    fontFamily: 'Como-Bold',
                    color: '#1F75FE',
                    fontSize: 14,
                  }}
                >
                  {translations.day_90}
                </Text>
              )}
            </View>

            <Image src={smartline} style={styles.smartline} />

            <View style={styles.rightSide}>
              <View
                style={{
                  ...styles.block,
                  textAlign: 'left',
                  marginLeft: 110,
                  height: 312,
                  marginTop: -8,
                }}
              >
                <Text style={styles.blockTitle}>{translations.step_T_customer_title}</Text>
                <Text style={{ ...styles.blockText, marginLeft: 0 }}>{translations.step_T_customer_description}</Text>
              </View>

              <View
                style={{
                  ...styles.block,
                  textAlign: 'left',
                  marginLeft: 110,
                  height: 140,
                }}
              >
                <Text style={styles.blockTitle}>{translations.step_1_customer_title}</Text>
                <Text style={{ ...styles.blockText, marginLeft: 0 }}>{translations.step_1_customer_description}</Text>
              </View>

              <View style={{ ...styles.block, textAlign: 'left', marginLeft: 110, height: 160 }}>
                <Text style={styles.blockTitle}>{translations.step_2_customer_title}</Text>
                <Text style={{ ...styles.blockText, marginLeft: 0 }}>{translations.step_2_customer_description}</Text>
              </View>

              <View style={{ ...styles.block, textAlign: 'left', marginLeft: 110, height: 150 }}>
                <Text style={styles.blockTitle}>{translations.step_3_customer_title}</Text>
                <Text style={{ ...styles.blockText, marginLeft: 0 }}>{translations.step_3_customer_description}</Text>
              </View>

              <View style={{ ...styles.block, textAlign: 'left', marginLeft: 110 }}>
                <Text style={styles.blockTitle}>{translations.step_4_customer_title}</Text>
                <Text style={{ ...styles.blockText, marginLeft: 0 }}>{translations.step_4_customer_description}</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};

FivePage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.objectOf(PropTypes.shape)]),
  lang: PropTypes.string,
};

FivePage.defaultProps = {
  data: null,
  lang: 'sv',
};

export default FivePage;
