import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const MenuLink = ({ path, name, className }) => (
  <div className={styles.container}>
    <Link to={path} className={className}>
      {name}
    </Link>
  </div>
);

MenuLink.propTypes = {
  name: PropTypes.string,
  path: PropTypes.string,
  className: PropTypes.string,
};

MenuLink.defaultProps = {
  name: '',
  path: '',
  className: '',
};

export default MenuLink;
