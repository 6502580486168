import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Pagination } from '@mui/material';
import { AgreementItem, Input } from '../../simple';
import styles from './styles.module.scss';
import { inputName } from '../../../helpers/sidebarItems';

const AgreementsList = ({ agreements, handleCount, totalPages, search, onChangeSearch }) => {
  const { pathname } = useLocation();
  const handleChange = (event) => onChangeSearch(event.target.value);

  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <Input
          name="search"
          type="input"
          onChange={handleChange}
          value={search}
          label={inputName(pathname)}
          className={styles.roundedInput}
        />
        <div className={styles.list}>
          {agreements &&
            agreements.map((agreement) => (
              <AgreementItem
                key={agreement.id}
                id={agreement.id}
                name={agreement.company_name}
                data={agreement.created_at}
                step={agreement.step}
                company={agreement.company_id}
                isDuplicate={agreement.is_duplicate}
              />
            ))}
        </div>
      </div>
      <div className={styles.pagination}>
        <Pagination count={totalPages} onChange={handleCount} />
      </div>
    </div>
  );
};

AgreementsList.propTypes = {
  agreements: PropTypes.arrayOf(PropTypes.shape),
  handleCount: PropTypes.func,
  totalPages: PropTypes.number,
  search: PropTypes.string,
  onChangeSearch: PropTypes.func,
};

AgreementsList.defaultProps = {
  agreements: null,
  handleCount: () => {},
  totalPages: 0,
  search: '',
  onChangeSearch: () => null,
};

export default AgreementsList;
