import React from 'react';
import PropTypes from 'prop-types';
import FirstPage from './FirstPage';
import SecondPage from './SecondPage';
import ThirdPage from './ThirdPage';
import FourPage from './FourPage';
import FivePage from './FivePage';
import SixPage from './SixPage';
import SevenPage from './SevenPage';
import EightPage from './EightPage';
import NinePage from './NinePage';

const PersonalDataServiceAgreement = ({ data }) => (
  <>
    <FirstPage data={data} />
    <SecondPage />
    <ThirdPage />
    <FourPage />
    <FivePage />
    <SixPage />
    <SevenPage />
    <EightPage />
    <NinePage />
  </>
);

PersonalDataServiceAgreement.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.objectOf(PropTypes.shape)]),
};

PersonalDataServiceAgreement.defaultProps = {
  data: null,
};

export default PersonalDataServiceAgreement;
