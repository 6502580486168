import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import logo from '../../../../assets/images/Logo.png';
import element1 from '../../../../assets/images/element1.png';
import s from '../../../../assets/images/S.png';
import main from '../../../../assets/images/main2.png';
import { summaryPdfStyles } from '../../../../helpers/pdfCommonStyles';

import { getTranslations } from '../translations';

const styles = StyleSheet.create({
  ...summaryPdfStyles,
  title: {
    fontFamily: 'Como-Bold',
    fontSize: 30,
    marginTop: 30,
    letterSpacing: 4,
    width: 400,
    lineHeight: 1,
    marginBottom: 15,
  },
  info: {
    fontSize: 12,
    letterSpacing: 1.5,
    fontFamily: 'Como-SemiBold',
    lineHeight: 2,
  },
  image: {
    marginTop: 10,
  },
  blockTitle: {
    fontFamily: 'Como-Bold',
    fontSize: 12,
    width: 180,
    marginBottom: 2,
  },
  items: {
    display: 'flex',
    flexDirection: 'row',
  },
  block: {
    width: '50%',
    fontSize: 12,
    textAlign: 'right',
    height: 300,
    paddingTop: 133,
  },
  blockText: {
    width: 220,
    fontSize: 10,
    lineHeight: 1.1,
    textAlign: 'right',
    paddingRight: 40,
  },
  element1: {
    marginLeft: 170,
    width: 50,
    position: 'absolute',
    left: 35,
    top: 80,
  },
  letter: {
    width: 30,
    height: 40,
    position: 'absolute',
    right: 20,
    top: 115,
  },
});

const ThirdPage = ({ data, lang }) => {
  const { translations, t, language } = getTranslations('thirdPage', lang);

  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.logo}>
          <Image src={logo} />
          <View style={styles.agreementNumberBlock}>
            <Text style={styles.agreementNumber}>{data?.agreement_id}</Text>
            <Text style={styles.agreementNumber}>{data?.created_at}</Text>
          </View>
        </View>
        <View style={styles.content}>
          <Text style={styles.title}>{translations.title}</Text>
          <Text style={styles.info}>{translations.info}</Text>

          <View style={styles.image}>
            <Image src={main} style={{ height: 200 }} />
          </View>

          <View>
            <Text
              style={{
                position: 'absolute',
                fontSize: 12,
                fontFamily: 'Como-Bold',
                bottom: 130,
                left: 40,
              }}
            >
              {translations.label_1}
            </Text>
          </View>

          <View>
            <Text
              style={{
                position: 'absolute',
                fontSize: 12,
                fontFamily: 'Como-Bold',
                bottom: 170,
                right: 50,
              }}
            >
              {translations.label_2}
            </Text>
          </View>

          <View>
            <Text
              style={{
                position: 'absolute',
                fontSize: 12,
                fontFamily: 'Como-Bold',
                bottom: 70,
                right: 0,
              }}
            >
              {translations.label_3}
            </Text>
          </View>

          <View style={{ paddingTop: 30 }}>
            {language === 'no' ? null : (
              <Text
                style={{
                  color: '#1F75FE',
                  position: 'absolute',
                  left: 210,
                  top: 110,
                  textAlign: 'center',
                  fontFamily: 'Como-Bold',
                  fontSize: 14,
                  marginRight: 15,
                }}
              >
                {translations.day_1}
              </Text>
            )}
          </View>
          <View style={styles.items}>
            <View style={styles.block}>
              <Text style={styles.blockTitle}>{translations.step_S_frontdesk_title}</Text>
              <Text style={styles.blockText}>{translations.step_S_frontdesk_description}</Text>
              <Image src={s} style={styles.letter} />
            </View>

            <Image src={element1} style={styles.element1} />
            <View>
              <Text> </Text>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};

ThirdPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.objectOf(PropTypes.shape)]),
  lang: PropTypes.string,
};

ThirdPage.defaultProps = {
  data: null,
  lang: 'sv',
};

export default ThirdPage;
