import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import logo from '../../../../assets/images/Logo.png';
import { summaryPdfStyles } from '../../../../helpers/pdfCommonStyles';

import { getTranslations } from '../translations';

const styles = StyleSheet.create({
  ...summaryPdfStyles,
  content: {
    ...summaryPdfStyles.content,
    width: '85%',
  },
  title: {
    fontFamily: 'Como-Bold',
    fontSize: 18,
    lineHeight: 1.5,
  },
  text: {
    fontSize: 12,
    margin: '10px 0',
    letterSpacing: 1.2,
    lineHeight: 1.5,
  },
  footerName: {
    ...summaryPdfStyles.footerName,
    marginBottom: 5,
  },
});

const SecondPage = ({ data, lang }) => {
  const { translations, t } = getTranslations('secondPage', lang);

  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.logo}>
          <Image src={logo} />
          <View style={styles.agreementNumberBlock}>
            <Text style={styles.agreementNumber}>{data?.agreement_id}</Text>
            <Text style={styles.agreementNumber}>{data?.created_at}</Text>
          </View>
        </View>

        <View style={styles.content}>
          <Text style={{ ...styles.title, marginTop: 50 }}>{translations.hello}</Text>
          <Text style={styles.text}>{translations.hello_description}</Text>

          <Text style={{ ...styles.title, width: 300, marginTop: 20 }}>{translations.pingaway}</Text>
          <Text style={styles.text}>{translations.pingaway_description}</Text>

          <Text style={{ ...styles.title, fontSize: 14, marginTop: 20 }}>{translations.definitioner}</Text>
          <Text style={styles.text}>
            {translations.definitioner_info
              .replace('{{company_name}}', data?.company.name)
              .replace('{{organization_number}}', data?.company.organization_number)}
          </Text>
        </View>
      </View>
      <View style={styles.footer}>
        <View style={styles.footerName}>
          <Text style={{ fontFamily: 'Como-Bold' }}>{t.common.offer} </Text>
          <Text style={{ fontFamily: 'Como-SemiBold' }}>{data?.company.name}</Text>
        </View>
        <Text style={{ fontFamily: 'Como-Medium' }}>{data?.created_at}</Text>
      </View>
    </Page>
  );
};

SecondPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.objectOf(PropTypes.shape)]),
  lang: PropTypes.string,
};

SecondPage.defaultProps = {
  data: null,
  lang: 'sv',
};

export default SecondPage;
