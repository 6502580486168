import React from 'react';
import PropTypes from 'prop-types';
import FirstPage from './FirstPage';
import SecondPage from './SecondPage';
import ThirdPage from './ThirdPage';
import FourPage from './FourPage';

const SLA = ({ data }) => (
  <>
    <FirstPage />
    <SecondPage />
    <ThirdPage />
    <FourPage />
  </>
);

SLA.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.objectOf(PropTypes.shape)]),
};

SLA.defaultProps = {
  data: null,
};

export default SLA;
