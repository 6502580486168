import React from 'react';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import logo from '../../../../assets/images/Logo.png';
import { roamingPdfStyles } from '../../../../helpers/pdfCommonStyles';
import { getTranslations } from '../translations';

const styles = StyleSheet.create({
  ...roamingPdfStyles,
  title: {
    fontSize: 31,
    fontFamily: 'Como-ExtraBold',
    marginBottom: 20,
  },
  container: {
    ...roamingPdfStyles.container,
    width: '100%',
  },
  cell: {
    ...roamingPdfStyles.cell,
    width: 100,
  },
});

const tableCreator = (
  roamingzon,
  inomLandet,
  tillVerige,
  tillZon1,
  tillZon2,
  tillZon3,
  tillZon7,
  emotSamtal,
  emotSMS,
  skickaSMS,
) => ({
  roamingzon,
  inomLandet,
  tillVerige,
  tillZon1,
  tillZon2,
  tillZon3,
  tillZon7,
  emotSamtal,
  emotSMS,
  skickaSMS,
});

const tableValues = [
  tableCreator('1', 'EU*/0.425', 'EU*/0.425', 'EU*/0.425', '5,00', '16,00', '48,00', 'EU*/ 0,15', '0', 'EU*/ 0,13'),
  tableCreator('2a', '1,20', '1,20', '1,20', '5,00', '16,00', '48,00', '4,00', '0', '0,40'),
  tableCreator('2b', '2,40', '2,40', '2,40', '5,00', '16,00', '48,00', '4,00', '0', '2.40'),
  tableCreator('2c', '10,00', '10,00', '10,00', '10,00', '16,00', '48,00', '4,00', '0', '3,20'),
  tableCreator('3', '20,00', '20,00', '20,00', '20,00', '20,00', '48,00', '6,00', '0', '3,20'),
  tableCreator('4', '16,00', '16,00', '16,00', '16,00', '16,00', '48,00', '6,00', '0', '3,20'),
  tableCreator('5a', '20,00', '20,00', '20,00', '20,00', '20,00', '48,00', '8,00', '0', '3,20'),
  tableCreator('5b', '26,00', '26,00', '26,00', '26,00', '26,00', '48,00', '8,00', '0', '3,20'),
  tableCreator('5c', '40,00', '40,00', '40,00', '40,00', '40,00', '48,00', '18,00', '0', '3,20'),
  tableCreator('6', '26,00', '26,00', '26,00', '26,00', '26,00', '48,00', '28,00', '0', '3,20'),
  tableCreator('7', '48,00', '48,00', '48,00', '48,00', '48,00', '48,00', '48,00', '0', '4,0'),
  tableCreator('Thailand', '6,00', '6,00', '16,00', '16,00', '20,00', '48,00', '6,00', '0', '3,20'),
];

const FirstPage = () => {
  const { translations } = getTranslations( 'roaming_first_page');

  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.header}>
          <View style={styles.logo}>
            <Image src={logo} />
          </View>
          <View>
            <Text style={styles.title}>{translations.title}</Text>
          </View>
        </View>

        <View style={styles.containerWrapper}>
          <View style={styles.tableContainer}>
            <Text style={styles.tableTitle}>{translations.table_title}</Text>
            <View>
              <View style={styles.tableHeader}>
                <Text style={{ ...styles.cell, width: 80 }}>{translations.table_head_1}</Text>
                <View style={{ ...styles.cell, width: 80 }}>
                  <Text>{translations.table_head_2}</Text>
                  <Text style={styles.cellSub}>
                    ({translations.value}/{translations.min})
                  </Text>
                </View>
                <View style={{ ...styles.cell, width: 70 }}>
                  <Text>{translations.table_head_3}</Text>
                  <Text style={styles.cellSub}>
                    ({translations.value}/{translations.min})
                  </Text>
                </View>
                <View style={{ ...styles.cell, width: 60 }}>
                  <Text>{translations.table_head_4}</Text>
                  <Text style={styles.cellSub}>
                    ({translations.value}/{translations.min})
                  </Text>
                </View>
                <View style={{ ...styles.cell, width: 85 }}>
                  <Text>{translations.table_head_5}</Text>
                  <Text style={styles.cellSub}>
                    ({translations.value}/{translations.min})
                  </Text>
                </View>
                <View style={{ ...styles.cell, width: 80 }}>
                  <Text>{translations.table_head_6}</Text>
                  <Text style={styles.cellSub}>
                    ({translations.value}/{translations.min})
                  </Text>
                </View>
                <View style={{ ...styles.cell, width: 65 }}>
                  <Text>{translations.table_head_7}</Text>
                  <Text style={styles.cellSub}>
                    ({translations.value}/{translations.min})
                  </Text>
                </View>
                <View style={{ ...styles.cell, width: 100 }}>
                  <Text>{translations.table_head_8}</Text>
                  <Text style={styles.cellSub}>
                    ({translations.value}/{translations.min})
                  </Text>
                </View>
                <Text style={{ ...styles.cell, width: 85 }}>{translations.table_head_9}</Text>
                <View style={{ ...styles.cell, width: 70 }}>
                  <Text>{translations.table_head_10}</Text>
                  <Text style={styles.cellSub}>
                    ({translations.value}/{translations.sms})
                  </Text>
                </View>
              </View>
              <>
                {tableValues.map((item) => (
                  <View style={styles.row} key={item.roamingzon}>
                    <Text style={{ ...styles.cell, width: 80 }}>{item.roamingzon}</Text>
                    <Text style={{ ...styles.cell, width: 80 }}>{item.inomLandet}</Text>
                    <Text style={{ ...styles.cell, width: 70 }}>{item.tillVerige}</Text>
                    <Text style={{ ...styles.cell, width: 60 }}>{item.tillZon1}</Text>
                    <Text style={{ ...styles.cell, width: 85 }}>{item.tillZon2}</Text>
                    <Text style={{ ...styles.cell, width: 80 }}>{item.tillZon3}</Text>
                    <Text style={{ ...styles.cell, width: 65 }}>{item.tillZon7}</Text>
                    <Text style={{ ...styles.cell, width: 100 }}>{item.emotSamtal}</Text>
                    <Text style={{ ...styles.cell, width: 85 }}>{item.emotSMS}</Text>
                    <Text style={{ ...styles.cell, width: 70 }}>{item.skickaSMS}</Text>
                  </View>
                ))}
              </>
            </View>
            <Text style={styles.tableInfo}>{translations.table_info}</Text>
          </View>
        </View>
      </View>
      <View style={styles.footer}>
        <Text>{translations.link}</Text>
      </View>
    </Page>
  );
};

export default FirstPage;
