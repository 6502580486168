import React from 'react';
import PropTypes from 'prop-types';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { securityPdfStyles } from '../../../../helpers/pdfCommonStyles';

const styles = StyleSheet.create({
  ...securityPdfStyles,
});

// eslint-disable-next-line arrow-body-style
const Footer = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return (
    <View style={styles.footerBg}>
      <View style={styles.footer}>
        <Text style={styles.footerName}>© {currentYear} Pingaway. All rights reserved.</Text>
      </View>
    </View>
  );
};

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
