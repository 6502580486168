/* eslint-disable camelcase */
/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../../pages/NewAgreementThirdPage/styles.module.scss';
import { TotalTBCard } from '../../simple';
import useMultiLang from '../../../localization/useMultiLang';
// import { totalTBMedCalculate } from '../../../helpers/tablesValues';

const TbCardsMenu = ({ tbRamavtal, tbSwitch, ramValue, switchValue, totalTBMedCalculate }) => {
  const { t } = useMultiLang();

  const ramCalculate = totalTBMedCalculate(tbRamavtal, ramValue);
  const switchCalculate = totalTBMedCalculate(tbSwitch, switchValue);
  const partner_type = localStorage.getItem('partner_type');

  return (
    <div className={styles.totalCards}>
      <TotalTBCard
        title={t('total_TB_without_financial_agreement')}
        pingRam={tbRamavtal}
        pingSwitch={tbSwitch}
        partner_type={partner_type}
      />
      <TotalTBCard
        title={t('total_TB_with_financial_agreement')}
        pingRam={ramCalculate}
        pingSwitch={switchCalculate}
        partner_type={partner_type}
      />
      {/* <TotalTBCard
        title="Total TB efter avdrag"
        pingRam={tbRamavtal - ramCalculate}
        pingSwitch={tbSwitch - switchCalculate}
        partner_type={partner_type}
      /> */}
    </div>
  );
};

TbCardsMenu.propTypes = {
  tbRamavtal: PropTypes.number,
  tbSwitch: PropTypes.number,
  ramValue: PropTypes.number,
  switchValue: PropTypes.number,
  totalTBMedCalculate: PropTypes.func,
};

TbCardsMenu.defaultProps = {
  tbRamavtal: 0,
  tbSwitch: 0,
  ramValue: 0,
  switchValue: 0,
  totalTBMedCalculate: () => 0,
};

export default TbCardsMenu;
