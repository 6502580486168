import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import { securityPdfStyles } from '../../../../helpers/pdfCommonStyles';

import logo from '../../../../assets/images/Logo.png';
import Footer from './footer';

const styles = StyleSheet.create({
  ...securityPdfStyles,
  content: {
    ...securityPdfStyles.content,
    marginTop: 100,
  },
});

// eslint-disable-next-line arrow-body-style
const ThirdPage = () => {
  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.logo}>
          <Image src={logo} />
        </View>

        <View style={styles.content}>
          <Text style={styles.subTitle}>Service assurance for voice</Text>
          <Text style={styles.text}>
            We have automated monitoring and alerting systems and processes that continuously conduct real-world voice
            calls to ensure the highest voice quality and route availability.
          </Text>

          <Text style={styles.subTitle}>Dynamic scaling</Text>
          <Text style={styles.text}>
            Our services are built on top of a virtual infrastructure, allowing us to scale services to meet increases
            in demand. This, combined with the fact that all our applicationsare engineered with active high
            availability, ensure that we can handle mostsituations gracefully without human intervention.
          </Text>

          <Text style={styles.subTitle}>Network security scanning</Text>
          <Text style={styles.text}>
            We have automated and continuous network scanning that scans the external and internal network perimeter
            (i.e. outside and inside of edge firewalls) for securitycompliance. All reports on deviations are reviewed
            on at least a weekly basis, and mitigations are tracked from this process.
          </Text>
          <Text style={styles.subTitle}>Firewalling and DDoS protection</Text>
          <Text style={styles.text}>
            Pingaway maintains restrictive L2+ capable network-level firewalling around the whole of its perimeter in
            addition to host-based application-specific firewalls. Additionally, all service-level HTTPS traffic is
            proxied through web application firewalls. External scrubbing services are employed to mitigate volumetric
            DDoS attacks.
          </Text>
        </View>
      </View>
      <Footer />
    </Page>
  );
};

ThirdPage.propTypes = {};

ThirdPage.defaultProps = {};

export default ThirdPage;
