import React from 'react';
import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { getTranslations } from '../translations';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'ArialRegular',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: '#000',
  },
  container: {
    height: 830,
  },
  content: {
    fontSize: 7,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: '40%',
  },
  title: {
    color: '#2072f5',
    fontSize: 12,
    fontFamily: 'ArialBoldItalic',
    marginTop: 15,
    marginLeft: 44,
    marginBottom: 20,
  },
  block: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 8,
  },
  blockText: {
    flexWrap: 'wrap',
  },
  number: {
    paddingLeft: 20,
    marginRight: 18,
  },
  blockHeader: {
    color: '#2072f5',
    fontSize: 8,
  },
  footer: {
    fontSize: 8,
    color: 'grey',
    width: 250,
    marginLeft: 30,
  },
});

const SecondPage = () => {
  const { translations } = getTranslations('grenke_second_page');

  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View>
          <Text style={styles.title}>{translations.title}</Text>
        </View>

        <View style={{ marginLeft: 44 }}>
          <Text style={{ fontSize: 8, color: 'grey', marginBottom: 5 }}>{translations.info}</Text>
          <Text style={{ fontSize: 14, marginBottom: 10 }}>{translations.sub_title}</Text>
        </View>

        <View style={styles.content}>
          <View style={styles.block}>
            <Text style={styles.number}>1.</Text>
            <View>
              <Text style={styles.blockHeader}>{translations.block_head_1}</Text>
              <Text style={styles.blockText}>{translations.block_text_1}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <Text style={styles.number}>2.</Text>
            <View>
              <Text style={styles.blockHeader}>{translations.block_head_2}</Text>
              <Text style={styles.blockText}>{translations.block_text_2_1}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <Text style={{ ...styles.number, marginRight: 22 }}> </Text>
            <View>
              <Text style={styles.blockText}>{translations.block_text_2_2}</Text>
            </View>
          </View>

          <View style={{ ...styles.block, marginBottom: 0 }}>
            <Text style={{ ...styles.number, marginRight: 22 }}>-</Text>
            <View>
              <Text style={styles.blockText}>{translations.block_text_2_3}</Text>
            </View>
          </View>
          <View style={styles.block}>
            <Text style={{ ...styles.number, marginRight: 22 }}>-</Text>
            <View>
              <Text style={styles.blockText}>{translations.block_text_2_4}</Text>
            </View>
          </View>
          <View style={styles.block}>
            <Text style={{ ...styles.number, marginRight: 22 }}> </Text>
            <View>
              <Text style={styles.blockText}>{translations.block_text_2_5}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <Text style={styles.number}>3.</Text>
            <View>
              <Text style={styles.blockHeader}>{translations.block_head_3}</Text>
              <Text style={styles.blockText}>{translations.block_text_3}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <Text style={styles.number}>4.</Text>
            <View>
              <Text style={styles.blockHeader}>{translations.block_head_4}</Text>
              <Text style={styles.blockText}>{translations.block_text_4}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <Text style={styles.number}>5.</Text>
            <View>
              <Text style={styles.blockHeader}>{translations.block_head_5}</Text>
              <Text style={styles.blockText}>{translations.block_text_5}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <Text style={styles.number}>6.</Text>
            <View>
              <Text style={styles.blockHeader}>{translations.block_head_6}</Text>
              <Text style={styles.blockName}>{translations.block_head_6_name_1}</Text>
              <Text style={styles.blockText}>{translations.block_text_6_1}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <Text style={{ ...styles.number, marginRight: 23 }}> </Text>
            <View>
              <Text>{translations.block_head_6_name_2}</Text>
              <Text style={styles.blockText}>{translations.block_text_6_2}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <Text style={{ ...styles.number, marginRight: 23 }}> </Text>
            <View>
              <Text>{translations.block_head_6_name_3}</Text>
              <Text style={styles.blockText}>{translations.block_text_6_3}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <Text style={styles.number}>7.</Text>
            <View>
              <Text style={styles.blockHeader}>{translations.block_head_7} </Text>
              <Text style={styles.blockText}>{translations.block_text_7}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <Text style={styles.number}>8.</Text>
            <View>
              <Text style={styles.blockHeader}>{translations.block_head_8} </Text>
              <Text style={styles.blockText}>{translations.block_text_8}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <Text style={styles.number}>9.</Text>
            <View>
              <Text style={styles.blockHeader}>{translations.block_head_9}</Text>
              <Text style={styles.blockText}>{translations.block_text_9}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <Text style={{ ...styles.number, marginRight: 13 }}>10.</Text>
            <View>
              <Text style={styles.blockHeader}>{translations.block_head_10}</Text>
              <Text style={styles.blockText}>{translations.block_text_10}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <Text style={{ ...styles.number, marginRight: 13 }}>11.</Text>
            <View>
              <Text style={styles.blockHeader}>{translations.block_head_11}</Text>
              <Text style={styles.blockText}>{translations.block_text_11}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <Text style={{ ...styles.number, marginRight: 13 }}>12.</Text>
            <View>
              <Text style={styles.blockHeader}>{translations.block_head_12}</Text>
              <Text style={styles.blockText}>{translations.block_text_12}</Text>
            </View>
          </View>
        </View>
        <View style={styles.footer}>
          <Text>{translations.footer}</Text>
        </View>
      </View>
    </Page>
  );
};

export default SecondPage;
