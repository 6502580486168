import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import { personalDataServiceAgreementPdfStyles } from '../../../../helpers/pdfCommonStyles';
import { getTranslations } from '../translations';

const styles = StyleSheet.create({
  ...personalDataServiceAgreementPdfStyles,

  text: {
    ...personalDataServiceAgreementPdfStyles.text,
    lineHeight: 1.2,
  },

  textBold: {
    ...personalDataServiceAgreementPdfStyles.textBold,
    lineHeight: 1.2,
  },

  table: {
    borderWidth: 1,
    borderColor: '#000',
  },

  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
  },
  tableCol_1: {
    flex: 1,
    padding: '5px 10px',
    borderRightWidth: 1,
    borderRightColor: '#000',
  },
  tableCol_2: {
    flex: 3,
    padding: '5px 10px',
  },
});

// eslint-disable-next-line arrow-body-style
const EightPage = () => {
  const { translations, t } = getTranslations('personalDataServiceAgreement_eight_page');

  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.content}>
          <Text style={{ ...styles.title, marginBottom: 20 }}>{translations.title}</Text>

          <Text style={styles.text}>{translations.block_text_1}</Text>

          <Text style={{ ...styles.textBold, marginTop: 10, marginBottom: 10 }}>{translations.sub_title_1}</Text>

          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol_1}>
                <Text style={styles.textBold}>{translations.table_head_1}</Text>
              </View>
              <View style={styles.tableCol_2}>
                <Text style={styles.text}>{translations.table_text_1}</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCol_1}>
                <Text style={styles.textBold}>{translations.table_head_2}</Text>
              </View>
              <View style={styles.tableCol_2}>
                <Text style={styles.text}>{translations.table_text_2}</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCol_1}>
                <Text style={styles.textBold}>{translations.table_head_3}</Text>
              </View>
              <View style={styles.tableCol_2}>
                <Text style={styles.text}>{translations.table_text_3}</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCol_1}>
                <Text style={styles.textBold}>{translations.table_head_4}</Text>
              </View>
              <View style={styles.tableCol_2}>
                <Text style={styles.text}>{translations.table_item_1}</Text>
                <Text style={styles.text}>{translations.table_item_2}</Text>
                <Text style={styles.text}>{translations.table_item_3}</Text>
                <Text style={styles.text}>{translations.table_item_4}</Text>
              </View>
            </View>

            <View style={{ ...styles.tableRow, borderBottom: 'none' }}>
              <View style={styles.tableCol_1}>
                <Text style={styles.textBold}>{translations.table_head_5}</Text>
              </View>
              <View style={styles.tableCol_2}>
                <Text style={styles.text}>{translations.table_text_5}</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};

EightPage.propTypes = {};

EightPage.defaultProps = {};

export default EightPage;
