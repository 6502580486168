import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const Input = ({ name, type, onChange, value, label, className, labelStyle, readonly, min, placeholder }) => (
  <div className={styles.inputWrapper}>
    <label htmlFor={name} className={`${styles.label} ${labelStyle}`}>
      {label}
    </label>
    <input
      id={name}
      name={name}
      type={type}
      onChange={onChange}
      value={value}
      className={`${styles.input} ${className}`}
      readOnly={readonly}
      min={min}
      placeholder={placeholder}
    />
  </div>
);

Input.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string || PropTypes.number,
  label: PropTypes.string,
  className: PropTypes.string,
  labelStyle: PropTypes.string,
  readonly: PropTypes.bool,
  min: PropTypes.string,
  placeholder: PropTypes.string,
};

Input.defaultProps = {
  name: '',
  type: '',
  onChange: () => {},
  value: '',
  label: '',
  className: '',
  labelStyle: '',
  readonly: false,
  min: '',
  placeholder: '',
};

export default Input;
