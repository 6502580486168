import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import { translate } from '../../../localization/LanguageContext';

const StyledSelect = ({ title, amount, onChange, values, value }) => (
  <div className={styles.container}>
    <p>{title}</p>
    <select onChange={onChange} value={value}>
      {values.map((item) => (
        <option value={item} key={item}>
          {item} {amount}
        </option>
      ))}
    </select>
  </div>
);

StyledSelect.propTypes = {
  title: PropTypes.string,
  amount: PropTypes.string,
  onChange: PropTypes.func,
  values: PropTypes.arrayOf(PropTypes.number),
  value: PropTypes.number,
};

StyledSelect.defaultProps = {
  title: '',
  amount: translate('mon'),
  onChange: () => {},
  values: null,
  value: 0,
};

export default StyledSelect;
