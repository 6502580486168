import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import logo from '../../../../assets/images/Logo.png';
import { summaryPdfStyles } from '../../../../helpers/pdfCommonStyles';
import { getTranslations } from '../translations';

const styles = StyleSheet.create({
  ...summaryPdfStyles,
  containerWrapper: {
    width: '90%',
    margin: '0 auto',
  },
  title: {
    fontSize: 30,
    fontFamily: 'Como-ExtraBold',
    marginTop: 50,
    marginBottom: 5,
  },
  table: {
    marginBottom: 10,
    paddingBottom: 15,
    borderBottom: 1,
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: 1,
    paddingBottom: 2,
    marginBottom: 5,
    fontSize: 10,
  },
  tableTitle: {
    fontFamily: 'Como-Bold',
  },
  footerName: {
    ...summaryPdfStyles.footerName,
    marginBottom: 3,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 10,
    fontWeight: 300,
    padding: '2px 0',
  },
  rowName: {
    width: 200,
    paddingLeft: 15,
  },
  cell: {
    width: 130,
    fontSize: 10,
    fontWeight: 300,
    textAlign: 'center',
  },
});

const Discount = ({ data, ramValues, switchValues, ramDiscount, switchDiscount }) => {
  const { translations, t } = getTranslations( 'discount');

  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.logo}>
          <Image src={logo} />
          <View style={styles.agreementNumberBlock}>
            <Text style={styles.agreementNumber}>{data?.agreement_id}</Text>
            <Text style={styles.agreementNumber}>{data?.created_at}</Text>
          </View>
        </View>
        <View style={styles.containerWrapper}>
          <Text style={styles.title}>{translations.title}</Text>
          <Text style={{ fontSize: 12, fontFamily: 'Como-Bold', marginBottom: 30 }}>{translations.description}</Text>

          <View style={styles.table}>
            <View style={{ ...styles.tableHeader, fontSize: 12 }}>
              <Text style={{ ...styles.tableTitle, width: '50%', paddingLeft: 5 }}>{translations.table_title_1}</Text>
              <Text style={styles.tableTitle}>{translations.table_title_2}</Text>
            </View>

            <View style={styles.tableHeader}>
              <Text style={{ ...styles.tableTitle, width: '37%', paddingLeft: 5 }}> </Text>
              <Text style={{ ...styles.tableTitle, width: '13%' }}>{translations.table_head_1}</Text>
              <Text style={{ ...styles.tableTitle, width: '15%', margin: '0 10px' }}>{translations.table_head_2}</Text>
              <Text style={{ ...styles.tableTitle, width: '15%', margin: '0 10px' }}>{translations.table_head_3}</Text>
              <Text style={{ ...styles.tableTitle, width: '12%', margin: '0 10px' }}>{translations.table_head_4}</Text>
            </View>
            <Text style={{ ...styles.tableTitle, fontSize: 12, paddingLeft: 5 }}>{translations.mon_discount}</Text>
            {ramValues?.price_per_month < 3999 && ramValues?.num_services > 0 && (
              <View style={styles.row}>
                <Text style={styles.rowName}>Ping RAM {data?.ping_ram_duration} mån</Text>
                <Text style={styles.cell}>{ramValues?.num_services}</Text>
                <Text style={styles.cell}>3999</Text>
                <Text style={styles.cell}>{parseFloat(ramValues?.price_per_month)}</Text>
                <Text style={styles.cell}>{3999 - parseFloat(ramValues?.price_per_month)}</Text>
              </View>
            )}
            {switchValues?.price_per_month < 3999 && switchValues?.num_services > 0 && (
              <View style={styles.row}>
                <Text style={styles.rowName}>Ping Switch {data?.ping_switch_duration} mån</Text>
                <Text style={styles.cell}>{switchValues?.num_services}</Text>
                <Text style={styles.cell}>3999</Text>
                <Text style={styles.cell}>{parseFloat(switchValues?.price_per_month)}</Text>
                <Text style={styles.cell}>{3999 - parseFloat(switchValues?.price_per_month)}</Text>
              </View>
            )}
          </View>
          <View
            style={{
              ...styles.tableHeader,
              fontSize: 14,
              justifyContent: 'space-between',
              borderBottom: 'none',
              padding: '0 10px',
            }}
          >
            <Text style={styles.tableTitle}>{translations.save_a_month}</Text>
            <Text style={styles.tableTitle}>
              {ramDiscount + switchDiscount} {translations.sek}
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.footer}>
        <Text style={styles.footerName}>
          <Text style={{ fontFamily: 'Como-Bold' }}>{t.common.offer} </Text>
          {data?.company.name}
        </Text>
        <Text>{data?.created_at}</Text>
      </View>
    </Page>
  );
};
Discount.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.objectOf(PropTypes.shape)]),
  ramValues: PropTypes.objectOf(PropTypes.shape),
  switchValues: PropTypes.objectOf(PropTypes.shape),

  ramDiscount: PropTypes.number,
  switchDiscount: PropTypes.number,
};

Discount.defaultProps = {
  data: null,
  ramValues: null,
  switchValues: null,
  ramDiscount: 0,
  switchDiscount: 0,
};

export default Discount;
