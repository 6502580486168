import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const ScaleCard = ({ title, firstValue, secondValue, firstName, secondName, amount }) => (
  <div className={styles.container}>
    <div className={styles.title}>
      <p>{title}</p>
    </div>
    <div className={styles.body}>
      <div className={styles.section}>
        <p>
          {firstValue} {amount}
        </p>
        <span>{firstName}</span>
      </div>
      {secondName && (
        <div className={styles.section}>
          <p>
            {secondValue} {amount}
          </p>
          <span>{secondName}</span>
        </div>
      )}
    </div>
  </div>
);

ScaleCard.propTypes = {
  title: PropTypes.string,
  firstValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  secondValue: PropTypes.number,
  firstName: PropTypes.string,
  secondName: PropTypes.string,
  amount: PropTypes.string,
};

ScaleCard.defaultProps = {
  title: '',
  firstValue: 0,
  secondValue: 0,
  firstName: '',
  secondName: '',
  amount: 'kr',
};

export default ScaleCard;
