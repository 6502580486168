import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { StepPage } from '../../components/complex';
import NewAgreementSecondStep from './NewAgreementSecondStep';
import { newAgreementStep1 } from '../../router/routes';

const NewAgreementSecondPage = () => {
  const navigate = useNavigate();
  const company = localStorage.getItem('companyId');

  useEffect(() => {
    if (!company) navigate(newAgreementStep1);
  }, [company]);

  return (
    <StepPage>
      <NewAgreementSecondStep />
    </StepPage>
  );
};

export default NewAgreementSecondPage;
