import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import { hardwarePotPdfStyles } from '../../../../helpers/pdfCommonStyles';
import { getTotal } from '../../../../helpers/finalInvoiceAndHardwarePot';
import { feeGroup } from '../../../../helpers/tablesValues';

import logo from '../../../../assets/images/Logo.png';
import { getTranslations } from '../translations';

const styles = StyleSheet.create({
  ...hardwarePotPdfStyles,
  container: {
    ...hardwarePotPdfStyles.container,
    marginTop: 70,
  },
  form: {
    ...hardwarePotPdfStyles.form,
    marginTop: 50,
  },
});

// eslint-disable-next-input arrow-body-style
const FinalInvoiceAndHardwarePot = ({ data }) => {
  const total1 = getTotal(data?.fees, feeGroup.FINAL_INVOICE);
  const total2 = getTotal(data?.fees, feeGroup.HARDWARE_POT);
  const { translations, t } = getTranslations('hardwarePot_finalInvoice');

  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.content}>
          <Text style={{ ...styles.subTitle, marginBottom: 20 }}>{translations.title_1}</Text>
          <Text style={{ ...styles.title, marginBottom: 20 }}>{translations.title_2}</Text>

          <Text style={{ ...styles.textBold, marginBottom: 5 }}>{translations.sub_title_finalInvoice}</Text>
          <Text style={styles.text}>
            <Text style={styles.text}>{translations.text_finalInvoice.replace('{{total}}', total1)}</Text>
          </Text>

          <Text style={{ ...styles.textBold, marginBottom: 5, marginTop: 15 }}>
            {translations.sub_title_hardwarePot}
          </Text>
          <Text style={styles.text}>{translations.text_hardwarePot.replace('{{total}}', total2)}</Text>

          <View style={styles.form}>
            <View style={styles.block}>
              <View style={styles.item}>
                <View style={styles.input} />
                <Text style={styles.textBold}>{translations.input_1}</Text>
              </View>

              <View style={styles.item}>
                <View style={styles.input} />
                <Text style={styles.textBold}>{translations.input_2}</Text>
              </View>

              <View style={styles.item}>
                <View style={styles.input} />
                <Text style={styles.textBold}>{translations.input_3}</Text>
              </View>
            </View>

            <View style={styles.block}>
              <View style={styles.item}>
                <View style={styles.input}>
                  <Text style={{ ...styles.text }}>{data?.company?.name}</Text>
                </View>
                <Text style={styles.textBold}>{translations.input_4}</Text>
              </View>
              <View style={styles.item}>
                <View style={styles.input}>
                  <Text style={{ ...styles.text }}>{data?.company?.organization_number}</Text>
                </View>
                <Text style={styles.textBold}>{translations.input_5}</Text>
              </View>
            </View>
          </View>
        </View>
      </View>

      <View style={styles.footer}>
        <View style={styles.footerCol}>
          <Text style={styles.footerText}>{translations.footer_info_1}</Text>
          <Text style={styles.footerText}>{translations.footer_info_2}</Text>
          <Text style={styles.footerText}>{translations.footer_info_3}</Text>
        </View>
        <View style={styles.footerCol}>
          <Text style={styles.footerText}>{translations.footer_info_4}</Text>
          <Text style={styles.footerText}>{translations.footer_info_5}</Text>
        </View>
        <View style={styles.footerCol}>
          <Text style={styles.footerText}>{translations.footer_info_6}</Text>
          <Text style={styles.footerText}>{translations.footer_info_7}</Text>
        </View>
        <View style={{ ...styles.footerCol, flex: 1.5 }}>
          <View style={styles.logo}>
            <Image src={logo} />
          </View>
        </View>
      </View>
    </Page>
  );
};

FinalInvoiceAndHardwarePot.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.objectOf(PropTypes.shape)]),
};

FinalInvoiceAndHardwarePot.defaultProps = {
  data: null,
};

export default FinalInvoiceAndHardwarePot;
