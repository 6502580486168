import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import { personalDataServiceAgreementPdfStyles } from '../../../../helpers/pdfCommonStyles';
import { getTranslations } from '../translations';

const styles = StyleSheet.create({
  ...personalDataServiceAgreementPdfStyles,
  container: { ...personalDataServiceAgreementPdfStyles.container, width: '100%' },
  col_1: { ...personalDataServiceAgreementPdfStyles.col_1, width: 30 },
  sub_col_1: { ...personalDataServiceAgreementPdfStyles.sub_col_1, width: 320 },
});

// eslint-disable-next-line arrow-body-style
const SecondPage = () => {
  const { translations, t } = getTranslations('personalDataServiceAgreement_second_page');

  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.content}>
          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.textBold} />
            </View>

            <View style={styles.col_2}>
              <View style={styles.sub_row}>
                <View style={styles.sub_col_1}>
                  <Text style={styles.textBold}>{translations.row_title_5}</Text>
                </View>
                <View style={styles.sub_col_2}>
                  <Text style={styles.text}>{translations.row_text_5}</Text>
                </View>
              </View>

              <View style={styles.sub_row}>
                <View style={styles.sub_col_1}>
                  <Text style={styles.textBold}>{translations.row_title_6}</Text>
                </View>
                <View style={styles.sub_col_2}>
                  <Text style={styles.text}>{translations.row_text_6}</Text>
                </View>
              </View>

              <View style={styles.sub_row}>
                <View style={styles.sub_col_1}>
                  <Text style={styles.textBold}>{translations.row_title_7}</Text>
                </View>
                <View style={styles.sub_col_2}>
                  <Text style={styles.text}>{translations.row_text_7}</Text>
                </View>
              </View>

              <View style={styles.sub_row}>
                <View style={styles.sub_col_1}>
                  <Text style={styles.textBold}>{translations.row_title_8}</Text>
                </View>
                <View style={styles.sub_col_2}>
                  <Text style={styles.text}>{translations.row_text_8}</Text>
                </View>
              </View>

              <View style={styles.sub_row}>
                <View style={styles.sub_col_1}>
                  <Text style={styles.textBold}>{translations.row_title_9}</Text>
                </View>
                <View style={styles.sub_col_2}>
                  <Text style={styles.text}>{translations.row_text_9}</Text>
                </View>
              </View>

              <View style={styles.sub_row}>
                <View style={styles.sub_col_1}>
                  <Text style={styles.textBold}>{translations.row_title_10}</Text>
                </View>
                <View style={styles.sub_col_2}>
                  <Text style={styles.text}>{translations.row_text_10}</Text>
                </View>
              </View>

              <View style={styles.sub_row}>
                <View style={styles.sub_col_1}>
                  <Text style={styles.textBold}>{translations.row_title_11}</Text>
                </View>
                <View style={styles.sub_col_2}>
                  <Text style={styles.text}>{translations.row_text_11}</Text>
                </View>
              </View>

              <View style={styles.sub_row}>
                <View style={styles.sub_col_1}>
                  <Text style={styles.textBold}>{translations.row_title_12}</Text>
                </View>
                <View style={styles.sub_col_2}>
                  <Text style={styles.text}>{translations.row_text_12}</Text>
                </View>
              </View>

              <View style={styles.sub_row}>
                <View style={styles.sub_col_1}>
                  <Text style={styles.textBold}>{translations.row_title_13}</Text>
                </View>
                <View style={styles.sub_col_2}>
                  <Text style={styles.text}>{translations.row_text_13}</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};

SecondPage.propTypes = {};

SecondPage.defaultProps = {};

export default SecondPage;
