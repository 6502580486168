import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const ErrorMessage = ({ text }) => (
  <div className={styles.error}>
    <span>{text}</span>
  </div>
);

ErrorMessage.propTypes = {
  text: PropTypes.string,
};

ErrorMessage.defaultProps = {
  text: '',
};

export default ErrorMessage;
