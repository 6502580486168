import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import { choiseStep } from '../../../helpers/linkButtonsValues';
import { startedAgreements, allStartedAgreements, myAgreement } from '../../../router/routes';

const AgreementItem = ({ name, data, id, step, company, isDuplicate }) => {
  const setQuoteId = () => {
    localStorage.setItem('quoteId', id);
    localStorage.setItem('companyId', company);
    localStorage.setItem('step', step);
  };
  const { pathname } = useLocation();

  return (
    <div className={styles.container}>
      {pathname === startedAgreements || pathname === allStartedAgreements ? (
        <Link to={choiseStep(step)} onClick={setQuoteId} className={styles.link}>
          {name} {isDuplicate && '(duplicera)'}
        </Link>
      ) : (
        <Link to={myAgreement} onClick={setQuoteId} className={styles.link}>
          {name}
        </Link>
      )}
      <span>{data}</span>
    </div>
  );
};

AgreementItem.propTypes = {
  name: PropTypes.string,
  data: PropTypes.string,
  id: PropTypes.string,
  step: PropTypes.number,
  company: PropTypes.string,
  isDuplicate: PropTypes.bool,
};

AgreementItem.defaultProps = {
  name: '',
  data: '',
  id: '',
  step: 0,
  company: '',
  isDuplicate: false,
};

export default AgreementItem;
