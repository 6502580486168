import React from 'react';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import logo from '../../../../assets/images/Logo.png';
import { roamingPdfStyles } from '../../../../helpers/pdfCommonStyles';
import { getTranslations } from '../translations';

const styles = StyleSheet.create({
  ...roamingPdfStyles,
  title: {
    fontSize: 31,
    fontFamily: 'Como-ExtraBold',
    marginBottom: 20,
  },

  tableHeader: {
    ...roamingPdfStyles.tableHeader,
    fontSize: 11,
  },
  container: {
    ...roamingPdfStyles.container,
    width: '100%',
  },
});
const mobileTableCreator = (roamingzon, skickaMMS, taEmotMMS, GPRSData, volume) => ({
  roamingzon,
  skickaMMS,
  taEmotMMS,
  GPRSData,
  volume,
});

const mobileTableValues = [
  mobileTableCreator('1 - EU/EES', 'pris per MB', 'pris per MB', 'Ingår*', '-'),
  mobileTableCreator('1a - EU/EES', 'pris per MB', 'pris per MB', 'Ingår*', '-'),
  mobileTableCreator('2a', 'pris per MB', 'pris per MB', '4', '169 kr / 1 GB'),
  mobileTableCreator('2b', 'pris per MB', 'pris per MB', '4', '169 kr / 1 GB'),

  mobileTableCreator('3a, 3d', 'pris per MB', 'pris per MB', '4', '169 kr / 1 GB'),
  mobileTableCreator('3b, 3c', 'pris per MB', 'pris per MB', '4', '169 kr / 1 GB'),

  mobileTableCreator('5a, 5b', 'pris per MB', 'pris per MB', '4', '269 kr / 300 MB'),

  mobileTableCreator('6a', 'pris per MB', 'pris per MB', '80', '269 kr / 10 MB'),

  mobileTableCreator('7a, 7b, 7c, 7d', 'pris per MB', 'pris per MB', '4', '169 kr / 500 MB'),

  mobileTableCreator('8', 'pris per MB', 'pris per MB', '8', '269 kr / 100 MB'),

  mobileTableCreator('9', 'pris per MB', 'pris per MB', '80', 'N/A'),
];

const mobileTableValuesNO = [
  mobileTableCreator('1 - EU/EES', 'pris per MB', 'pris per MB', 'Ingår*', '-'),
  mobileTableCreator('Schweiz (sone 2A)', 'pris per MB', 'pris per MB', '4', '169 nok/1GB'),
  mobileTableCreator('Storbritannien, Gibraltar (sone 2B)', 'pris per MB', 'pris per MB', '4', '169 nok/1GB'),
  mobileTableCreator('USA, Puerto Rico (sone 3A)', 'pris per MB', 'pris per MB', '4', '169 nok/1GB'),

  mobileTableCreator('Turkiet (sone 3B)', 'pris per MB', 'pris per MB', '4', '169 nok/1GB'),
  mobileTableCreator('Kanada (sone)', 'pris per MB', 'pris per MB', '4', '169 nok/1GB'),
  mobileTableCreator('Thailand (sone)', 'pris per MB', 'pris per MB', '4', '169 nok/1GB'),

  mobileTableCreator('Sone 5 A-B', 'pris per MB', 'pris per MB', '4', '269 nok/300MB'),

  mobileTableCreator('Sone 6', 'pris per MB', 'pris per MB', '80', '369 nok/10MB'),

  mobileTableCreator('Sone 7 A-D', 'pris per MB', 'pris per MB', '4', '169 nok/500MB'),

  mobileTableCreator('Sone 8', 'pris per MB', 'pris per MB', '8', '269 nok/100MB'),

  mobileTableCreator('Sone 9', 'pris per MB', 'pris per MB', '80', 'N/A'),
];

const SecondPage = () => {
  const { translations, language } = getTranslations( 'roaming_second_page');

  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.header}>
          <View style={styles.logo}>
            <Image src={logo} />
          </View>
        </View>

        <View style={styles.containerWrapper}>
          <View style={styles.tableContainer}>
            <Text style={styles.tableTitle}>{translations.title}</Text>
            <View>
              {language === 'no' ? (
                <View style={styles.tableHeader}>
                  <Text style={{ ...styles.cell, width: 160 }}>{translations.table_head_1}</Text>
                  <Text style={{ ...styles.cell, width: 75 }}>{translations.table_head_2}</Text>
                  <Text style={{ ...styles.cell, width: 75 }}>{translations.table_head_3}</Text>
                  <View style={{ ...styles.cell, width: 90 }}>
                    <Text>{translations.table_head_4}</Text>
                    <Text style={styles.cellSub}>{translations.value_1}</Text>
                  </View>
                  <View style={{ ...styles.cell, width: 110 }}>
                    <Text>{translations.table_head_5}</Text>
                    <Text style={styles.cellSub}>{translations.value_2}</Text>
                  </View>
                </View>
              ) : (
                <View style={styles.tableHeader}>
                  <Text style={{ ...styles.cell, width: 100 }}>{translations.table_head_1}</Text>
                  <Text style={{ ...styles.cell, width: 100 }}>{translations.table_head_2}</Text>
                  <Text style={{ ...styles.cell, width: 100 }}>{translations.table_head_3}</Text>
                  <View style={{ ...styles.cell, width: 100 }}>
                    <Text>{translations.table_head_4}</Text>
                    <Text style={styles.cellSub}>{translations.value_1}</Text>
                  </View>
                  <View style={{ ...styles.cell, width: 100 }}>
                    <Text>{translations.table_head_5}</Text>
                    <Text style={styles.cellSub}>{translations.value_2}</Text>
                  </View>
                </View>
              )}

              {language === 'no'
                ? mobileTableValuesNO.map((item) => (
                    <View style={styles.row} key={item.roamingzon}>
                      <Text style={{ ...styles.cell, width: 160, paddingLeft: 18, textAlign: 'left' }}>
                        {item.roamingzon}
                      </Text>
                      <Text style={{ ...styles.cell, width: 75, paddingLeft: 20, textAlign: 'left' }}>
                        {item.skickaMMS}
                      </Text>
                      <Text style={{ ...styles.cell, width: 75, paddingLeft: 20, textAlign: 'left' }}>
                        {item.taEmotMMS}
                      </Text>
                      <Text style={{ ...styles.cell, width: 100 }}>{item.GPRSData}</Text>
                      <Text style={{ ...styles.cell, width: 100 }}>{item.volume}</Text>
                    </View>
                  ))
                : mobileTableValues.map((item) => (
                    <View style={styles.row} key={item.roamingzon}>
                      <Text style={{ ...styles.cell, width: 100, paddingLeft: 18, textAlign: 'left' }}>
                        {item.roamingzon}
                      </Text>
                      <Text style={{ ...styles.cell, width: 100, paddingLeft: 20, textAlign: 'left' }}>
                        {item.skickaMMS}
                      </Text>
                      <Text style={{ ...styles.cell, width: 100, paddingLeft: 20, textAlign: 'left' }}>
                        {item.taEmotMMS}
                      </Text>
                      <Text style={{ ...styles.cell, width: 100 }}>{item.GPRSData}</Text>
                      <Text style={{ ...styles.cell, width: 100 }}>{item.volume}</Text>
                    </View>
                  ))}
            </View>
            <Text style={{ ...styles.tableInfo }}>{translations.table_info}</Text>
          </View>
        </View>
      </View>
      <View style={styles.footer}>
        <Text>{translations.link}</Text>
      </View>
    </Page>
  );
};

export default SecondPage;
