import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import { personalDataServiceAgreementPdfStyles } from '../../../../helpers/pdfCommonStyles';
import { getTranslations } from '../translations';

const styles = StyleSheet.create({
  ...personalDataServiceAgreementPdfStyles,

  text: {
    ...personalDataServiceAgreementPdfStyles.text,
    lineHeight: 1.2,
  },

  textBold: {
    ...personalDataServiceAgreementPdfStyles.textBold,
    lineHeight: 1.2,
  },

  table: {
    borderWidth: 1,
    borderColor: '#000',
    marginTop: 10,
    marginBottom: 60,
  },

  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
  },
  tableCol_1: {
    width: '35%',
    padding: '5px 10px',
    borderRightWidth: 1,
    borderRightColor: '#000',
  },
  tableCol_2: {
    width: '25%',
    padding: '5px 10px',
    borderRightWidth: 1,
    borderRightColor: '#000',
  },
  tableCol_3: {
    width: '50%',
    padding: '5px 10px',
  },

  form: {
    marginTop: 100,
    display: 'flex',
    flexDirection: 'row',
  },
  block: {
    flex: 1,
    marginRight: 50,
  },
  item: {
    marginBottom: 30,
  },
  line: {
    width: '100%',
    height: 3,
    backgroundColor: '#000',
    marginTop: 40,
  },
});

// eslint-disable-next-line arrow-body-style
const NinePage = () => {
  const { translations, t } = getTranslations('personalDataServiceAgreement_nine_page');

  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.content}>
          <Text style={{ ...styles.title, marginBottom: 20 }}>{translations.title} </Text>

          <Text style={styles.text}>{translations.block_text_1}</Text>

          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol_1}>
                <Text style={styles.textBold}>{translations.table_head_1}</Text>
              </View>
              <View style={styles.tableCol_2}>
                <Text style={styles.textBold}>{translations.table_head_2}</Text>
              </View>
              <View style={styles.tableCol_3}>
                <Text style={styles.textBold}>{translations.table_head_3}</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCol_1}>
                <Text style={styles.text}>{translations.table_text_1}</Text>
              </View>
              <View style={styles.tableCol_2}>
                <Text style={styles.text}>{translations.table_text_2} </Text>
              </View>
              <View style={styles.tableCol_3}>
                <Text style={styles.text}>{translations.table_text_3}</Text>
              </View>
            </View>

            <View style={{ ...styles.tableRow, borderBottom: 'none' }}>
              <View style={styles.tableCol_1}>
                <Text style={styles.text}> </Text>
              </View>
              <View style={styles.tableCol_2}>
                <Text style={styles.text}> </Text>
              </View>
              <View style={styles.tableCol_3}>
                <Text style={styles.text}> </Text>
              </View>
            </View>
          </View>

          <View style={styles.form}>
            <View style={styles.block}>
              <View style={styles.item}>
                <Text style={styles.textBold}>{translations.label_1}</Text>
                <View style={styles.line} />
              </View>
              <View style={styles.item}>
                <Text style={styles.textBold}>{translations.label_2}</Text>
                <View style={styles.line} />
              </View>
              <View style={styles.item}>
                <Text style={styles.textBold}>{translations.label_3}</Text>
                <View style={styles.line} />
              </View>
            </View>
            <View style={styles.block}>
              <View style={styles.item}>
                <Text style={styles.textBold}>{translations.label_1}</Text>
                <View style={styles.line} />
              </View>
              <View style={styles.item}>
                <Text style={styles.textBold}>{translations.label_2}</Text>
                <View style={styles.line} />
              </View>
              <View style={styles.item}>
                <Text style={styles.textBold}>{translations.label_3}</Text>
                <View style={styles.line} />
              </View>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};

NinePage.propTypes = {};

NinePage.defaultProps = {};

export default NinePage;
