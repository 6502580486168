import {
  downloadAgreements,
  newAgreementStep1,
  newAgreementStep2,
  newAgreementStep3,
  startedAgreements,
} from '../router/routes';

import { translate } from '../localization/LanguageContext';

const valuesCreator = (id, text, color, path) => ({
  id,
  text,
  color,
  path,
});

// eslint-disable-next-line import/prefer-default-export
export const mainPageLinks = [
  valuesCreator(1, translate('my_agreement'), 'yellow', downloadAgreements),
  valuesCreator(2, translate('current_agreement'), 'purple', startedAgreements),
  valuesCreator(3, translate('create_new_agreement'), 'blue', newAgreementStep1),
];

export const choiseStep = (step) => {
  const values = {
    1: newAgreementStep1,
    2: newAgreementStep2,
    3: newAgreementStep3,
  };

  return values[step];
};
