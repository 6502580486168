/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import { personalDataServiceAgreementPdfStyles } from '../../../../helpers/pdfCommonStyles';
import { getTranslations } from '../translations';

const styles = StyleSheet.create({
  ...personalDataServiceAgreementPdfStyles,
  row: { ...personalDataServiceAgreementPdfStyles.row, marginBottom: 7 },
  sub_row: { ...personalDataServiceAgreementPdfStyles.sub_row, marginTop: 7 },
});

// eslint-disable-next-line arrow-body-style
const FirstPage = ({ data }) => {
  const { translations, t } = getTranslations('personalDataServiceAgreement_first_page');

  const WrapAndReturnText = (text, symbol, style) => {
    // Regular expression to find the wrapped text
    const regex = new RegExp(`${symbol}(.*?)${symbol}`, 'g');
    // Split the text into an array of parts: text before, wrapped text, and text after
    const parts = text.split(regex);
    return parts.map((part, index) => {
      if (index % 2 === 1) {
        // This is the part wrapped with the symbol, so apply the style
        return (
          <Text key={index} style={style}>
            {part}
          </Text>
        );
      }
      return <Text key={index}>{part}</Text>;
    });
  };

  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.content}>
          <Text style={{ ...styles.title, marginBottom: 10 }}>{translations.title}</Text>

          <Text style={styles.text}>{WrapAndReturnText(translations.block_text_1, '@@', styles.textBold)}</Text>

          <View style={styles.row} />
          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>(1)</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>
                {WrapAndReturnText(
                  translations.block_item_1
                    .replace('{{company_name}}', data?.company?.name)
                    .replace('{{organization_number}}', data?.company?.organization_number)
                    .replace('{{postal_address}}', data?.company?.postal_address),
                  '@@',
                  styles.textBold,
                )}
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>(2)</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>{WrapAndReturnText(translations.block_item_2, '@@', styles.textBold)}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.textBold}>1.</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.textBold}>{translations.block_item_3}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>1.1</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>{WrapAndReturnText(translations.block_item_4, '@@', styles.textBold)}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>1.2</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>{translations.block_item_5}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>1.3</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>{translations.block_item_6}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.textBold}>2.</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.textBold}>{translations.sub_title}</Text>

              <View style={styles.sub_row}>
                <View style={styles.sub_col_1}>
                  <Text style={styles.textBold}>{translations.row_title_1}</Text>
                </View>
                <View style={styles.sub_col_2}>
                  <Text style={styles.text}>{translations.row_text_1}</Text>
                </View>
              </View>

              <View style={styles.sub_row}>
                <View style={styles.sub_col_1}>
                  <Text style={styles.textBold}>{translations.row_title_2}</Text>
                </View>
                <View style={styles.sub_col_2}>
                  <Text style={styles.text}>{translations.row_text_2}</Text>
                </View>
              </View>

              <View style={styles.sub_row}>
                <View style={styles.sub_col_1}>
                  <Text style={styles.textBold}>{translations.row_title_3}</Text>
                </View>
                <View style={styles.sub_col_2}>
                  <Text style={styles.text}>{translations.row_text_3}</Text>
                </View>
              </View>

              <View style={styles.sub_row}>
                <View style={styles.sub_col_1}>
                  <Text style={styles.textBold}>{translations.row_title_4}</Text>
                </View>
                <View style={styles.sub_col_2}>
                  <Text style={styles.text}>{translations.row_text_4}</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};

FirstPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.objectOf(PropTypes.shape)]),
};

FirstPage.defaultProps = {
  data: null,
};

export default FirstPage;
