/* eslint-disable react/prop-types */
import React, { createContext, useState, useContext } from 'react';
import sv from '../translations/sv.json';
import no from '../translations/no.json';

const LanguageContext = createContext();

let currentLanguage = process.env.REACT_APP_LANGUAGE || 'sv'; // Default language
// let currentLanguage = 'no'; // Default language

const translations = {
  sv,
  no,
};

const getNestedTranslation = (obj, key) =>
  key.split('.').reduce((result, keyPart) => (result ? result[keyPart] : undefined), obj);

export const translate = (key) => getNestedTranslation(translations[currentLanguage], key) || key;

export const getCurrentLanguage = () => currentLanguage;

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(currentLanguage);

  const changeLanguage = (lng) => {
    currentLanguage = lng;
    setLanguage(lng);
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <LanguageContext.Provider value={{ language, changeLanguage, translate }}>{children}</LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
