import React from 'react';
import PropTypes from 'prop-types';
import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { getTranslations } from '../translations';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'ArialRegular',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: '#000',
  },
  container: {
    width: '90%',
    margin: '0 auto',
    height: 830,
  },

  main_title: {
    fontSize: 18,
    fontFamily: 'ArialRegular',
    color: '#000',
    marginTop: 40,
  },
  input: {
    border: 1,
    width: 200,
  },
  label: {
    fontSize: 6,
    marginLeft: 3,
    marginTop: 1,
  },

  text_number_wrapper: {
    marginTop: 30,
  },
  text_number: {
    fontFamily: 'ArialBold',
    fontSize: 9,
  },
  row: {
    flexDirection: 'row',
    gap: 10,
  },
  sub_title: {
    fontFamily: 'ArialRegular',
    fontSize: 12,
    color: '#727378',
    marginTop: 5,
  },

  title: {
    fontFamily: 'ArialBold',
    fontSize: 8,
    color: '#000',
    marginBottom: 2,
  },
  text: {
    fontFamily: 'ArialRegular',
    fontSize: 7,
  },
  left: {
    marginLeft: '15%',
  },
  block: {
    position: 'relative',
    backgroundColor: '#ECECED',
    paddingHorizontal: 15,
    paddingVertical: 5,
    marginBottom: 5,
  },
  number: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: -15,
    width: 15,
    height: 15,
    backgroundColor: '#000',
  },
  number_txt: {
    fontFamily: 'ArialRegular',
    fontSize: 10,
    color: '#fff',
  },
  section: {
    width: '50%',
  },
  text_area: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: '#fff',
    paddingHorizontal: 2,
  },
  column: {
    width: '100%',
  },
  checkbox: {
    width: 10,
    height: 10,
    borderWidth: 0.5,
    borderColor: '#000',
  },
  footer: {
    fontSize: 6,
    color: 'grey',
    width: 250,
    position: 'absolute',
    bottom: 0,
  },
});

const FourPageNo = ({ data }) => {
  const { translations } = getTranslations('grenke_four_page_no');

  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <Text style={styles.main_title}>{translations.main_title}</Text>

        <View style={{ ...styles.row, marginBottom: 10, alignItems: 'baseline' }}>
          <Text style={styles.sub_title}>{translations.sub_title}</Text>
          <View style={styles.left}>
            <Text style={styles.text}>
              {translations.inquiry_number}:{' '}
              <Text style={{ ...styles.text, fontFamily: 'ArialBold', color: '#000' }}>
                {data?.inquiry_number ?? ''}
              </Text>
            </Text>
          </View>
        </View>

        <View style={styles.block}>
          <View style={styles.number}>
            <Text style={styles.number_txt}>1</Text>
          </View>
          <View style={styles.row}>
            <View style={styles.section}>
              <Text style={styles.title}>{translations.block_head_1_1}</Text>
              <Text style={styles.text}>{translations.block_text_1_1}</Text>

              <View style={{ ...styles.text_area, height: 50, marginTop: 5 }} />

              <View style={{ ...styles.row, marginVertical: 3, gap: 0 }}>
                <View
                  style={{
                    ...styles.text_area,
                    width: '50%',
                    height: 20,
                    flexDirection: 'row',
                    gap: 3,
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <View style={styles.checkbox} />
                  <Text style={styles.text}>NY – GJELDER F.O.M.</Text>
                </View>
                <View style={{ ...styles.text_area, width: '50%', height: 20 }}>
                  <Text style={styles.text}>T.O.M. DATO</Text>
                </View>
              </View>

              <View
                style={{
                  ...styles.text_area,
                  height: 20,
                  flexDirection: 'row',
                  gap: 3,
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <View style={styles.checkbox} />
                <Text style={styles.text}>ENDRINGEN GJELDER F.O.M. DATO</Text>
              </View>
            </View>

            <View style={styles.section}>
              <Text style={styles.title}>{translations.block_head_1_2}</Text>
              <Text style={styles.text}>{translations.block_text_1_2}</Text>

              <View style={{ ...styles.text_area, height: 50, marginTop: 5, paddingLeft: 5 }}>
                <Text style={{ ...styles.text, fontSize: 9 }}>
                  GRENKE BANK AG{'\n'} Branch Norway{'\n'} Martin Linges vei 25{'\n'} 1364 Fornebu
                </Text>
              </View>
              <Text style={{ ...styles.text, marginTop: 10, marginLeft: 10 }}>{translations.block_info_1}</Text>
            </View>
          </View>
        </View>

        <View style={styles.block}>
          <View style={styles.number}>
            <Text style={styles.number_txt}>2</Text>
          </View>
          <View>
            <Text style={styles.title}>{translations.block_head_2_1}</Text>

            <View style={{ ...styles.row, gap: 3 }}>
              <View style={{ ...styles.text_area, width: '10%', height: 20, marginTop: 5, textAlign: 'center' }} />
              <View style={{ ...styles.text_area, width: '10%', height: 20, marginTop: 5, textAlign: 'center' }}>
                <Text style={{ ...styles.text, fontFamily: 'ArialBold' }}>{translations.block_2_label_1}</Text>
              </View>

              <View style={{ ...styles.text_area, width: '35%', height: 20, marginTop: 5, textAlign: 'center' }}>
                <Text style={{ ...styles.text, fontFamily: 'ArialBold' }}>{translations.block_2_label_2}</Text>
              </View>
              <View style={{ ...styles.text_area, width: '35%', height: 20, marginTop: 5, textAlign: 'center' }}>
                <Text style={{ ...styles.text, fontFamily: 'ArialBold' }}>{translations.block_2_label_3}</Text>
              </View>
              <View style={{ ...styles.text_area, width: '35%', height: 20, marginTop: 5, textAlign: 'center' }}>
                <Text style={{ ...styles.text, fontFamily: 'ArialBold' }}>{translations.block_2_label_4}</Text>
              </View>

              <View style={{ ...styles.text_area, width: '10%', height: 20, marginTop: 5, textAlign: 'center' }}>
                <Text style={{ ...styles.text, fontFamily: 'ArialBold' }}>{translations.block_2_label_5}</Text>
              </View>
              <View style={{ ...styles.text_area, width: '15%', height: 20, marginTop: 5, textAlign: 'center' }}>
                <Text style={{ ...styles.text, fontFamily: 'ArialBold' }}>{translations.block_2_label_6}</Text>
              </View>
            </View>

            <View style={{ ...styles.row, gap: 3 }}>
              <View style={{ ...styles.text_area, width: '10%', height: 20, marginTop: 5, textAlign: 'center' }}>
                <Text style={{ ...styles.text, fontFamily: 'ArialBold' }}>H</Text>
              </View>
              <View style={{ ...styles.text_area, width: '10%', height: 20, marginTop: 5, textAlign: 'center' }}>
                <Text style={{ ...styles.text, fontFamily: 'ArialBold' }}>1</Text>
              </View>

              <View style={{ ...styles.text_area, width: '35%', height: 20, marginTop: 5, textAlign: 'center' }} />
              <View style={{ ...styles.text_area, width: '35%', height: 20, marginTop: 5, textAlign: 'center' }} />
              <View style={{ ...styles.text_area, width: '35%', height: 20, marginTop: 5, textAlign: 'center' }}>
                <Text style={{ ...styles.text, fontFamily: 'ArialBold' }}>750.06.54841</Text>
              </View>

              <View style={{ ...styles.text_area, width: '10%', height: 20, marginTop: 5, textAlign: 'center' }}>
                <Text style={{ ...styles.text, fontFamily: 'ArialBold' }}>4</Text>
              </View>
              <View style={{ ...styles.text_area, width: '15%', height: 20, marginTop: 5, textAlign: 'center' }} />
            </View>

            <View style={{ ...styles.row, marginTop: 20, gap: 25 }}>
              <View>
                <Text style={{ ...styles.text, fontFamily: 'ArialBold', marginBottom: 5 }}>
                  {translations.block_2_description_head_1}
                </Text>
                <Text style={{ ...styles.text }}>{translations.block_2_description_text_1_1}</Text>
                <Text style={{ ...styles.text }}>{translations.block_2_description_text_1_2}</Text>
                <Text style={{ ...styles.text }}>{translations.block_2_description_text_1_3}</Text>
              </View>

              <View>
                <Text style={{ ...styles.text, fontFamily: 'ArialBold', marginBottom: 5 }}>
                  {translations.block_2_description_head_2}
                </Text>
                <View style={{ ...styles.row, gap: 15 }}>
                  <View>
                    <Text style={{ ...styles.text }}>{translations.block_2_description_text_2_1}</Text>
                    <Text style={{ ...styles.text }}>{translations.block_2_description_text_2_2}</Text>
                    <Text style={{ ...styles.text }}>{translations.block_2_description_text_2_3}</Text>
                  </View>
                  <View>
                    <Text style={{ ...styles.text }}>{translations.block_2_description_text_2_4}</Text>
                    <Text style={{ ...styles.text }}>{translations.block_2_description_text_2_5}</Text>
                    <Text style={{ ...styles.text }}>{translations.block_2_description_text_2_6}</Text>
                  </View>
                </View>
              </View>

              <View>
                <Text style={{ ...styles.text, fontFamily: 'ArialBold', marginBottom: 5 }}>
                  {translations.block_2_description_head_3}
                </Text>
                <Text style={{ ...styles.text }}>{translations.block_2_description_text_3_1}</Text>
                <Text style={{ ...styles.text }}>{translations.block_2_description_text_3_2}</Text>
                <Text style={{ ...styles.text }}>{translations.block_2_description_text_3_3}</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.block}>
          <View style={styles.number}>
            <Text style={styles.number_txt}>3</Text>
          </View>

          <Text style={styles.title}>{translations.block_head_3_1}</Text>

          <View style={{ marginTop: 10, width: '55%', gap: 10 }}>
            <View style={styles.row}>
              <Text style={styles.text}>1.</Text>
              <View>
                <Text style={styles.text}>{translations.block_3_item_title_1}</Text>
                <Text style={styles.text}>{translations.block_3_item_text_1}</Text>
              </View>
            </View>

            <View style={styles.row}>
              <Text style={styles.text}>2.</Text>
              <View>
                <Text style={styles.text}>{translations.block_3_item_title_2}</Text>
                <Text style={styles.text}>{translations.block_3_item_text_2}</Text>
              </View>
            </View>

            <View style={styles.row}>
              <Text style={styles.text}>3.</Text>
              <View>
                <Text style={styles.text}>{translations.block_3_item_title_3}</Text>
                <Text style={styles.text}>{translations.block_3_item_text_3}</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.block}>
          <View style={{ ...styles.row, gap: 4 }}>
            <View style={{ width: '50%' }}>
              <View style={{ ...styles.text_area, height: 23, justifyContent: 'flex-end', marginBottom: 4 }}>
                <Text style={{ ...styles.text }}>{translations.location}</Text>
              </View>
              <View style={{ ...styles.text_area, height: 23, justifyContent: 'flex-end' }}>
                <Text style={{ ...styles.text, fontFamily: 'ArialBold' }}>{translations.date}</Text>
              </View>
            </View>

            <View style={{ width: '50%' }}>
              <View style={{ ...styles.text_area, height: 50, justifyContent: 'flex-end' }}>
                <Text style={{ ...styles.accent_text, fontFamily: 'ArialRegular', fontSize: 15, marginBottom: 'auto' }}>
                  X
                </Text>

                <Text style={{ ...styles.text, fontFamily: 'ArialBold' }}>{translations.signature}</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.footer}>
          <Text>
            <Text style={{ color: '#000' }}>{translations.bank}</Text> {'\n'}
            {translations.footer}
          </Text>
        </View>
      </View>
    </Page>
  );
};

FourPageNo.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.objectOf(PropTypes.shape)]),
};

FourPageNo.defaultProps = {
  data: null,
};

export default FourPageNo;
