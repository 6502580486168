import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { PublicRoute, PrivateRoute } from '../components/simple';
import {
  loginPage,
  forgotPasswordPage,
  mainPage,
  downloadAgreements,
  startedAgreements,
  newAgreementStep3,
  newAgreementStep1,
  newAgreementStep2,
  summaryPage,
  myAgreement,
  allDownloadAgreements,
  allStartedAgreements,
} from './routes';
import withSuspence from '../helpers/withSuspense';
import { LoginPage } from '../pages';

const routeCreator = (route, component, privateRoute) => ({
  route,
  component,
  privateRoute,
});

const mainPageSuspence = React.lazy(() => import('../pages/MainPage'));

const forgotPasswordPageSuspence = React.lazy(() => import('../pages/ForgotPasswordPage'));

const agreementsPageSuspence = React.lazy(() => import('../pages/AgreementsPage'));

const adminAgreementsPageSuspence = React.lazy(() => import('../pages/AdminAgreementsPage'));

const myAgreementPageSuspence = React.lazy(() => import('../pages/MyAgreementPage'));

const newAgreementFirstPageSuspence = React.lazy(() => import('../pages/NewAgreementFirstPage'));

const newAgreementSecondPageSuspence = React.lazy(() => import('../pages/NewAgreementSecondPage'));

const newAgreementThirdPageSuspence = React.lazy(() => import('../pages/NewAgreementThirdPage'));

const summaryPageSuspence = React.lazy(() => import('../pages/SummaryPage'));

const routerValues = [
  routeCreator(loginPage, <LoginPage />, <PublicRoute />),
  routeCreator(forgotPasswordPage, withSuspence(forgotPasswordPageSuspence), <PublicRoute />),
  routeCreator(mainPage, withSuspence(mainPageSuspence), <PrivateRoute />),
  routeCreator(downloadAgreements, withSuspence(agreementsPageSuspence)),
  routeCreator(startedAgreements, withSuspence(agreementsPageSuspence), <PrivateRoute />),
  routeCreator(allDownloadAgreements, withSuspence(adminAgreementsPageSuspence), <PrivateRoute />),
  routeCreator(allStartedAgreements, withSuspence(adminAgreementsPageSuspence), <PrivateRoute />),
  routeCreator(myAgreement, withSuspence(myAgreementPageSuspence), <PrivateRoute />),
  routeCreator(newAgreementStep1, withSuspence(newAgreementFirstPageSuspence), <PrivateRoute />),
  routeCreator(newAgreementStep2, withSuspence(newAgreementSecondPageSuspence), <PrivateRoute />),
  routeCreator(newAgreementStep3, withSuspence(newAgreementThirdPageSuspence), <PrivateRoute />),
  routeCreator(summaryPage, withSuspence(summaryPageSuspence), <PrivateRoute />),
];

const Router = () => (
  <Routes>
    {routerValues.map((route) => (
      <Route path={route.route} element={route.privateRoute} key={route.route}>
        <Route path={route.route} element={route.component} />
      </Route>
    ))}
  </Routes>
);

export default Router;
