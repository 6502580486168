import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import { slaPdfStyles } from '../../../../helpers/pdfCommonStyles';

import logo from '../../../../assets/images/Logo.png';
import img from '../../../../assets/images/sla_img_1.png';
import { getTranslations } from '../translations';

const styles = StyleSheet.create({
  ...slaPdfStyles,
});

// eslint-disable-next-line arrow-body-style
const FirstPage = () => {
  const { translations, t } = getTranslations( 'SLA_first_page');

  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.logo}>
          <Image src={logo} />
        </View>

        <View style={styles.content}>
          <Text style={{ ...styles.title, marginBottom: 10, color: '#3b63a0' }}>{translations.title}</Text>

          <Text style={styles.subTitle}>{translations.sub_title}</Text>
          <Text style={styles.text}>{translations.block_text_1}</Text>

          <Text style={styles.text}>{translations.block_text_2}</Text>

          <Text style={styles.text}>{translations.block_text_3}</Text>
          <Image src={img} />
        </View>
      </View>
      <View style={styles.footer}>
        <Text style={styles.footerName}>{translations.link}</Text>
      </View>
    </Page>
  );
};

FirstPage.propTypes = {};

FirstPage.defaultProps = {};

export default FirstPage;
