export const loginPage = '/login';
export const forgotPasswordPage = '/forgot-password';

export const mainPage = '/';
export const downloadAgreements = '/download-agreements';
export const startedAgreements = '/started-agreements';
export const newAgreementStep1 = '/new-agreement/step-1';
export const newAgreementStep2 = '/new-agreement/step-2';
export const newAgreementStep3 = '/new-agreement/step-3';
export const summaryPage = '/summary-page';
export const myAgreement = '/my-agreement';
export const allDownloadAgreements = '/all-download-agreement';
export const allStartedAgreements = '/all-started-agreement';
