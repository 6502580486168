import React from 'react';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import logo from '../../../assets/images/Logo.png';
import { summaryPdfStyles } from '../../../helpers/pdfCommonStyles';
import { getTranslations } from './translations';

const styles = StyleSheet.create({
  ...summaryPdfStyles,
  containerWrapper: {
    width: '70%',
    margin: '0 auto',
  },
  title: {
    fontSize: 31,
    fontFamily: 'Como-ExtraBold',
    margin: '50px 0',
  },
  table: {
    marginBottom: 40,
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '97%',
    borderBottom: 1,
    paddingBottom: 2,
    marginBottom: 5,
  },
  tableTitle: {
    padding: '0 20px',
    fontFamily: 'Como-Bold',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontFamily: 'Como',
    fontSize: 14,
    margin: '5px 20px',
  },
  tableInfo: {
    fontSize: 12,
  },

  footer: {
    textAlign: 'center',
    // paddingBottom: 20,
    fontSize: 14,
    width: '100%',
    position: 'absolute',
    bottom: 40,
  },
  footerName: {
    fontSize: 12,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
});

const rowCreator = (id, name, value) => ({
  id,
  name,
  value,
});

const Trafic = () => {
  const { translations } = getTranslations('trafic');

  const firstTable = [
    rowCreator(1, translations.item_1, '0,59 kr'),
    rowCreator(2, translations.item_2, '0,59 kr'),
    rowCreator(3, translations.item_3, '0,59 kr'),
    rowCreator(4, translations.item_4, '0,59 kr'),
    rowCreator(5, translations.item_5, '1,99 kr'),
  ];

  const secondTable = [
    rowCreator(1, translations.item_6, '0,59 kr'),
    rowCreator(2, translations.item_7, '1,59 kr'),
    rowCreator(3, translations.item_8, '1,59 kr'),
  ];

  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.logo}>
          <Image src={logo} />
        </View>
        <View style={styles.containerWrapper}>
          <Text style={styles.title}>{translations.title}</Text>

          <View style={styles.table}>
            <View style={styles.tableHeader}>
              <Text style={styles.tableTitle}>{translations.sub_title_1}</Text>
              <Text style={styles.tableTitle}>{translations.price}</Text>
            </View>
            <>
              {firstTable.map((item) => (
                <View style={styles.row} key={item.id}>
                  <Text>{item.name}</Text>
                  <Text>{item.value}</Text>
                </View>
              ))}
            </>
          </View>

          <View style={styles.table}>
            <View style={styles.tableHeader}>
              <Text style={styles.tableTitle}>{translations.sub_title_2}</Text>
              <Text style={styles.tableTitle}>{translations.price}</Text>
            </View>
            <>
              {secondTable.map((item) => (
                <View style={styles.row} key={item.id}>
                  <Text>{item.name}</Text>
                  <Text>{item.value}</Text>
                </View>
              ))}
            </>
          </View>

          <Text style={styles.tableInfo}>{translations.text}</Text>
        </View>
      </View>

      <View style={styles.footer}>
        <Text style={styles.footerName}>{translations.link}</Text>
      </View>
    </Page>
  );
};

export default Trafic;
