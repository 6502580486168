import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import { slaPdfStyles } from '../../../../helpers/pdfCommonStyles';

import logo from '../../../../assets/images/Logo.png';
import { getTranslations } from '../translations';

const styles = StyleSheet.create({
  ...slaPdfStyles,

  formula: {
    position: 'relative',
    padding: '0 40px',
    paddingBottom: 20,
  },
});

// eslint-disable-next-line arrow-body-style
const ThirdPage = () => {
  const { translations, t } = getTranslations( 'SLA_third_page');

  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.logo}>
          <Image src={logo} />
        </View>

        <View style={styles.content}>
          <Text style={styles.subTitle}>{translations.sub_title_1}</Text>
          <Text style={styles.text}>{translations.block_text_1} </Text>

          <View style={styles.formula}>
            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Text style={{ ...styles.text, fontFamily: 'Como-Bold' }}>{translations.formula_1}</Text>
              <Text style={{ ...styles.text }}> =</Text>
              <Text style={{ ...styles.text, borderBottom: 0.3, borderBottomColor: '#10225c' }}>
                {translations.formula_2}
              </Text>
            </View>
            <Text style={{ ...styles.textBlock, position: 'absolute', right: 200, bottom: 8 }}>
              {translations.formula_3}
            </Text>
          </View>

          {/* ADD formul */}

          <Text style={styles.subTitle}>{translations.sub_title_2}</Text>
          <Text style={styles.text}>{translations.block_text_2}</Text>

          <Text style={{ ...styles.subTitle, marginBottom: 5 }}>{translations.sub_title_3}</Text>
          <Text style={styles.listItem}>{translations.item_1}</Text>
          <Text style={styles.listItem}>{translations.item_2}</Text>
          <Text style={styles.listItem}>{translations.item_3}</Text>
          <Text style={styles.listItem}>{translations.item_4}</Text>

          <Text style={styles.text}>{translations.block_text_3}</Text>

          <Text style={styles.subTitle}>{translations.sub_title_4}</Text>
          <Text style={styles.text}>{translations.block_text_4}</Text>

          <Text style={styles.subTitle}>{translations.sub_title_5}</Text>
          <Text style={styles.text}>{translations.block_text_5}</Text>
        </View>
      </View>
      <View style={styles.footer}>
        <Text style={styles.footerName}>{translations.link}</Text>
      </View>
    </Page>
  );
};

ThirdPage.propTypes = {};

ThirdPage.defaultProps = {};

export default ThirdPage;
