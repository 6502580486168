import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { MenuLink } from '../../../simple';
import styles from './styles.module.scss';

const MenuLinks = ({ links }) => {
  const { pathname } = useLocation();

  return (
    <div className={styles.sideLinks}>
      {links.map((link) => (
        <MenuLink
          path={link.path}
          name={link.name}
          key={link.name}
          className={`${styles.link} ${pathname === link.path && styles.active}`}
        />
      ))}
    </div>
  );
};

MenuLinks.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape),
};

MenuLinks.defaultProps = {
  links: null,
};

export default MenuLinks;
