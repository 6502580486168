import {
  downloadAgreements,
  startedAgreements,
  newAgreementStep1,
  allDownloadAgreements,
  allStartedAgreements,
} from '../router/routes';

import { translate } from '../localization/LanguageContext';

const itemCreator = (name, path) => ({
  name,
  path,
});

export const sidebarItems = [
  itemCreator(translate('my_agreement'), downloadAgreements),
  itemCreator(translate('current_agreement'), startedAgreements),
  itemCreator(translate('create_new_agreement'), newAgreementStep1),
];

export const adminItems = [
  itemCreator(translate('ping_agreement'), allDownloadAgreements),
  itemCreator(translate('ping_ongoing_agreement'), allStartedAgreements),
  itemCreator(translate('create_new_agreement'), newAgreementStep1),
];

export const inputName = (path) =>
  path === downloadAgreements ? translate('search') : `${translate('current_agreement')}...`;
