import React from 'react';
import PropTypes from 'prop-types';
import FirstPage from './FirstPage';
import SecondPage from './SecondPage';
import ThirdPage from './ThirdPage';
import FourPage from './FourPage';
import FivePage from './FivePage';
import SixPage from './SixPage';

const Security = ({ data }) => (
  <>
    <FirstPage />
    <SecondPage />
    <ThirdPage />
    <FourPage />
    <FivePage />
    <SixPage />
  </>
);

Security.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.objectOf(PropTypes.shape)]),
};

Security.defaultProps = {
  data: null,
};

export default Security;
