import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import { securityPdfStyles } from '../../../../helpers/pdfCommonStyles';

import logo from '../../../../assets/images/Logo.png';
import Footer from './footer';

const styles = StyleSheet.create({
  ...securityPdfStyles,
  content: {
    ...securityPdfStyles.content,
    marginTop: 100,
  },
});

// eslint-disable-next-line arrow-body-style
const SecondPage = () => {
  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.logo}>
          <Image src={logo} />
        </View>

        <View style={styles.content}>
          <Text style={styles.subTitle}>Data centers and interconnects</Text>
          <Text style={styles.text}>
            Pingaway services are provided from multiple, geographically distributed tier 3+ datacentres compliant to
            ISO 27001. These data centres are interconnected thanks to adedicated and fully redundant fibre backbone. We
            are fully GDPR compliant and maintain rigorous control of where we store and process our customers’ data.
          </Text>

          <Text style={styles.subTitle}>Site access and process</Text>
          <Text style={styles.text}>
            Only Pingaway employees have physical access to equipment in these facilities. Access to facilities is
            granted to a selected few Pingaway employees that have received proper training. Access list and access logs
            are audited regularly.
          </Text>

          <Text style={styles.subTitle}>Peering and internet access</Text>
          <Text style={styles.text}>
            Pingaway maintains and continuously extends our Internet peering- and transit-network with new points of
            presence and capacity to ensure reliable and high-speedconnectivity to our customers and partners. We
            encrypt all web and voice traffic over the public Internet. In both instances, this is based on modern
            industry standards such as TLS 1.2+.
          </Text>
        </View>
      </View>
      <Footer />
    </Page>
  );
};

SecondPage.propTypes = {};

SecondPage.defaultProps = {};

export default SecondPage;
