/* eslint-disable no-unused-vars */
import React from 'react';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import logo from '../../../../assets/images/Logo.png';
import { roamingPdfStyles } from '../../../../helpers/pdfCommonStyles';
import { getTranslations } from '../translations';

const styles = StyleSheet.create({
  ...roamingPdfStyles,
  container: {
    ...roamingPdfStyles.container,
    height: 800,
  },
});

const FourPage = () => {
  const { translations } = getTranslations( 'roaming_four_page');

  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.logo}>
          <Image src={logo} />
        </View>

        <View style={styles.titleContainer}>
          <Text style={styles.title}>{translations.title}</Text>
        </View>

        <View style={styles.content}>
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>{translations.section_head_1}</Text>
            <Text style={styles.sectionText}>{translations.section_text_1}</Text>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>{translations.section_head_2}</Text>
            <Text style={styles.sectionText}>{translations.section_text_2}</Text>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>{translations.section_head_3}</Text>
            <Text style={styles.sectionText}>{translations.section_text_3}</Text>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>{translations.section_head_4}</Text>
            <Text style={styles.sectionText}>{translations.section_text_4}</Text>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>{translations.section_head_5}</Text>
            <Text style={styles.sectionText}>{translations.section_text_5}</Text>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>{translations.section_head_6}</Text>
            <Text style={styles.sectionText}>{translations.section_text_6}</Text>
          </View>

          <View style={{ ...styles.section }}>
            <Text style={styles.sectionTitle}>{translations.section_head_7}</Text>
            <Text style={styles.sectionText}>{translations.section_text_7_1}</Text>
          </View>

          <View style={{ ...styles.section, marginLeft: 40 }}>
            <Text style={styles.sectionText}>{translations.section_text_7_2}</Text>
          </View>

          <View style={{ ...styles.section, marginLeft: 40 }}>
            <Text style={styles.sectionTitle}>{translations.section_head_8}</Text>
            <Text style={styles.sectionText}>{translations.section_text_8}</Text>
          </View>

          <View style={{ ...styles.section, marginLeft: 40 }}>
            <Text style={styles.sectionTitle}>{translations.section_head_9}</Text>
            <Text style={styles.sectionText}>{translations.section_text_9}</Text>
          </View>

          <View style={{ ...styles.section, marginLeft: 40 }}>
            <Text style={styles.sectionTitle}>{translations.section_head_10}</Text>
            <Text style={styles.sectionText}>{translations.section_text_10}</Text>
          </View>

          <View style={{ ...styles.section, marginLeft: 40 }}>
            <Text style={styles.sectionTitle}>{translations.section_head_11}</Text>
            <Text style={styles.sectionText}>{translations.section_text_11}</Text>
          </View>

          <View style={{ ...styles.section, marginLeft: 40 }}>
            <Text style={styles.sectionTitle}>{translations.section_head_12}</Text>
            <Text style={styles.sectionText}>{translations.section_text_12}</Text>
          </View>
        </View>
      </View>
      <View style={styles.footer}>
        <Text>{translations.link}</Text>
      </View>
    </Page>
  );
};

export default FourPage;
