import React from 'react';
import styles from './styles.module.scss';
import ForgotPasswordForm from './ForgotPasswordForm';
import { Logo } from '../../components/simple';

const ForgotPasswordPage = () => (
  <>
    <Logo />
    <div className={styles.forgotPasswordContainer}>
      <ForgotPasswordForm />
    </div>
  </>
);

export default ForgotPasswordPage;
