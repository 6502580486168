import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import buttonColor from '../../../helpers/buttonColors';

const StyledButton = ({ color, text, type, className, disabled, onClick, style }) => (
  <Button
    type={type}
    style={{
      borderRadius: 35,
      backgroundColor: buttonColor(color),
      padding: '10px 24px',
      width: '180px',
      fontSize: '12px',
      fontFamily: 'ComoBold',
      textTransform: 'none',
      color: '#fff',
      marginRight: '30px',
      boxShadow: '5px 1px 10px gray',
      ...style,
    }}
    className={className}
    disabled={disabled}
    onClick={onClick}
  >
    {text}
  </Button>
);

StyledButton.propTypes = {
  color: PropTypes?.string,
  text: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.shape({}),
};

StyledButton.defaultProps = {
  color: '',
  text: '',
  type: 'button',
  className: '',
  disabled: false,
  onClick: () => {},
  style: undefined,
};

export default StyledButton;
