import React from 'react';
import FirstPage from './FirstPage';
import SecondPage from './SecondPage';
import ThirdPage from './ThirdPage';
import FourPage from './FourPage';
import FivePage from './FivePage';

const Roaming = () => (
  <>
    <FirstPage />
    <SecondPage />
    <ThirdPage />
    <FourPage />
    <FivePage />
  </>
);

export default Roaming;
