import { useLanguage } from './LanguageContext';

const useMultiLang = () => {
  const { translate, changeLanguage, language } = useLanguage();

  return {
    t: translate,
    changeLanguage,
    getCurrentLanguage: () => language,
  };
};

export default useMultiLang;
