import React from 'react';
import { PDFViewer, Font, Document } from '@react-pdf/renderer';

Font.register({
  family: 'Como',
  src: '/Como.ttf',
});
Font.register({
  family: 'Como-Bold',
  src: '/Como-Bold.ttf',
});
Font.register({
  family: 'Como-ExtraBold',
  src: '/Como-ExtraBold.ttf',
});
Font.register({
  family: 'Como-Light',
  src: '/Como-Light.ttf',
});
Font.register({
  family: 'Como-Medium',
  src: '/Como-Medium.ttf',
});
Font.register({
  family: 'Como-SemiBold',
  src: '/Como-SemiBold.ttf',
});

const pdfViewer = (Component, data) => (
  <PDFViewer showToolbar={false} width={800} height={1127} style={{ marginBottom: 50 }}>
    <Document>
      <Component data={data} />
    </Document>
  </PDFViewer>
);

export default pdfViewer;
