import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import { personalDataServiceAgreementPdfStyles } from '../../../../helpers/pdfCommonStyles';
import { getTranslations } from '../translations';

const styles = StyleSheet.create({
  ...personalDataServiceAgreementPdfStyles,
});

// eslint-disable-next-line arrow-body-style
const SixPage = () => {
  const { translations, t } = getTranslations('personalDataServiceAgreement_six_page');

  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.content}>
          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>11.2</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>{translations.block_text_1}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>11.3</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>{translations.block_text_2}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>11.4</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>{translations.block_text_3}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.textBold}>12.</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.textBold}>{translations.sub_title_1}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>12.1</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>{translations.block_text_4}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>12.2</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>{translations.block_text_5}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.textBold}>13.</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.textBold}>{translations.sub_title_2} </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>13.1</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>{translations.block_text_6}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>13.2</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>{translations.block_text_7}</Text>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};

SixPage.propTypes = {};

SixPage.defaultProps = {};

export default SixPage;
