import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import { securityPdfStyles } from '../../../../helpers/pdfCommonStyles';

import logo from '../../../../assets/images/Logo.png';
import Footer from './footer';

const styles = StyleSheet.create({
  ...securityPdfStyles,
  content: {
    ...securityPdfStyles.content,
  },

  articleTitle: {
    ...securityPdfStyles.subTitle,
    fontSize: 12,
  },

  grid: {
    display: 'flex',
    gap: 10,
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  article: {
    width: '45%',
    display: 'flex',
  },
});

// eslint-disable-next-line arrow-body-style
const FivePage = () => {
  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.logo}>
          <Image src={logo} />
        </View>

        <View style={styles.content}>
          <Text style={styles.subTitle}>Software development organisation</Text>
          <Text style={styles.text}>
            How we develop and release our software is key to our growth and our possbility to keep a high space in our
            product development. With 90+ developers and an agileapproach, we apply a couple of fundamental concepts to
            our development process to secure both quality and speed.
          </Text>

          <View style={styles.grid}>
            <View style={styles.row}>
              <View style={styles.article}>
                <Text style={styles.articleTitle}>Architectural reviews</Text>
                <Text style={styles.text}>
                  All Pingaway source code is stored in a centralsource code repository. At least three developers
                  review any source code changes. The code review process is guided by checklists and best practices
                  that include security checks, including OWASP Top 10. All code is tested before all deployments
                  toprevent regressions.
                </Text>
              </View>

              <View style={styles.article}>
                <Text style={styles.articleTitle}>Software testing and reviews</Text>
                <Text style={styles.text}>
                  For any major change, semi-formalarchitecture reviews are performed. Flexible deployment and rollouts
                  are planned from here. When bringing on any new software dependency, system or vendor, a governance
                  process needs to be implemented that covers its entirelifecycle. This includes an initial
                  riskassessment to secure the lifecycle.
                </Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.article}>
                <Text style={styles.articleTitle}>Continuous integration (CI){'\n'} and deployment (CD)</Text>
                <Text style={styles.text}>
                  Shipping small increments daily reduces the risk of bugs and limits the need for big projects. All
                  bigger overhauls are delivered in a truly agile and interactive way. CI and CDensure tight feed- back
                  loops into the product, enabling us to adapt mid-project.
                </Text>
              </View>
              <View style={styles.article}>
                <Text style={styles.articleTitle}>Agile deployment</Text>
                <Text style={styles.text}>
                  We upgrade our platform multiple times everyday. Key: rolling upgrades, flexible loadbalancing,
                  feature flags and canarydeployments. This gives us the best possibleability to troubleshoot and roll
                  back anyabnormalities – fast.
                </Text>
              </View>
            </View>
          </View>

          <Text style={styles.text}>
            The above process ensures that system changes are logged and an audit trail is kept. We regularly employ
            external auditors to perform penetration tests to evaluate and circumvent our protections as part of our
            continuous information security work.
          </Text>
        </View>
      </View>
      <Footer />
    </Page>
  );
};

FivePage.propTypes = {};

FivePage.defaultProps = {};

export default FivePage;
