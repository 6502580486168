import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { authorization } from '../../helpers/authentication';
import { get } from '../../services/API';
import { AgreementsList, MyAgreementsWrapper } from '../../components/complex';
import { allDownloadAgreements } from '../../router/routes';
import { adminItems } from '../../helpers/sidebarItems';

const AgreementsPage = () => {
  const [agreements, setAgreements] = useState([]);
  const [count, setCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const { pathname } = useLocation();
  const [search, setSearchValue] = useState('');

  const isDownload = (path) => {
    if (path === allDownloadAgreements) {
      return true;
    }
    return false;
  };

  const handleCount = (event, value) => setCount((value - 1) * 15);

  const onChangeSearch = (s) => {
    setSearchValue(s);
    setCount(0);
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await get('/user/current-user', authorization());

      localStorage.setItem('role', data.role);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await get(
        `/quotes/quotes?is_draft=${!isDownload(pathname)}&limit=15&offset=${count}&company_name=${search}`,
        authorization(),
      );

      setAgreements(data.results);
      setTotalPages(Math.ceil(data.count / 15));
    };

    fetchData();
  }, [count, pathname, search]);

  return (
    <MyAgreementsWrapper links={adminItems}>
      <AgreementsList
        agreements={agreements}
        handleCount={handleCount}
        totalPages={totalPages}
        search={search}
        onChangeSearch={onChangeSearch}
      />
    </MyAgreementsWrapper>
  );
};

export default AgreementsPage;
