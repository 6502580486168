import React from 'react';
import PropTypes from 'prop-types';
import { Logo } from '../../simple';
import styles from './styles.module.scss';
import MenuLinks from './MenuLinks';

const MyAgreementsWrapper = ({ children, links }) => (
  <div className={styles.mainWrapper}>
    <Logo />
    <div className={styles.container}>
      <MenuLinks links={links} />
      {children}
    </div>
  </div>
);

MyAgreementsWrapper.propTypes = {
  children: PropTypes.element,
  links: PropTypes.arrayOf(PropTypes.shape),
};

MyAgreementsWrapper.defaultProps = {
  children: null,
  links: null,
};

export default MyAgreementsWrapper;
