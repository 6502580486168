import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { myAgreement } from '../../../router/routes';
import styles from './styles.module.scss';

const Steps = ({ className, steps, agreement }) => {
  const navigate = useNavigate();
  const quote = localStorage.getItem('quoteId');

  useEffect(() => {
    if (agreement?.is_downloaded || agreement?.esign_documents?.length) {
      navigate(myAgreement);
    }
  }, [agreement]);

  return (
    <div className={styles.container}>
      {steps.map((step) => (
        <div key={step.step} className={`${styles.step} ${className}`}>
          <button type="button" onClick={() => navigate(step.path)}>
            {step.step}
          </button>
        </div>
      ))}
    </div>
  );
};

Steps.propTypes = {
  className: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.shape),
  // eslint-disable-next-line react/forbid-prop-types
  agreement: PropTypes.any,
};

Steps.defaultProps = {
  className: '',
  steps: null,
  agreement: null,
};

export default Steps;
