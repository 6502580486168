import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import { securityPdfStyles } from '../../../../helpers/pdfCommonStyles';

import logo from '../../../../assets/images/Logo.png';
import Footer from './footer';

const styles = StyleSheet.create({
  ...securityPdfStyles,
  content: {
    ...securityPdfStyles.content,
  },
});

// eslint-disable-next-line arrow-body-style
const FourPage = () => {
  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.logo}>
          <Image src={logo} />
        </View>

        <View style={styles.content}>
          <Text style={styles.subTitle}>Hardened OS configuration</Text>
          <Text style={styles.text}>
            The majority of services that Pingaway operates are deployed into a commonoperating system. This OS and its
            base configuration are minimised and security-hardened according to commonly accepted security practices
            (based on CIS) and Pingaway policies. This OS (like any other vendor component) has a controlled process to
            ensure that any vendor security vulnerability announcements are reviewed and appropriatemitigations are
            implemented.
          </Text>

          <Text style={styles.subTitle}>Centralised logging</Text>
          <Text style={styles.text}>
            Logging from all systems, services and equipment is, in real-time, sent to centrallogging facilities to
            protect against tampering and unauthorised access, allowarchiving and facilitate analysis and review of
            security events.
          </Text>

          <Text style={styles.subTitle}>Access to production (VPN + SSH)</Text>
          <Text style={styles.text}>
            Remote administrative access to systems is only available through VPN and SSH by approved personnel.
          </Text>

          <Text style={styles.subTitle}>Data storage</Text>
          <Text style={styles.text}>
            All data is continuously backed up and shipped off-site to allow for point-in-time and disaster recovery.
          </Text>

          <Text style={styles.subTitle}>Continuous support</Text>
          <Text style={styles.text}>
            We collect and analyse performance metrics to ensure that we keep delivering high-quality services with
            excellent service levels. All while adding new value-creatingfeatures and tweaking existing ones for our
            customers and new subscribers. These principles allow us to improve our services faster, in smaller
            increments, and without using maintenance windows where the service is even partly unavailable.
          </Text>
        </View>
      </View>
      <Footer />
    </Page>
  );
};

FourPage.propTypes = {};

FourPage.defaultProps = {};

export default FourPage;
