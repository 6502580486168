import React from 'react';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import logo from '../../../../assets/images/Logo.png';
import { roamingPdfStyles } from '../../../../helpers/pdfCommonStyles';
import { getTranslations } from '../translations';

const styles = StyleSheet.create({
  ...roamingPdfStyles,
  title: {
    fontSize: 31,
    fontFamily: 'Como-ExtraBold',
    marginBottom: 20,
  },
  wrapper: {
    width: 400,
    paddingLeft: 40,
  },
  tableContainer: {
    ...roamingPdfStyles.tableContainer,
  },

  tableHeader: {
    ...roamingPdfStyles.tableHeader,
    fontSize: 11,
    paddingBottom: 15,
  },

  container: {
    ...roamingPdfStyles.container,
    width: '100%',
  },

  cell: {
    ...roamingPdfStyles.cell,
    textAlign: 'left',
  },
});

const mobileTableCreator = (country, feed, code) => ({
  country,
  feed,
  code,
});

const mobileTableValues = [
  mobileTableCreator('USA', '5 GB/ 199 kr'),
  mobileTableCreator('Storbritannien/Gibraltar', '5 GB/ 199 kr'),
  mobileTableCreator('Schweiz', '5 GB/ 199 kr'),
  mobileTableCreator('Turkiet', '5 GB/ 199 kr'),
  mobileTableCreator('Thailand', '5 GB/ 199 kr'),
  mobileTableCreator('Kanada', '3 GB/ 199 kr'),
  mobileTableCreator('Kina', '3 GB/ 199 kr'),
  mobileTableCreator('Hong Kong', '3 GB/ 199 kr'),
  mobileTableCreator('Singapore', '3 GB/ 199 kr'),
  mobileTableCreator('Israel', '3 GB/ 199 kr'),
  mobileTableCreator('Sydkorea', '3 GB/ 199 kr'),
  mobileTableCreator('Malaysia', '3 GB/ 199 kr'),
  mobileTableCreator('Taiwan', '3 GB/ 199 kr'),
  mobileTableCreator('Indonesien', '3 GB/ 199 kr'),
];

const mobileTableValuesNO = [
  mobileTableCreator('USA, Puerto Rico, Am. Jungfruöarna', '5 GB / 199 nok', '5GBUS'),
  mobileTableCreator('Storbritannien/Gibraltar', '5 GB / 199 nok', '5GBUK'),
  mobileTableCreator('Schweiz', '5 GB / 199 nok', '5GBCH'),
  mobileTableCreator('Turkiet', '5 GB / 199 nok', '5GBTR'),
  mobileTableCreator('Thailand', '5 GB / 199 nok', '5GBTH'),
  mobileTableCreator('Andorra', '3 GB / 199 nok', '3GB7B'),
  mobileTableCreator('Australien', '3 GB / 199 nok', '3GB7A'),
  mobileTableCreator('Bosnien och Hercegovina', '3 GB / 199 nok', '3GB7B'),
  mobileTableCreator('Filippinerna', '3 GB / 199 nok', '3GB7A'),
  mobileTableCreator('Faröarna', '3 GB / 199 nok', '3GB7B'),
  mobileTableCreator('Grönland', '3 GB / 199 nok', '3GB7B'),
  mobileTableCreator('Guernsey', '3 GB / 199 nok', '3GB7B'),
  mobileTableCreator('Hong Kong', '3 GB / 199 nok', '3GB7B'),
  mobileTableCreator('Indonesien', '3 GB / 199 nok', '3GB7A'),
  mobileTableCreator('Isle of Man', '3 GB / 199 nok', '3GB7B'),
  mobileTableCreator('Israel', '3 GB / 199 nok', '3GB7A'),
  mobileTableCreator('Jersey', '3 GB / 199 nok', '3GB7B'),
  mobileTableCreator('Kanada', '3 GB / 199 nok', '3GBCA'),
  mobileTableCreator('Kina', '3 GB / 199 nok', '3GB7B'),
  mobileTableCreator('Macau', '3 GB / 199 nok', '3GB7B'),
  mobileTableCreator('Malaysia', '3 GB / 199 nok', '3GB7A'),
  mobileTableCreator('Moldavien', '3 GB / 199 nok', '3GB7B'),
  mobileTableCreator('Palestina', '3 GB / 199 nok', '3GB7B'),
  mobileTableCreator('Serbien', '3 GB / 199 nok', '3GB7B'),
  mobileTableCreator('Singapore', '3 GB / 199 nok', '3GB7A'),
  mobileTableCreator('Sydkorea', '3 GB / 199 nok', '3GB7A'),
  mobileTableCreator('Taiwan', '3 GB / 199 nok', '3GB7A'),
];

const ThirdPage = () => {
  const { translations, language } = getTranslations( 'roaming_third_page');

  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.header}>
          <View style={styles.logo}>
            <Image src={logo} />
          </View>
        </View>

        <View style={styles.containerWrapper}>
          <View style={styles.tableContainer}>
            <View style={styles.wrapper}>
              <Text style={{ ...styles.tableTitle, paddingLeft: 20 }}>{translations.title}</Text>

              {language === 'no' ? (
                <View>
                  <View style={styles.tableHeader}>
                    <Text style={{ ...styles.cell, width: 300, paddingLeft: 20 }}>{translations.table_head_1}</Text>
                    <Text style={{ ...styles.cell, width: 200, textAlign: 'center' }}>{translations.table_head_2}</Text>
                    <Text style={{ ...styles.cell, width: 100, textAlign: 'center' }}>{translations.table_head_3}</Text>
                  </View>
                  <>
                    {mobileTableValuesNO.map((item) => (
                      <View style={styles.row} key={item.roamingzon}>
                        <Text style={{ ...styles.cell, width: 300, paddingLeft: 20 }}>{item.country}</Text>
                        <Text style={{ ...styles.cell, width: 200, textAlign: 'center' }}>{item.feed}</Text>
                        <Text style={{ ...styles.cell, width: 100, textAlign: 'center' }}>{item.code}</Text>
                      </View>
                    ))}
                  </>
                </View>
              ) : (
                <View>
                  <View style={styles.tableHeader}>
                    <Text style={{ ...styles.cell, width: 200, paddingLeft: 20 }}>{translations.table_head_1}</Text>
                    <Text style={{ ...styles.cell, width: 200, textAlign: 'center' }}>{translations.table_head_2}</Text>
                  </View>
                  <>
                    {mobileTableValues.map((item) => (
                      <View style={styles.row} key={item.roamingzon}>
                        <Text style={{ ...styles.cell, width: 200, paddingLeft: 20 }}>{item.country}</Text>
                        <Text style={{ ...styles.cell, width: 200, textAlign: 'center' }}>{item.feed}</Text>
                      </View>
                    ))}
                  </>
                </View>
              )}
              <Text style={{ ...styles.tableInfo, paddingLeft: 20 }}>{translations.table_info}</Text>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.footer}>
        <Text>{translations.link}</Text>
      </View>
    </Page>
  );
};

export default ThirdPage;
