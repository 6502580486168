import * as Yup from 'yup';
import dayjs from 'dayjs';
import { getCurrentLanguage, translate } from '../localization/LanguageContext';

const VIEWER = 'viewer';
const SIGNING = 'signing_party';

export const firstStepInitialValues = {
  name: '',
  organization_number: '',
  postal_address: '',
  zip_code: '',
  state: '',
  // country: '',
  phone_number: getCurrentLanguage() === 'sv' ? '+46' : '+47',
  date: dayjs().format('DD-MM-YYYY'),
  expire: '',
};

export const firstStepValidationSchema = Yup.object().shape({
  name: Yup.string().required(translate('required')),
  organization_number: Yup.string().required(translate('required')),
  postal_address: Yup.string().required(translate('required')),
  zip_code: Yup.string().max(5, translate('max_length_5')).required(translate('required')),
  state: Yup.string().required(translate('required')),
  // country: Yup.string().required(translate('required')),
  phone_number: Yup.string().required(translate('required')),
  expire: Yup.string().required(translate('required')),
});

export const secondStepValidationSchema = {
  signers: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required(translate('required')),
      email: Yup.string().email(translate('enter_valid_email')).required(translate('required')),
      phone_number: Yup.string().required(translate('required')),
      social_security_number: Yup.string().when('type', {
        is: (type) => type === VIEWER || getCurrentLanguage() === 'no',
        then: Yup.string().notRequired(),
        otherwise: Yup.string().required(translate('required')),
      }),
    }),
  ),
  // real_principals: Yup.array().of(
  //   Yup.object().shape({
  //     name: Yup.string().required(translate('required')),
  //     email: Yup.string().email(translate('enter_valid_email')).required(translate('required')),
  //     phone_number: Yup.string().required(translate('required')),
  //     social_security_number: Yup.string().required(translate('required')),
  //     percentage: Yup.number().notRequired(),
  //   }),
  // ),
  telephone_manager: Yup.object().shape({
    name: Yup.string().required(translate('required')),
    email: Yup.string().email(translate('enter_valid_email')).required(translate('required')),
    phone_number: Yup.string().required(translate('required')),
  }),
  manager: Yup.object().shape({
    username: Yup.string().notRequired(),
    email: Yup.string().notRequired(),
    phone_number: Yup.string().notRequired(),
    partner_name: Yup.string().nullable().required(translate('required')),
    partner_org_num: Yup.string().nullable().required(translate('required')),
  }),
};
