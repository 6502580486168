import React from 'react';
import PropTypes from 'prop-types';
import { Logo } from '../../simple';
import styles from './styles.module.scss';

const StepPage = ({ children }) => (
  <div className={styles.container}>
    <Logo />
    <div className={styles.content}>{children}</div>
  </div>
);

StepPage.propTypes = {
  children: PropTypes.element,
};

StepPage.defaultProps = {
  children: null,
};

export default StepPage;
