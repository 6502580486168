import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Checkbox } from '@mui/material';
import styles from './styles.module.scss';

const StyledCheckbox = ({ name, checked, onChange, label }) => (
  <FormControlLabel
    control={<Checkbox name={name} checked={checked} onChange={onChange} />}
    label={label}
    className={styles.checkbox}
  />
);

StyledCheckbox.propTypes = {
  name: PropTypes.string,
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  label: PropTypes.string,
};

StyledCheckbox.defaultProps = {
  name: '',
  checked: false,
  onChange: () => {},
  label: '',
};

export default StyledCheckbox;
