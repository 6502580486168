import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import StyledButton from '../StyledButton';

const LinkButton = ({ text, color, path, className, linkClassName, onClick }) => (
  <Link to={path} className={linkClassName}>
    <StyledButton color={color} text={text} className={className} onClick={onClick} />
  </Link>
);

LinkButton.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  path: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  linkClassName: PropTypes.string,
};

LinkButton.defaultProps = {
  text: '',
  color: '',
  path: '',
  className: '',
  linkClassName: '',
  onClick: () => {},
};

export default LinkButton;
