import React from 'react';
import PropTypes from 'prop-types';
import { Page, Text, Image, View, StyleSheet } from '@react-pdf/renderer';
import logo from '../../../../assets/images/grenke-logo.png';
import { getTranslations } from '../translations';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'ArialRegular',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: '#000',
  },
  container: {
    width: '90%',
    margin: '0 auto',
  },
  logo: {
    height: 70,
    width: 120,
    marginLeft: 'auto',
    marginTop: 25,
    color: 'grey',
    fontSize: 16,
    textAlign: 'center',
  },
  title: {
    fontSize: 20,
    fontFamily: 'ArialBold',
    height: 80,
  },
  text: {
    fontSize: 10,
    width: 400,
  },
  inputs: {
    display: 'flex',
    flexDirection: 'row',
  },
  name: {
    borderBottom: 1,
    width: 250,
    padding: '5px 0',
    marginRight: 20,
    fontFamily: 'ArialBold',
    fontSize: 16,
  },
  number: {
    borderBottom: 1,
    width: 150,
    padding: '5px 0',
    marginRight: 20,
    fontFamily: 'ArialBold',
    fontSize: 16,
  },
  tableText: {
    fontFamily: 'Helvetica',
    fontSize: 16,
    padding: '3px 3px',
  },
  caption: {
    fontSize: 10,
  },
  table: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 5,
  },
  cell: {
    border: 1,
    width: 170,
    height: 25,
  },
  footer: {
    paddingBottom: 20,
    width: '90%',
    margin: '0 auto',
  },
});

const ThirdPage = ({ data }) => {
  const { translations } = getTranslations('grenke_third_page');

  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.logo}>
          <Image src={logo} />
        </View>

        <Text style={styles.title}>{translations.title}</Text>

        <Text style={{ fontSize: 12, fontFamily: 'ArialBold' }}>{translations.sub_title_1}</Text>
        <Text style={{ ...styles.text, marginBottom: 20 }}>{translations.block_text_1}</Text>

        <Text style={{ fontSize: 12, fontFamily: 'ArialBold' }}>{translations.customer}</Text>
        <View style={styles.inputs}>
          <View>
            <Text style={styles.name}>{data?.company.name}</Text>
            <Text style={styles.caption}>{translations.company_name}</Text>
          </View>
          <View>
            <Text style={styles.number}>{data?.company.organization_number}</Text>
            <Text style={styles.caption}>{translations.organization_number}</Text>
          </View>
        </View>

        <Text
          style={{
            fontSize: 12,
            fontFamily: 'ArialBold',
            marginTop: 30,
            marginBottom: 5,
          }}
        >
          {translations.sub_title_2}
        </Text>
        <Text style={{ ...styles.text, width: 300, marginBottom: 5 }}>{translations.block_text_2}</Text>

        <View style={styles.table}>
          <View
            style={{
              ...styles.cell,
              fontSize: 10,
              borderRight: 'none',
              height: 17,
            }}
          >
            <Text>{translations.table_head_1}</Text>
          </View>
          <View
            style={{
              ...styles.cell,
              fontSize: 10,
              borderRight: 'none',
              height: 17,
            }}
          >
            <Text>{translations.table_head_2}</Text>
          </View>
          <View style={{ ...styles.cell, fontSize: 10, height: 17, paddingTop: 2 }}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingRight: 3,
              }}
            >
              <Text>{translations.table_head_3}</Text>
              <Text>{translations.table_head_4}</Text>
            </View>
          </View>

          <View
            style={{
              ...styles.cell,
              fontSize: 18,
              borderRight: 'none',
              borderTop: 'none',
            }}
          >
            <Text style={styles.tableText}>
              {data?.company?.real_principals?.[0] && data?.company?.real_principals?.[0].name}
            </Text>
          </View>
          <View
            style={{
              ...styles.cell,
              fontSize: 18,
              borderRight: 'none',
              borderTop: 'none',
            }}
          >
            <Text style={styles.tableText}>
              {data?.company?.real_principals?.[0] && data?.company?.real_principals?.[0].social_security_number}
            </Text>
          </View>
          <View
            style={{
              ...styles.cell,
              fontSize: 18,
              borderTop: 'none',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Text style={styles.tableText}>
              {data?.company?.real_principals?.[0] && data?.company?.real_principals?.[0].percentage}
            </Text>
            <Text
              style={{
                border: 1,
                width: 20,
                height: 10,
                marginTop: 5,
                marginRight: 3,
              }}
            >
              {' '}
            </Text>
          </View>

          <View
            style={{
              ...styles.cell,
              fontSize: 18,
              borderRight: 'none',
              borderTop: 'none',
            }}
          >
            <Text style={styles.tableText}>
              {data?.company?.real_principals?.[1] && data?.company?.real_principals?.[1].name}
            </Text>
          </View>
          <View
            style={{
              ...styles.cell,
              fontSize: 18,
              borderRight: 'none',
              borderTop: 'none',
            }}
          >
            <Text style={styles.tableText}>
              {data?.company?.real_principals?.[1] && data?.company?.real_principals?.[1].social_security_number}
            </Text>
          </View>
          <View
            style={{
              ...styles.cell,
              fontSize: 18,
              borderTop: 'none',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Text style={styles.tableText}>
              {data?.company?.real_principals?.[1] && data?.company?.real_principals?.[1].percentage}
            </Text>
            <Text
              style={{
                border: 1,
                width: 20,
                height: 10,
                marginTop: 5,
                marginRight: 3,
              }}
            >
              {' '}
            </Text>
          </View>

          <View
            style={{
              ...styles.cell,
              fontSize: 18,
              borderRight: 'none',
              borderTop: 'none',
            }}
          >
            <Text style={styles.tableText}>
              {data?.company?.real_principals?.[2] && data?.company?.real_principals?.[2].name}
            </Text>
          </View>
          <View
            style={{
              ...styles.cell,
              fontSize: 18,
              borderRight: 'none',
              borderTop: 'none',
            }}
          >
            <Text style={styles.tableText}>
              {data?.company?.real_principals?.[2] && data?.company?.real_principals?.[2].social_security_number}
            </Text>
          </View>
          <View
            style={{
              ...styles.cell,
              fontSize: 18,
              borderTop: 'none',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Text style={styles.tableText}>
              {data?.company?.real_principals?.[2] && data?.company?.real_principals?.[2].percentage}
            </Text>
            <Text
              style={{
                border: 1,
                width: 20,
                height: 10,
                marginTop: 5,
                marginRight: 3,
              }}
            >
              {' '}
            </Text>
          </View>
        </View>

        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <Text style={{ fontSize: 7, width: 330 }}>{translations.info_1}</Text>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: 160,
              marginLeft: 19,
            }}
          >
            <Text style={{ fontSize: 8 }}>{translations.info_2}</Text>
            <Text
              style={{
                border: 1,
                width: 20,
                height: 10,
                marginTop: 5,
                marginRight: 3,
              }}
            >
              {' '}
            </Text>
          </View>
        </View>
        <Text style={{ borderBottom: 1, padding: '10px 0', marginBottom: 2 }}> </Text>
        <Text style={styles.caption}>{translations.location_date}</Text>

        <Text style={{ borderBottom: 1, padding: '10px 0', marginBottom: 2 }}> </Text>
        <Text style={styles.caption}>{translations.signature}</Text>
      </View>
      <View style={styles.footer}>
        <View
          style={{
            borderBottom: 1,
            fontSize: 7,
            marginBottom: 10,
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Text style={{ width: 250 }}>{translations.info_3}</Text>
          <Text>{translations.page}</Text>
        </View>
        <Text style={{ textAlign: 'center', fontSize: 7 }}>{translations.footer}</Text>
      </View>
    </Page>
  );
};

ThirdPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.objectOf(PropTypes.shape)]),
};

ThirdPage.defaultProps = {
  data: null,
};

export default ThirdPage;
