import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { loginPage, mainPage } from '../../../router/routes';
import logo from '../../../assets/images/Logo.png';
import styles from './styles.module.scss';
import StyledButton from '../StyledButton';
import { get } from '../../../services/API';
import { authorization } from '../../../helpers/authentication';
import useMultiLang from '../../../localization/useMultiLang';

const Logo = ({ className }) => {
  const { t } = useMultiLang();

  const navigate = useNavigate();

  const logout = async () => {
    const data = await get('/auth/logout', authorization());
    if (data.status === 200) {
      localStorage.clear();
      navigate(loginPage);
    }
  };

  return (
    <div className={`${styles.logoWrapper} ${className}`}>
      <div className={styles.container}>
        <Link to={mainPage}>
          <img src={logo} alt="Logo" className={styles.logo} />
        </Link>
        {localStorage.getItem('token') && (
          <StyledButton color="purple" text={t('logout')} type="button" className={styles.logout} onClick={logout} />
        )}
      </div>
    </div>
  );
};

Logo.propTypes = {
  className: PropTypes.string,
};

Logo.defaultProps = {
  className: '',
};

export default Logo;
