/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import { slaPdfStyles } from '../../../../helpers/pdfCommonStyles';

import logo from '../../../../assets/images/Logo.png';
import img_sv from '../../../../assets/images/sla_img_2.png';
import img_no from '../../../../assets/images/sla_img_2_no.png';
import { getTranslations } from '../translations';

const styles = StyleSheet.create({
  ...slaPdfStyles,
});

// eslint-disable-next-line arrow-body-style
const SecondPage = () => {
  const { translations, t, language } = getTranslations( 'SLA_second_page');

  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.logo}>
          <Image src={logo} />
        </View>

        <View style={styles.content}>
          <Text style={styles.text}>{translations.block_text_1}</Text>

          <Text style={styles.subTitle}>{translations.sub_title}</Text>
          <Text style={styles.text}>{translations.block_text_2}</Text>
          {language === 'sv' ? <Image src={img_sv} /> : <Image src={img_no} />}

          <Text style={styles.text}>{translations.block_text_3}</Text>
        </View>
      </View>
      <View style={styles.footer}>
        <Text style={styles.footerName}>{translations.link}</Text>
      </View>
    </Page>
  );
};

SecondPage.propTypes = {};

SecondPage.defaultProps = {};

export default SecondPage;
