/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { FieldArray, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Input, Steps, StyledButton, StyledCheckbox } from '../../../components/simple';
import { formCreator, formListCreator, secondStepValues } from '../../../helpers/inputValues';
import { Delete } from '../../../assets/icons';

import styles from './styles.module.scss';
import { steps } from '../../../helpers/stepsValues';
import { get, post, put } from '../../../services/API';
import useToggle from '../../../helpers/useToogle';
import { authorization } from '../../../helpers/authentication';
import { newAgreementStep3 } from '../../../router/routes';
import { secondStepValidationSchema } from '../../../helpers/formikValues';
import useMultiLang from '../../../localization/useMultiLang';

const SIGNING = 'signing_party';
const APPROVER = 'approver';
const VIEWER = 'viewer';

const NewAgreementSecondStep = () => {
  const { t, getCurrentLanguage } = useMultiLang();
  const [isSending, setIsSending] = useState(false);
  const [isCopyManager, setIsCopyManager] = useState(false);
  const [isCopyPrincipal, setIsCopyPrincipal] = useState(false);
  const [principalCheckBoxes, setPrincipalCheckBoxes] = useState([0]);
  const [agreement, setAgreement] = useState(null);

  const [isManagersCreated, setIsManagersCreated] = useState(false);
  const quote = localStorage.getItem('quoteId');
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape(secondStepValidationSchema);

  const formik = useFormik({
    initialValues: {
      signers: [
        {
          name: '',
          email: '',
          phone_number: getCurrentLanguage() === 'sv' ? '+46' : '+47',
          social_security_number: '',
          company_id: localStorage.getItem('companyId'),
          type: SIGNING,
        },
      ],
      telephone_manager: {
        name: '',
        email: '',
        phone_number: getCurrentLanguage() === 'sv' ? '+46' : '+47',
        company_id: localStorage.getItem('companyId'),
      },
      real_principals: [
        {
          name: '',
          email: '',
          phone_number: getCurrentLanguage() === 'sv' ? '+46' : '+47',
          social_security_number: '',
          percentage: '',
          company_id: localStorage.getItem('companyId'),
        },
      ],
      manager: {
        username: '',
        email: '',
        phone_number: getCurrentLanguage() === 'sv' ? '+46' : '+47',
        partner_name: '',
        partner_org_num: '',
      },
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsSending(true);
      const submitValues = {
        ...values,
        real_principals: values.real_principals,
        quote_id: localStorage.getItem('quoteId'),
      };

      if (getCurrentLanguage() !== 'sv') {
        delete submitValues.real_principals;
      }

      // console.log('submitValues', submitValues);
      const requestData = async () => {
        if (isManagersCreated) {
          return put(`clients/managers/${localStorage.getItem('quoteId')}`, submitValues, authorization());
        }
        return post('/clients/managers', submitValues, authorization());
      };

      const result = await requestData();

      if (result.status === 201 || result.status === 200) {
        await put(
          `/quotes/quotes/${localStorage.getItem('quoteId')}`,
          {
            company_id: localStorage.getItem('companyId'),
            manager_id: localStorage.getItem('userId'),
            step: 3,
          },
          authorization(),
        );
        localStorage.setItem('step', '3');
        navigate(newAgreementStep3);
      }

      setIsSending(false);
    },
  });

  const handleCopyManager = () => {
    setIsCopyManager(!isCopyManager);

    formik.setFieldValue('telephone_manager', {
      name: isCopyManager ? '' : formik.values.signers[0].name,
      email: isCopyManager ? '' : formik.values.signers[0].email,
      phone_number: isCopyManager ? '' : formik.values.signers[0].phone_number,
    });
  };

  const handleCopyPrincipal = () => {
    setIsCopyPrincipal(!isCopyPrincipal);

    const newArray = formik.values.real_principals.map((item, index) => {
      if (index === 0) {
        return {
          ...item,
          name: isCopyPrincipal ? '' : formik.values.signers[0].name || '',
          email: isCopyPrincipal ? '' : formik.values.signers[0].email || '',
          phone_number: isCopyPrincipal ? '' : formik.values.signers[0].phone_number || '',
          social_security_number: isCopyPrincipal ? '' : formik.values.signers[0].social_security_number || '',
        };
      }
      return item;
    });

    formik.setValues({
      ...formik.values,
      real_principals: newArray,
    });
  };

  const getIndexArray = (arr) => {
    if (arr === undefined || arr?.length === 0) {
      return [0];
    }
    return Array.from({ length: arr.length }, (_, index) => index);
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data } = quote && (await get(`quotes/quotes/${quote}`, authorization()));
      if (data.company?.signers?.length) setIsManagersCreated(true);

      setPrincipalCheckBoxes(getIndexArray(data.company.real_principals));
      setAgreement(data);
      // localStorage.setItem('userId', data.id);

      formik.setValues({
        ...formik.values,
        ...(data.company.real_principals?.length ? { real_principals: data.company.real_principals } : {}),
        ...(data.company.signers?.length
          ? {
              signers: data.company.signers.sort((a, b) => {
                // Compare the 'type' values
                if (a.type > b.type) return 1;
                if (a.type < b.type) return -1;
                return 0;
              }),
            }
          : {}),
        ...(data.company.telephone_manager ? { telephone_manager: data.company.telephone_manager } : {}),
        manager: data.manager,
      });
    };

    fetchData();
  }, []);

  const addPrincipalItem = () => {
    formik.setValues({
      ...formik.values,
      real_principals: [
        ...formik.values.real_principals,
        {
          name: '',
          email: '',
          phone_number: getCurrentLanguage() === 'sv' ? '+46' : '+47',
          social_security_number: '',
          percentage: '',
          company_id: localStorage.getItem('companyId'),
        },
      ],
    });
  };

  const removePrincipalItem = () => {
    const newArray = formik.values.real_principals.slice(0, -1);
    formik.setValues({
      ...formik.values,
      real_principals: newArray,
    });
  };

  const principalCheckBoxesHandle = (checked, ind) => {
    const include = principalCheckBoxes.includes(ind);
    if (checked) {
      setPrincipalCheckBoxes((prev) => [...prev, ind + 1]);
      addPrincipalItem();
    } else {
      const newArray = principalCheckBoxes.slice(0, -1);
      setPrincipalCheckBoxes(newArray);
      removePrincipalItem();
    }
  };

  const showCheckBoxes = (array, index) => {
    const lastIndex = array.length - 1;
    const secondLastIndex = array.length - 2;
    if (index === 2) {
      return false;
    }
    return index >= secondLastIndex;
  };

  const addSignersItem = (type) => {
    formik.setValues({
      ...formik.values,
      signers: [
        ...formik.values.signers,
        {
          name: '',
          email: '',
          phone_number: getCurrentLanguage() === 'sv' ? '+46' : '+47',
          social_security_number: '',
          type,
          company_id: localStorage.getItem('companyId'),
        },
      ].sort((a, b) => {
        // Compare the 'type' values
        if (a.type > b.type) return 1;
        if (a.type < b.type) return -1;
        return 0;
      }),
    });
  };

  const removeSignersItem = (ind) => {
    const newArray = formik.values.signers.filter((item, index) => index !== ind);

    formik.setValues({
      ...formik.values,
      signers: newArray,
    });
  };

  const renderPrincipalsCheckBox = (index) => {
    return (
      <div className={styles.checkboxesWrapper}>
        {showCheckBoxes(principalCheckBoxes, index) && (
          <StyledCheckbox
            label={t('add_principal')}
            name="secondPrincipal"
            checked={principalCheckBoxes.includes(index + 1)}
            onChange={(e) => principalCheckBoxesHandle(e.target.checked, index)}
          />
        )}
      </div>
    );
  };

  const getCounts = (data) => {
    const counts = {
      [SIGNING]: 0,
      // [APPROVER]: 0,
      [VIEWER]: 0,
    };

    return {
      counts,
      count: data?.map((item) => {
        // eslint-disable-next-line no-plusplus
        counts[item.type]++;
        return counts[item.type];
      }),
    };
  };

  const renderSignersTitle = (it, index) => {
    let txt = '';
    const { counts, count } = getCounts(formik.values.signers);

    if (it.type === VIEWER) {
      if (counts[VIEWER] > 1) {
        txt = `${t('examiner')} ${count[index]}`;
      } else {
        txt = t('examiner');
      }
    }

    if (it.type === SIGNING) {
      if (counts[SIGNING] > 1) {
        txt = `${t('company_signatory')} ${count[index]}`;
      } else {
        return t('company_signatory');
      }
    }

    return (
      <span className={styles.itemHeader}>
        {txt}
        <span onClick={() => removeSignersItem(index)} className={styles.delete}>
          <Delete />
        </span>
      </span>
    );
  };

  const renderSignersCheckBox = (index, it) => {
    const disabledAddSigner = formik.values.signers.filter((item) => item.type === SIGNING).length >= 3;
    const disabledAddViewer = formik.values.signers.filter((item) => item.type === VIEWER).length >= 3;
    // const disabledAddApprover = !!formik.values.signers.find((item) => item.type === APPROVER);

    return (
      <div className={styles.checkboxesWrapper}>
        {index === 0 ? (
          <>
            <StyledCheckbox
              label={t('copy_company_signatory_to_telephony_manager')}
              name="copyManager"
              checked={isCopyManager}
              onChange={handleCopyManager}
            />
            <StyledCheckbox
              label={t('copy_company_signatory_to_real_principal')}
              name="copyPrincipal"
              checked={isCopyPrincipal}
              onChange={handleCopyPrincipal}
            />

            <StyledButton
              color={disabledAddSigner ? 'grey' : 'blue'}
              text={t('add_signatory_scrive')}
              onClick={() => addSignersItem(SIGNING)}
              className={styles.buttonAdd}
              disabled={disabledAddSigner}
            />

            <StyledButton
              color={disabledAddViewer ? 'grey' : 'purple'}
              text={t('add_reviewer_scrive')}
              onClick={() => addSignersItem(VIEWER)}
              className={styles.buttonAdd}
              disabled={disabledAddViewer}
            />

            {/* <StyledButton
              color={disabledAddViewer ? 'grey' : 'yellow'}
              text="Addera Visare Scrive"
              onClick={() => addSignersItem(VIEWER)}
              className={styles.buttonAdd}
              disabled={disabledAddViewer}
            /> */}
          </>
        ) : null}
      </div>
    );
  };

  const filterSigners = (f, item) => {
    if (item.type === VIEWER) {
      return f.name !== 'social_security_number';
    }
    return f;
  };

  return (
    <div>
      <div className={styles.steps}>
        <Steps className={styles.secondStep} steps={steps} agreement={agreement} />
      </div>
      <form onSubmit={formik.handleSubmit} className={styles.form}>
        <div className={styles.formContent}>
          <div className={styles.fields}>
            {formListCreator(
              'signers',
              formik,
              t('company_signatory'),
              renderSignersCheckBox,
              renderSignersTitle,
              filterSigners,
            )}

            <div>
              <h2 className={styles.title}>{t('telephone_manager')}</h2>
              <div className={styles.managerBlock}>{formCreator('telephone_manager', formik)}</div>
            </div>

            {/* {formPrincipalCreator('real_principals')} */}

            {getCurrentLanguage() === 'sv'
              ? formListCreator('real_principals', formik, t('real_principal'), renderPrincipalsCheckBox)
              : null}

            <div>
              <h2 className={styles.title}>{t('seller')}</h2>
              <div className={styles.sellerBlock}>{formCreator('manager', formik)}</div>
            </div>
          </div>
        </div>

        <StyledButton
          color="purple"
          text={isSending ? `${t('sanding')}...` : t('next_page')}
          type="submit"
          className={styles.button}
          disabled={isSending}
        />
      </form>
    </div>
  );
};

export default NewAgreementSecondStep;
