import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import { securityPdfStyles } from '../../../../helpers/pdfCommonStyles';

import logo from '../../../../assets/images/Logo.png';
import Footer from './footer';

const styles = StyleSheet.create({
  ...securityPdfStyles,
  content: {
    ...securityPdfStyles.content,
    marginTop: 70,
  },
});

// eslint-disable-next-line arrow-body-style
const SixPage = () => {
  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.logo}>
          <Image src={logo} />
        </View>

        <View style={styles.content}>
          <Text style={styles.subTitle}>Security operations</Text>
          <Text style={styles.text}>
            Should an unforeseen situation arise impacting services or their security, our 24/7 Network Operations
            Center staff are alerted and start working to remediate the problem instantly. NOC can be reached to email
            or phone and can escalate critical types of degradation of the service. The NOC team also handles all alarms
            and monitoring and can urgently assess the quality and stability of the service. The NOC team has escalation
            capabilities to the Pingaway Operations Manager and can coordinate or escalate technicians in various are as
            of the operations. After an incident, a post mortem analysis is performed to ensure that cause and impact
            are fully understood and preventive measures and improvements are planned and implemented.
          </Text>

          <Text style={styles.subTitle}>Compliance for partners and customers</Text>
          <Text style={styles.text}>
            Pingaway gives our customers the tools you need to stay compliant with your policies by setting up
            two-factor authentication, fine-grained permissions and audit-logging within the service. Pingaway regularly
            works with our customers and partners to ensure that our services meet their security and business
            continuity objectives.
          </Text>
        </View>
      </View>
      <Footer />
    </Page>
  );
};

SixPage.propTypes = {};

SixPage.defaultProps = {};

export default SixPage;
