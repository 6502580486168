import React from 'react';
import PropTypes from 'prop-types';
import { Document, Font } from '@react-pdf/renderer';
import Grenke from './Grenke/Grenke';

Font.register({
  family: 'Como',
  src: '/Como.ttf',
});
Font.register({
  family: 'Como-Bold',
  src: '/Como-Bold.ttf',
});
Font.register({
  family: 'Como-ExtraBold',
  src: '/Como-ExtraBold.ttf',
});
Font.register({
  family: 'Como-Light',
  src: '/Como-Light.ttf',
});
Font.register({
  family: 'Como-Medium',
  src: '/Como-Medium.ttf',
});
Font.register({
  family: 'Como-SemiBold',
  src: '/Como-SemiBold.ttf',
});
Font.register({
  family: 'ArialRegular',
  src: '/ArialRegular.ttf',
});
Font.register({
  family: 'ArialBold',
  src: '/ArialBold.ttf',
});
Font.register({
  family: 'Helvetica',
  src: '/Helvetica.ttf',
});
Font.register({
  family: 'ArialItalic',
  src: '/ArialItalic.ttf',
});
Font.register({
  family: 'ArialBoldItalic',
  src: '/ArialBoldItalic.ttf',
});

const GrenkeDocument = ({ data }) => (
  <Document>
    <Grenke data={data} />
  </Document>
);

GrenkeDocument.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.objectOf(PropTypes.shape)]),
};

GrenkeDocument.defaultProps = {
  data: null,
};

export default GrenkeDocument;
