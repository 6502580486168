import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useMultiLang from '../../localization/useMultiLang';
import ActionsMenu from './ActionsMenu';
import { Logo, Input, AgreementsCard } from '../../components/simple';
import styles from './styles.module.scss';
import { get } from '../../services/API';
import { authorization, logout } from '../../helpers/authentication';
import { loginPage } from '../../router/routes';

const MainPage = () => {
  const { t } = useMultiLang();
  const [cards, setCards] = useState([]);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      localStorage.removeItem('quoteId');
      localStorage.removeItem('companyId');
      localStorage.removeItem('step');
      const data = await get('user/current-user', authorization());
      if (data.status === 200) {
        localStorage.setItem('role', data.data.role);
        localStorage.setItem('userId', data.data.id);
        localStorage.setItem('partner_type', data.data.partner_type ?? 1);
      } else {
        logout();
        navigate(loginPage);
      }
    };
    fetchData();
  }, []);

  const getSearchField = (str) => {
    if (Number.isInteger(Number(str))) {
      return `&agreement_id=${str}`;
    }
    return `&company_name=${str}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      const mainRequest = async () => {
        const searchField = getSearchField(search);
        if (localStorage.getItem('role') === 'Admin') {
          return get(`/quotes/quotes?is_draft=false&ordering=-downloaded_at&limit=50${searchField}`, authorization());
        }
        return get(
          `/quotes/quotes/manager?is_draft=false&ordering=-downloaded_at&limit=50${searchField}`,
          authorization(),
        );
      };
      const agreements = await mainRequest();
      setCards(agreements.data.results);
    };
    fetchData();
  }, [search]);

  const handleChange = (event) => {
    setSearch(event.target.value);
  };

  return (
    <div className={styles.mainPageContainer}>
      <Logo />
      <div className={styles.searchInput}>
        <Input type="text" onChange={handleChange} value={search} label={t('search')} className={styles.roundedInput} />
      </div>
      <div className={styles.mainContent}>
        <h1 className={styles.headName}>{t('welcome')}</h1>
        <ActionsMenu />
        <h3 className={styles.docsHeading}>
          {localStorage.getItem('role') === 'Admin' ? t('ping') : t('mine')} {t('latest_agreement')}
        </h3>
        <div className={styles.cardContainer}>
          {cards &&
            cards.map((card) => (
              <AgreementsCard
                key={card.id}
                name={card.company_name}
                date={card.downloaded_at}
                margin={card.total_tb_with_contract}
                number={card.agreement_id}
                price={card.monthly_cost}
                id={card.id}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default MainPage;
