import React from 'react';
import { StepPage } from '../../components/complex';
import NewAgreementFirstStep from './NewAgreementFirstStep';

const NewAgreementFirstPage = () => (
  <StepPage>
    <NewAgreementFirstStep />
  </StepPage>
);

export default NewAgreementFirstPage;
