import React from 'react';
import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { getTranslations } from '../translations';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'ArialRegular',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: '#000',
  },
  container: {
    width: '90%',
    margin: '0 auto',
    height: 830,
    paddingBottom: 80,
  },
  content: {
    fontSize: 8,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: '30%',
    columnGap: 15,
  },
  title: {
    fontSize: 18,
    fontFamily: 'ArialRegular',
    color: '#01B9F2',
    marginTop: 30,
    marginBottom: 20,
  },
  block: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 8,
  },
  blockText: {
    flexWrap: 'wrap',
  },

  blockHeader: {
    color: '#01B9F2',
    fontSize: 9,
    marginBottom: 2,
  },
  footer: {
    fontSize: 6,
    color: 'grey',
    width: 250,
    position: 'absolute',
    bottom: 0,
  },
});

const ThirdPageNo = () => {
  const { translations } = getTranslations('grenke_third_page_no');

  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View>
          <Text style={{ fontSize: 6, marginBottom: 5, marginTop: 20 }}>
            SIDE 2 – <Text style={{ fontFamily: 'ArialBold' }}>18NO02</Text>
          </Text>
          <Text style={{ fontSize: 6 }}>{translations.info}</Text>
        </View>

        <View>
          <Text style={styles.title} />
        </View>

        <View style={styles.content}>
          <View style={styles.block}>
            <View>
              <Text style={styles.blockText}>{translations.block_text_8_2}</Text>
            </View>
          </View>
          <View style={styles.block}>
            <View>
              <Text style={styles.blockHeader}>{translations.block_head_9}</Text>
              <Text style={styles.blockText}>{translations.block_text_9}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <View>
              <Text style={styles.blockHeader}>{translations.block_head_10}</Text>
              <Text style={styles.blockText}>{translations.block_text_10_1}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <View>
              <Text style={styles.blockText}>{translations.block_text_10_2}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <View>
              <Text style={styles.blockHeader}>{translations.block_head_11}</Text>
              <Text style={styles.blockText}>{translations.block_text_11}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <View>
              <Text style={styles.blockHeader}>{translations.block_head_12}</Text>
              <Text style={styles.blockText}>{translations.block_text_12}</Text>
              <View style={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
                <Text style={styles.blockText}>a)</Text>
                <Text style={styles.blockText}>{translations.block_text_12_1}</Text>
              </View>
            </View>
          </View>

          <View style={{ ...styles.block, flexDirection: 'column' }}>
            <View style={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
              <Text style={styles.blockText}>b)</Text>
              <Text style={styles.blockText}>{translations.block_text_12_2}</Text>
            </View>

            <View style={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
              <Text style={styles.blockText}>c)</Text>
              <Text style={styles.blockText}>{translations.block_text_12_3}</Text>
            </View>

            <View style={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
              <Text style={styles.blockText}>d)</Text>
              <Text style={styles.blockText}>{translations.block_text_12_4}</Text>
            </View>

            <View style={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
              <Text style={styles.blockText}>e)</Text>
              <Text style={styles.blockText}>{translations.block_text_12_5}</Text>
            </View>

            <View style={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
              <Text style={styles.blockText}>f)</Text>
              <Text style={styles.blockText}>{translations.block_text_12_6}</Text>
            </View>

            <View style={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
              <Text style={styles.blockText}>g)</Text>
              <Text style={styles.blockText}>{translations.block_text_12_7}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <View>
              <Text style={styles.blockText}>{translations.block_text_12_8}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <View>
              <Text style={styles.blockText}>{translations.block_text_12_9}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <View>
              <Text style={styles.blockText}>{translations.block_text_12_10}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <View>
              <Text style={styles.blockHeader}>{translations.block_head_5}</Text>
              <Text style={styles.blockText}>{translations.block_text_5}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <View>
              <Text style={styles.blockHeader}>{translations.block_head_13}</Text>
              <Text style={styles.blockText}>{translations.block_text_13}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <View>
              <Text style={styles.blockHeader}>{translations.block_head_14}</Text>
              <Text style={styles.blockText}>{translations.block_text_14}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <View>
              <Text style={styles.blockHeader}>{translations.block_head_15}</Text>
              <Text style={styles.blockText}>{translations.block_text_15}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <View>
              <Text style={styles.blockHeader}>{translations.block_head_16}</Text>
              <Text style={styles.blockText}>{translations.block_text_16}</Text>
            </View>
          </View>

          <View style={styles.block}>
            <View>
              <Text style={styles.blockHeader}>{translations.block_head_17}</Text>
              <Text style={styles.blockText}>{translations.block_text_17}</Text>
            </View>
          </View>
          {/*  */}
        </View>

        <View style={styles.footer}>
          <Text>
            <Text style={{ color: '#000' }}>{translations.bank}</Text> {'\n'}
            {translations.footer}
          </Text>
        </View>
      </View>
    </Page>
  );
};

export default ThirdPageNo;
