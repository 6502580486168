import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import { personalDataServiceAgreementPdfStyles } from '../../../../helpers/pdfCommonStyles';
import { getTranslations } from '../translations';

const styles = StyleSheet.create({
  ...personalDataServiceAgreementPdfStyles,
});

// eslint-disable-next-line arrow-body-style
const ThirdPage = () => {
  const { translations, t } = getTranslations('personalDataServiceAgreement_third_page');

  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.content}>
          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.textBold} />
            </View>

            <View style={styles.col_2}>
              <View style={styles.sub_row}>
                <View style={styles.sub_col_1}>
                  <Text style={styles.textBold}>{translations.row_title_14}</Text>
                </View>
                <View style={styles.sub_col_2}>
                  <Text style={styles.text}>{translations.row_text_14}</Text>
                </View>
              </View>

              <View style={styles.sub_row}>
                <View style={styles.sub_col_1}>
                  <Text style={styles.textBold}>{translations.row_title_15}</Text>
                </View>
                <View style={styles.sub_col_2}>
                  <Text style={styles.text}>{translations.row_text_15}</Text>
                </View>
              </View>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>2.1</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>{translations.block_text_1}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.textBold}>3.</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.textBold}>{translations.sub_title_1}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>3.1</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>{translations.block_text_2}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>3.2</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>{translations.block_text_3}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>3.3</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>{translations.block_text_4}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>3.4</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>{translations.block_text_5}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.textBold}>4.</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.textBold}>{translations.sub_title_2} </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>4.1</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>{translations.block_text_6}</Text>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};

ThirdPage.propTypes = {};

ThirdPage.defaultProps = {};

export default ThirdPage;
