import React from 'react';
import PropTypes from 'prop-types';
import { Document, Font } from '@react-pdf/renderer';
import Roaming from './Roaming/Roaming';
import Trafic from './Trafic';
import Summary from './Summary/Summary';
import SLA from './SLA/SLA';
import Security from './Security/Security';
import PersonalDataServiceAgreement from './PersonalDataServiceAgreement/PersonalDataServiceAgreement';
import HardwarePot from './HardwarePot/HardwarePot';
import FinalInvoice from './FinalInvoice/FinalInvoice';
import FinalInvoiceAndHardwarePot from './FinalInvoiceAndHardwarePot/FinalInvoiceAndHardwarePot';

Font.register({
  family: 'Como',
  src: '/Como.ttf',
});
Font.register({
  family: 'Como-Bold',
  src: '/Como-Bold.ttf',
});
Font.register({
  family: 'Como-ExtraBold',
  src: '/Como-ExtraBold.ttf',
});
Font.register({
  family: 'Como-Light',
  src: '/Como-Light.ttf',
});
Font.register({
  family: 'Como-Medium',
  src: '/Como-Medium.ttf',
});
Font.register({
  family: 'Como-SemiBold',
  src: '/Como-SemiBold.ttf',
});
Font.register({
  family: 'ArialRegular',
  src: '/ArialRegular.ttf',
});
Font.register({
  family: 'ArialBold',
  src: '/ArialBold.ttf',
});
Font.register({
  family: 'Helvetica',
  src: '/Helvetica.ttf',
});
Font.register({
  family: 'ArialItalic',
  src: '/ArialItalic.ttf',
});
Font.register({
  family: 'ArialBoldItalic',
  src: '/ArialBoldItalic.ttf',
});

const SummaryDocument = ({
  isTrafic,
  isRoaming,
  isSLA,
  isSecurity,
  isPersonalDataServiceAgreement,
  isHardwarePot,
  isFinalInvoice,
  isFinalInvoiceAndHardwarePot,
  data,
  lang,
}) => (
  <Document>
    <Summary data={data} lang={lang} />
    {isTrafic && <Trafic />}
    {isRoaming && <Roaming />}
    {isSLA && <SLA />}
    {isSecurity && <Security />}
    {isPersonalDataServiceAgreement && <PersonalDataServiceAgreement data={data} />}
    {isHardwarePot && <HardwarePot data={data} />}
    {isFinalInvoice && <FinalInvoice data={data} />}
    {isFinalInvoiceAndHardwarePot && <FinalInvoiceAndHardwarePot data={data} />}
  </Document>
);

SummaryDocument.propTypes = {
  isTrafic: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isRoaming: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isSLA: PropTypes.oneOf([PropTypes.bool, PropTypes.func]),
  isSecurity: PropTypes.oneOf([PropTypes.bool, PropTypes.func]),
  isPersonalDataServiceAgreement: PropTypes.oneOf([PropTypes.bool, PropTypes.func]),
  isHardwarePot: PropTypes.oneOf([PropTypes.bool, PropTypes.func]),
  isFinalInvoice: PropTypes.oneOf([PropTypes.bool, PropTypes.func]),
  isFinalInvoiceAndHardwarePot: PropTypes.oneOf([PropTypes.bool, PropTypes.func]),
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.objectOf(PropTypes.shape)]),
  lang: PropTypes.string,
};

SummaryDocument.defaultProps = {
  isTrafic: false,
  isRoaming: false,
  isSLA: false,
  isSecurity: false,
  isPersonalDataServiceAgreement: false,
  isHardwarePot: false,
  isFinalInvoice: false,
  isFinalInvoiceAndHardwarePot: false,
  data: null,
  lang: 'sv',
};

export default SummaryDocument;
