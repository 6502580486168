function replacePlaceholders(text, components) {
  const regex = /{{(.*?)}}/g;
  let match;
  let lastIndex = 0;
  const result = [];

  // eslint-disable-next-line no-cond-assign
  while ((match = regex.exec(text)) !== null) {
    const textPiece = text.slice(lastIndex, match.index);
    const componentKey = match[1];

    if (textPiece) {
      result.push(textPiece);
    }
    if (componentKey && components[componentKey]) {
      result.push(components[componentKey]);
    }

    lastIndex = regex.lastIndex;
  }

  // Add any remaining text after the last match
  if (lastIndex < text.length) {
    result.push(text.substring(lastIndex));
  }

  return result;
}

export default replacePlaceholders;
