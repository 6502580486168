import React from 'react';
import PropTypes from 'prop-types';
import FirstPage from './FirstPage';
import SecondPage from './SecondPage';
import ThirdPage from './ThirdPage';

import FirstPageNO from './FirstPageNO';
import SecondPageNO from './SecondPageNO';
import ThirdPageNo from './ThirdPageNo';
import FourPageNo from './FourPageNo';

import { getCurrentLanguage } from '../../../../localization/LanguageContext';

const Grenke = ({ data }) => {
  const language = getCurrentLanguage();

  if (language === 'no') {
    return (
      <>
        <FirstPageNO data={data} />
        <SecondPageNO />
        <ThirdPageNo />
        <FourPageNo data={data} />
      </>
    );
  }

  return (
    <>
      <FirstPage data={data} />
      <SecondPage />
      <ThirdPage data={data} />
    </>
  );
};

Grenke.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.objectOf(PropTypes.shape)]),
};

Grenke.defaultProps = {
  data: null,
};

export default Grenke;
