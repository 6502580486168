import React from 'react';
import LoginForm from './LoginForm';
import { Logo } from '../../components/simple';
import styles from './styles.module.scss';

const LoginPage = () => (
  <div className={styles.loginPageContainer}>
    <Logo />
    <div className={styles.formWrapper}>
      <LoginForm />
    </div>
  </div>
);

export default LoginPage;
