import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import logo from '../../../../assets/images/Logo.png';
import { summaryPdfStyles } from '../../../../helpers/pdfCommonStyles';

import { getTranslations } from '../translations';

const styles = StyleSheet.create({
  ...summaryPdfStyles,
  title: {
    fontFamily: 'Como-Bold',
    fontSize: 30,
    marginTop: 40,
    marginBottom: 10,
  },
  text: {
    fontSize: 12,
    margin: '10px 0',
    lineHeight: 1.5,
  },
  cards: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  card: {
    width: 150,
    border: 1,
    height: 400,
    borderRadius: 20,
    textAlign: 'center',
    paddingBottom: 20,
  },
  cardTitle: {
    fontFamily: 'Como-Bold',
    fontSize: 12,
    paddingTop: 25,
  },
  cardInfo: {
    fontSize: 10,
    paddingTop: 15,
    width: 130,
    margin: '0 auto',
    marginBottom: 20,
  },
  items: {
    width: '90%',
    margin: '0 auto',
  },
  item: {
    borderBottom: 1,
    fontSize: 10,
    fontFamily: 'Como-Bold',
    padding: '10px 0',
  },
});

const SixPage = ({ data, lang }) => {
  const { translations, t } = getTranslations('sixPage', lang);

  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.logo}>
          <Image src={logo} />
          <View style={styles.agreementNumberBlock}>
            <Text style={styles.agreementNumber}>{data?.agreement_id}</Text>
            <Text style={styles.agreementNumber}>{data?.created_at}</Text>
          </View>
        </View>

        <View style={styles.content}>
          <Text style={styles.title}>{translations.title}</Text>
          <Text style={styles.text}>{translations.description_1}</Text>

          <Text style={styles.text}>{translations.description_2}</Text>

          <View style={styles.cards}>
            <View style={{ ...styles.card, borderColor: '#1e90ff' }}>
              <Text style={styles.cardTitle}>{translations.head_1}</Text>
              <Text style={styles.cardInfo}>{translations.head_1_description}</Text>
              <View style={styles.items}>
                <Text style={{ ...styles.item, borderColor: '#1e90ff' }}>{translations.access_to_platforms}</Text>
                <Text style={{ ...styles.item, borderColor: '#1e90ff' }}>{translations.plan_item_1}</Text>
                <Text style={{ ...styles.item, borderColor: '#1e90ff' }}>{translations.plan_item_2}</Text>
                <Text style={{ ...styles.item, borderColor: '#1e90ff' }}>{translations.plan_item_3}</Text>
                <Text style={{ ...styles.item, borderColor: '#1e90ff' }}>{translations.plan_item_4}</Text>
                <Text style={{ ...styles.item, borderColor: '#1e90ff' }}>{translations.plan_item_5}</Text>
                <Text
                  style={{
                    ...styles.item,
                    borderColor: 'lightgrey',
                    color: 'lightgrey',
                  }}
                >
                  {translations.plan_item_6}
                </Text>
                <Text
                  style={{
                    ...styles.item,
                    borderColor: 'lightgrey',
                    color: 'lightgrey',
                  }}
                >
                  {translations.plan_item_7}
                </Text>
                <Text
                  style={{
                    ...styles.item,
                    borderColor: 'lightgrey',
                    color: 'lightgrey',
                  }}
                >
                  {translations.plan_item_8}
                </Text>
                <Text
                  style={{
                    ...styles.item,
                    borderColor: 'lightgrey',
                    color: 'lightgrey',
                  }}
                >
                  {translations.plan_item_9}
                </Text>
                <Text
                  style={{
                    ...styles.item,
                    borderColor: 'lightgrey',
                    color: 'lightgrey',
                  }}
                >
                  {translations.plan_item_10}
                </Text>
                <Text
                  style={{
                    ...styles.item,
                    borderColor: 'lightgrey',
                    color: 'lightgrey',
                  }}
                >
                  {translations.plan_item_11}
                </Text>
              </View>
            </View>
            <View style={{ ...styles.card, borderColor: '#abcdef' }}>
              <Text style={styles.cardTitle}>{translations.head_2}</Text>
              <Text style={styles.cardInfo}>{translations.head_2_description}</Text>
              <View style={styles.items}>
                <Text style={{ ...styles.item, borderColor: '#abcdef' }}>{translations.access_to_platforms}</Text>
                <Text style={{ ...styles.item, borderColor: '#abcdef' }}>{translations.plan_item_1}</Text>
                <Text style={{ ...styles.item, borderColor: '#abcdef' }}>{translations.plan_item_2}</Text>
                <Text style={{ ...styles.item, borderColor: '#abcdef' }}>{translations.plan_item_3}</Text>
                <Text style={{ ...styles.item, borderColor: '#abcdef' }}>{translations.plan_item_4}</Text>
                <Text style={{ ...styles.item, borderColor: '#abcdef' }}>{translations.plan_item_5}</Text>
                <Text style={{ ...styles.item, borderColor: '#abcdef' }}>{translations.plan_item_6}</Text>
                <Text style={{ ...styles.item, borderColor: '#abcdef' }}>{translations.plan_item_7}</Text>
                <Text style={{ ...styles.item, borderColor: '#abcdef' }}>{translations.plan_item_8}</Text>
                <Text style={{ ...styles.item, borderColor: '#abcdef' }}>{translations.plan_item_9}</Text>
                <Text style={{ ...styles.item, borderColor: '#abcdef' }}>{translations.plan_item_10}</Text>
                <Text
                  style={{
                    ...styles.item,
                    borderColor: 'lightgrey',
                    color: 'lightgrey',
                  }}
                >
                  {translations.plan_item_11}
                </Text>
              </View>
            </View>
            <View style={{ ...styles.card, borderColor: '#ffc40c' }}>
              <Text style={styles.cardTitle}>{translations.head_3}</Text>
              <Text style={styles.cardInfo}>{translations.head_3_description}</Text>
              <View style={styles.items}>
                <Text style={{ ...styles.item, borderColor: '#ffc40c' }}>{translations.access_to_platforms}</Text>
                <Text style={{ ...styles.item, borderColor: '#ffc40c' }}>{translations.plan_item_1}</Text>
                <Text style={{ ...styles.item, borderColor: '#ffc40c' }}>{translations.plan_item_2}</Text>
                <Text style={{ ...styles.item, borderColor: '#ffc40c' }}>{translations.plan_item_3}</Text>
                <Text style={{ ...styles.item, borderColor: '#ffc40c' }}>{translations.plan_item_4}</Text>
                <Text style={{ ...styles.item, borderColor: '#ffc40c' }}>{translations.plan_item_5}</Text>
                <Text style={{ ...styles.item, borderColor: '#ffc40c' }}>{translations.plan_item_6}</Text>
                <Text style={{ ...styles.item, borderColor: '#ffc40c' }}>{translations.plan_item_7}</Text>
                <Text style={{ ...styles.item, borderColor: '#ffc40c' }}>{translations.plan_item_8}</Text>
                <Text style={{ ...styles.item, borderColor: '#ffc40c' }}>{translations.plan_item_9}</Text>
                <Text style={{ ...styles.item, borderColor: '#ffc40c' }}>{translations.plan_item_10}</Text>
                <Text style={{ ...styles.item, borderColor: '#ffc40c' }}>{translations.plan_item_11}</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.footer}>
        <View style={{ ...styles.footerName, marginBottom: 3 }}>
          <Text style={{ fontFamily: 'Como-Bold' }}>{t.common.offer} </Text>
          <Text style={{ fontFamily: 'Como-SemiBold' }}>{data?.company.name}</Text>
        </View>
        <Text style={{ fontFamily: 'Como-Medium' }}>{data?.created_at}</Text>
      </View>
    </Page>
  );
};

SixPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.objectOf(PropTypes.shape)]),
  lang: PropTypes.string,
};

SixPage.defaultProps = {
  data: null,
  lang: 'sv',
};

export default SixPage;
