import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const get = async (url, headers) => {
  try {
    return await instance.get(url, headers);
  } catch (e) {
    return e.response;
  }
};

export const post = async (url, body, headers) => {
  try {
    return await instance.post(url, body, headers);
  } catch (e) {
    return e.response;
  }
};

export const put = async (url, body, headers) => {
  try {
    const data = await instance.put(url, body, headers);
    return data;
  } catch (e) {
    return e.response;
  }
};

export const patch = async (url, body, headers) => {
  try {
    const data = await instance.patch(url, body, headers);
    return data;
  } catch (e) {
    return e.response;
  }
};

export const remove = (url, headers) => instance.delete(url, headers);
