export const summaryPdfStyles = {
  page: {
    fontFamily: 'Como',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: '#10225c',
  },
  container: {
    width: '90%',
    margin: '0 auto',
  },
  logo: {
    width: 160,
    height: 65,
    marginLeft: 'auto',
    marginTop: 15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  agreementNumberBlock: {
    width: 70,
    textAlign: 'right',
    marginRight: 5,
    marginTop: -5,
  },
  agreementNumber: {
    fontFamily: 'Como-SemiBold',
    fontSize: 12,
  },
  content: {
    width: '90%',
    margin: '0 auto',
  },
  footer: {
    // textAlign: 'center',
    // paddingBottom: 20,
    // fontSize: 12,
    width: '100%',
    position: 'absolute',
    textAlign: 'center',
    fontSize: 14,
    bottom: 40,
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    justifyContent: 'center',
  },
  footerName: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
};

export const roamingPdfStyles = {
  page: {
    fontFamily: 'Como',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: '#10225c',
    backgroundColor: '#e1ebf4',
  },
  header: {
    width: '90%',
    margin: '0 auto',
  },
  container: {
    width: '90%',
    margin: '0 auto',
  },
  logo: {
    width: 160,
    height: 65,
    marginLeft: 'auto',
    marginTop: 15,
  },
  titleContainer: {
    width: 300,
    marginBottom: 20,
  },
  title: {
    fontSize: 28,
    fontFamily: 'Como-Bold',
    letterSpacing: 1.5,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: '50%',
  },
  sectionTitle: {
    fontSize: 14,
    fontFamily: 'Como-Bold',
    marginBottom: 10,
  },
  sectionText: {
    fontSize: 10,
    fontFamily: 'Como-Medium',
    lineHeight: 1.3,
  },
  section: {
    marginBottom: 10,
    flexShrink: 1,
  },

  tableContainer: {
    fontSize: 8,
    margin: '0 20px',
    padding: '40px 20px',
    marginBottom: 10,
    backgroundColor: '#709cd6',
    borderRadius: 20,
  },
  tableTitle: {
    fontFamily: 'Como-Bold',
    fontSize: 14,
    marginBottom: 15,
    color: '#fff',
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: 1,
    borderColor: '#fff',
    paddingBottom: 2,
    fontSize: 8,
    fontFamily: 'Como-Bold',
  },

  containerWrapper: {
    width: '100%',
    margin: '0 auto',
  },

  tableInfo: {
    fontSize: 10,
    width: '100%',
    margin: '0 auto',
    paddingTop: 10,
    borderTop: 1,
    borderColor: '#fff',
    color: '#fff',
    lineHeight: 1.5,
  },
  cell: {
    padding: 0,
    textAlign: 'center',
    color: '#fff',
  },
  cellSub: {
    fontFamily: 'Como',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    padding: '4px 0',
    fontSize: 9,
  },
  footer: {
    textAlign: 'center',
    // paddingBottom: 20,
    fontSize: 12,
    color: '#10225c',
    width: '100%',
    position: 'absolute',
    bottom: 40,
  },
};

export const slaPdfStyles = {
  page: {
    fontFamily: 'Como',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: '#10225c',
  },
  container: {
    width: '90%',
    margin: '0 auto',
  },
  content: {
    width: '85%',
    marginLeft: 40,
  },
  logo: {
    width: 160,
    height: 65,
    marginLeft: 'auto',
    marginTop: 15,
  },
  title: {
    fontSize: 18,
    fontFamily: 'Como-Bold',
    // letterSpacing: 1.5,
  },

  text: {
    fontSize: 12,
    margin: '10px 0',
    lineHeight: 1.5,
  },

  textBlock: {
    fontSize: 12,
    marginTop: 5,
    lineHeight: 1.5,
  },

  listItem: {
    fontSize: 12,
    lineHeight: 1.2,
    margin: '1px 0',
  },

  listItemDot: {
    fontSize: 12,
    marginLeft: 5,
  },

  subTitle: {
    fontFamily: 'Como-Bold',
    fontSize: 12,
  },

  // footer: {
  //   textAlign: 'center',
  //   paddingBottom: 20,
  //   fontSize: 14,
  // },

  footer: {
    textAlign: 'center',
    // paddingBottom: 20,
    fontSize: 14,
    width: '100%',
    position: 'absolute',
    bottom: 40,
  },
  footerName: {
    fontSize: 12,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
};

export const securityPdfStyles = {
  page: {
    fontFamily: 'Como',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: '#10225c',
  },
  container: {
    width: '90%',
    margin: '0 auto',
  },
  content: {
    width: '85%',
    marginLeft: 40,
  },
  logo: {
    width: 160,
    height: 65,
    marginLeft: 'auto',
    marginTop: 15,
  },
  title: {
    fontSize: 18,
    fontFamily: 'Como-Bold',
    // fontSize: 12,
  },

  text: {
    fontSize: 12,
    margin: '10px 0',
    lineHeight: 1.5,
  },

  subTitle: {
    fontFamily: 'Como-Bold',
    fontSize: 14,
  },

  footerBg: {
    backgroundColor: 'rgba(126,179,227,1)',
  },

  footer: {
    width: '80%',
    margin: '0 auto',
    marginTop: 50,
    paddingTop: 30,
    paddingBottom: 40,
    fontSize: 14,
    borderTopWidth: 1,
    borderTopColor: '#fff',
    color: '#fff',
  },
  footerName: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
};

export const personalDataServiceAgreementPdfStyles = {
  page: {
    fontFamily: 'Como',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: '#000',
  },
  container: {
    width: '90%',
    margin: '0 auto',
    marginTop: 50,
  },

  content: {
    width: '85%',
    marginLeft: 40,
  },

  title: {
    fontSize: 18,
    fontFamily: 'Como-Bold',
  },

  text: {
    fontSize: 12,
    lineHeight: 1.5,
  },

  textBold: {
    fontSize: 12,
    lineHeight: 1.5,
    fontFamily: 'Como-Bold',
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 5,
  },

  col_1: {
    width: 50,
  },
  col_2: {
    width: '100%',
  },

  sub_row: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 15,
  },

  sub_col_1: {
    width: 200,
  },
  sub_col_2: {
    width: '100%',
  },
};

export const hardwarePotPdfStyles = {
  page: {
    fontFamily: 'Como',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: '#000',
  },
  container: {
    width: '90%',
    margin: '0 auto',
    marginTop: 150,
  },

  content: {
    width: '85%',
    marginLeft: 40,
  },

  logo: {
    width: 160,
    height: 65,
    marginTop: -15,
    marginRight: -5,
  },

  subTitle: {
    fontSize: 18,
    fontFamily: 'Como-Bold',
    color: '#3a63a0',
  },

  title: {
    fontSize: 24,
    fontFamily: 'Como-Bold',
    color: '#3a63a0',
  },

  text: {
    fontSize: 12,
    lineHeight: 1.3,
  },

  textBold: {
    fontSize: 12,
    inputHeight: 1.2,
    fontFamily: 'Como-Bold',
  },

  form: {
    marginTop: 100,
    display: 'flex',
    flexDirection: 'row',
  },
  block: {
    flex: 1,
    marginRight: 50,
  },

  item: {
    marginBottom: 50,
  },

  input: {
    width: '100%',
    height: 30,
    backgroundColor: '#eeebf2',
    marginBottom: 10,
    paddingTop: 10,
    paddingLeft: 10,
  },

  footer: {
    backgroundColor: 'rgba(126,179,227,1)',
    paddingTop: 50,
    paddingLeft: 40,
    paddingRight: 20,
    paddingBottom: 10,
    display: 'flex',
    flexDirection: 'row',
  },

  footerCol: {
    flex: 1,
    justifyContent: 'flex-start',
  },
  footerText: {
    fontSize: 10,
    inputHeight: 1.7,
    fontFamily: 'Como-Bold',
    marginBottom: 3,
  },
};

export default { summaryPdfStyles, roamingPdfStyles };
