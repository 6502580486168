import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import { personalDataServiceAgreementPdfStyles } from '../../../../helpers/pdfCommonStyles';
import { getTranslations } from '../translations';

const styles = StyleSheet.create({
  ...personalDataServiceAgreementPdfStyles,
});

// eslint-disable-next-line arrow-body-style
const FourPage = () => {
  const { translations, t } = getTranslations('personalDataServiceAgreement_four_page');

  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.content}>
          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>4.2</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>{translations.block_text_1}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.textBold}>5.</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.textBold}>{translations.sub_title_1}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>5.1</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>{translations.block_text_2}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>5.2</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>{translations.block_text_3}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.textBold}>6.</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.textBold}>{translations.sub_title_2} </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text} />
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>{translations.block_text_4}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.textBold}>7.</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.textBold}>{translations.sub_title_3} </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>7.1</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>{translations.block_text_5}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>7.2</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>{translations.block_text_6}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.textBold}>8.</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.textBold}>{translations.sub_title_4}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>8.1</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>{translations.block_text_7}</Text>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};

FourPage.propTypes = {};

FourPage.defaultProps = {};

export default FourPage;
