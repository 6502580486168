import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import { personalDataServiceAgreementPdfStyles } from '../../../../helpers/pdfCommonStyles';
import { getTranslations } from '../translations';

const styles = StyleSheet.create({
  ...personalDataServiceAgreementPdfStyles,
});

// eslint-disable-next-line arrow-body-style
const SevenPage = () => {
  const { translations, t } = getTranslations('personalDataServiceAgreement_seven_page');

  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.content}>
          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>13.3</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>{translations.block_text_1}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.textBold}>14.</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.textBold}>{translations.sub_title_1}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>14.1</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>{translations.block_text_2}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>14.2</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>{translations.block_text_3}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>14.3</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>{translations.block_text_4}</Text>
            </View>
          </View>

          <Text style={{ ...styles.text, textAlign: 'center', margin: '20px 0' }}>****</Text>

          <Text style={{ ...styles.text }}>{translations.text}</Text>
        </View>
      </View>
    </Page>
  );
};

SevenPage.propTypes = {};

SevenPage.defaultProps = {};

export default SevenPage;
