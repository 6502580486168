import enTranslations from './en.json';
import svTranslations from './sv.json';
import noTranslations from './no.json';
import { getCurrentLanguage } from '../../../../localization/LanguageContext';

const translationsMap = {
  en: enTranslations,
  sv: svTranslations,
  no: noTranslations,
};

// eslint-disable-next-line import/prefer-default-export
export const getTranslations = (page, lang) => {
  let selectedLang = lang || getCurrentLanguage();

  const currentLanguage = getCurrentLanguage();

  if (selectedLang === 'sv' && currentLanguage === 'no') {
    selectedLang = 'no';
  }

  const translations = translationsMap[selectedLang];

  if (!translations[page]) {
    selectedLang = 'sv';
  }

  const finalTranslations = translationsMap[selectedLang];

  return { t: finalTranslations, translations: finalTranslations[page], language: selectedLang };
};
